import {
  collection,
  query,
  where,
  Query,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  orderBy,
} from "firebase/firestore";
import { firestoreDefaultConverter } from "vuefire";
import { omit } from "lodash";

import { db } from "@/firebase.js";
import type { UserRole } from "@/models/user-role.model";
import type { UserRoleSchemaRead } from "@/schemas/user-role.schema";

export function getUserRoles({ companyId }: { companyId: string }) {
  const companyUserRolesQueryArguments = [
    collection(db, `roles`),
    where("companyId", "==", companyId),
    where("forAllCompanies", "==", false),
  ] as const;

  const globalUserRolesQueryArguments = [
    collection(db, `roles`),
    where("companyId", "==", null),
    where("forAllCompanies", "==", true),
  ] as const;

  return [companyUserRolesQueryArguments, globalUserRolesQueryArguments].map(
    (userRolesQueryArguments) => {
      return query(...userRolesQueryArguments).withConverter({
        toFirestore: firestoreDefaultConverter.toFirestore,
        fromFirestore: (snapshot) => {
          const data = firestoreDefaultConverter.fromFirestore(snapshot);
          const id = snapshot.id;
          return { ...data, id } as UserRoleSchemaRead;
        },
      }) as Query<UserRole>;
    },
  );
}

export function getThirdPartyUserRoles() {
  const thirdPartyUserRolesQueryArguments = [
    collection(db, `roles`),
    where("forThirdPartyDefault", "==", true),
    orderBy("date", "desc"),
  ] as const;
  return query(...thirdPartyUserRolesQueryArguments).withConverter({
    toFirestore: firestoreDefaultConverter.toFirestore,
    fromFirestore: (snapshot) => {
      const data = firestoreDefaultConverter.fromFirestore(snapshot);
      const id = snapshot.id;
      return { ...data, id } as UserRoleSchemaRead;
    },
  }) as Query<UserRole>;
}

export function upsertRole(role: UserRole) {
  if (role.id) {
    return updateDoc(doc(db, `roles`, role.id), omit(role, ["id"]));
  } else {
    return addDoc(collection(db, `roles`), omit(role, ["id"]));
  }
}

export function deleteRole(roleId: UserRole["id"]) {
  return deleteDoc(doc(db, `roles`, roleId));
}
