import { useCompanyStore } from "@/stores/company";
import { useToastsStore } from "@/stores/toasts";
import { useUserStore } from "@/stores/user";
import * as Sentry from "@sentry/vue";
import { i18n } from "@/i18n";

export enum ErrorType {
  UPDATE = "update",
  CREATE = "create",
  DELETE = "delete",
  READ = "read",
  DOWNLOAD = "download",
  IMPORT = "import",
  UPLOAD = "upload",
  VALIDATION = "validation",
  UNHANDLED = "unhandled",
  SOMETHING_WENT_WRONG = "something went wrong!",
}

export enum ErrorContext {
  CROP = "crop",
  USER = "user",
  NOTE = "note",
  ALERTS = "alerts",
  COMPANY = "Company",
  UNKNOWN = "unknown",
  PICTURE_LIGHTBOX = "Picture Lightbox",
  FILE = "file",
  SENSOR = "sensor",
  DOCUMENT = "document",
  ADVICE = "advice",
  OOPS = "oops!",
  PROTOCOL = "protocol",
  TASK = "task",
  TANK_MIX = "tankmix",
  QR = "qr",
}

export const captureError = (
  error: any,
  context: ErrorContext,
  type: ErrorType,
) => {
  const toastsStore = useToastsStore();
  const { t, te } = i18n.global;
  const title = t(`errors.somethingWentWrong`);
  const userErrors = [
    ErrorType.UPDATE,
    ErrorType.CREATE,
    ErrorType.DELETE,
    ErrorType.READ,
    ErrorType.DOWNLOAD,
    ErrorType.IMPORT,
    ErrorType.UPLOAD,
  ];
  const message = userErrors.includes(type)
    ? t(`errors.weDidNotManageTo`, {
        context: te(`errors.context.${context}`)
          ? t(`errors.context.${context}`)
          : context,
        type: te(`errors.type.${type}`) ? t(`errors.type.${type}`) : type,
      })
    : t(`errors.helpUsImprove`);
  toastsStore.addError(error, title, message);
  let userId = null;
  let userName = null;
  let companyId = null;
  let companyName = null;

  try {
    const userStore = useUserStore();
    const companyStore = useCompanyStore();
    userId = userStore.userInDb?.id;
    userName = userStore.userInDb?.name;
    companyId = companyStore.currentCompany?.id;
    companyName = companyStore.currentCompany?.name;
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
  }

  console.error(error);

  Sentry.captureException(error, {
    extra: {
      userId,
      userName,
      companyId,
      companyName,
      type,
      context,
    },
  });
};
