import {
  QueryDocumentSnapshot,
  doc,
  type FirestoreDataConverter,
} from "firebase/firestore";
import { db } from "@/firebase.js";
import { get, set } from "lodash";
import { ZodType, z } from "zod";
import { firestoreDefaultConverter } from "vuefire";
import { ErrorContext, ErrorType, captureError } from "../errors";

export interface HydrateFieldData {
  collectionPath: string;
  entryName: string;
}

export type HydrateFieldsMap = Record<
  string,
  HydrateFieldData | Record<string, HydrateFieldData>
>;

export const converter = <T extends Record<string, any>>(
  snapshot: QueryDocumentSnapshot<any>,
  hydrateFieldsMap: HydrateFieldsMap,
) => {
  const data = snapshot.data();

  for (const [field, fieldData] of Object.entries(hydrateFieldsMap)) {
    const val = get(data, field);
    let fieldDataToUse: HydrateFieldData;

    if (
      val &&
      typeof fieldData === "object" &&
      !("collectionPath" in fieldData)
    ) {
      fieldDataToUse = (fieldData as Record<string, HydrateFieldData>)[
        data.type
      ];
    } else {
      fieldDataToUse = fieldData as HydrateFieldData;
    }

    if (!val || !fieldDataToUse) continue;

    let collectionPath = fieldDataToUse.collectionPath;

    collectionPath = collectionPath
      .split("/")
      .map((part) => {
        if (part.match(/^\{.*\}$/)) {
          const param = part.substring(1, part.length - 1);
          return get(data, param);
        }
        return part;
      })
      .filter(Boolean)
      .join("/");

    const path = collectionPath ? `${collectionPath}/${val}` : null;
    if (!path) continue;

    const fieldValue = doc(db, path);
    set(data, fieldDataToUse.entryName, fieldValue);
  }

  return Object.assign({ id: snapshot.id }, data) as T;
};

export const validationConverter = <T extends ZodType>(
  schema: T,
): FirestoreDataConverter<z.infer<T>> => {
  return {
    fromFirestore: (snapshot, options) => {
      const data = snapshot.data(options);
      const id = snapshot.id;
      data.id = id;

      const parsedData = schema.safeParse(data);

      if (parsedData.success) {
        return parsedData.data;
      } else {
        captureError(
          parsedData.error,
          ErrorContext.COMPANY,
          ErrorType.VALIDATION,
        );

        return null;
      }
    },
    toFirestore: firestoreDefaultConverter.toFirestore,
  };
};
