import type {
  AllowedLocation,
  UserSensorSettings,
} from "@/schemas/user.schema";
import type { CompanySchema } from "@/schemas/company.schema";

export type NotificationSettingsFields =
  | "dailySummary"
  | "endOfCultivationAlerts"
  | "sensorAlerts"
  | "taskAlerts";

export interface User {
  id: string;
  active: boolean;
  agreement?: {
    version: string;
  };
  name: string;
  email?: string | null;
  slug: string;
  type: "user" | "grower" | "admin" | "advisor" | "third-party";
  companyId: string;
  company?: CompanySchema;
  profile: {
    taskAlertPreference: string;
    notificationSettings: Record<NotificationSettingsFields, boolean>;
    phone?: string;
    preferredLanguage?: { code: string; name: string; text: string } | null; // TODO: can be string too but that will be migrated away
    sensorSettings?: UserSensorSettings;
  };
  config: {
    manageCompanies: string[];
    managedCompanies: Array<{
      allowedLocations: AllowedLocation[];
      companyId: string;
      isOwnCompany: boolean;
      roleId: string | null;
    }>;
    allowedLocations?: AllowedLocation[]; // read when isInOwnCompany
    companyOwner?: boolean;
  };
  roleId?: string | null; // read when isInOwnCompany
}

export enum UserType {
  USER = "user",
  ADMIN = "admin",
  THIRD_PARTY = "third-party",
  ADVISOR = "advisor",
  GROWER = "grower",
}
