import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export function useUser() {
  /** User from store */
  const userStore = useUserStore();
  const { authUser, userCtx, userInDb, isLoading } = storeToRefs(userStore);
  const { saveUser, updatePassword } = userStore;

  return { authUser, userCtx, userInDb, saveUser, updatePassword, isLoading };
}
