import type { EnvironmentConfigs } from './environment-configs.model';
import { EnvironmentType } from './environment-type.model';

export const environment: EnvironmentConfigs = {
    environment: EnvironmentType.PROD,
    apiUrl: 'https://log-and-solve-api-new.onrender.com',
    beehiveApiUrl: 'https://beehive-new.onrender.com',
    firebaseAppId: "1:1009678376040:web:c683792f56fdeb3fdbefdd",
    firebaseAuthDomain: 'log-and-solve-prod.firebaseapp.com',
    firebaseKey: 'AIzaSyCyB4225SkX2b3plCwr4oKJbQMOv2TPzEE',
    firebaseProjectId:"log-and-solve-prod",
    firebaseSenderId: "1009678376040",
    firebaseStorageBucket: "log-and-solve-prod.appspot.com",
    googleMapsApIKey: "AIzaSyAW8jgiJWbUOuZRg-8S53ZsAXV6e31nb6k",
  }
