import {
  useCollection as initialUseCollection,
  useDocument as initialUseDocument,
} from "vuefire";
import { ref } from "vue";
// const getStackTrace = function (place?: number) {
//   const obj: Pick<Error, "stack"> = {};
//   Error.captureStackTrace(obj, getStackTrace);
//   return place !== undefined ? obj.stack?.split("\n")?.[place] : obj.stack;
// };

export type UseCollection = typeof initialUseCollection;
export type UseDocument = typeof initialUseDocument;
export const useVuefire = () => {
  const stoppers = ref<
    (
      | ReturnType<typeof initialUseCollection>
      | ReturnType<typeof initialUseDocument>
    )["stop"][]
  >([]);

  const useCollection: UseCollection = (...args: Parameters<UseCollection>) => {
    const collection = initialUseCollection(...args);
    stoppers.value.push(collection.stop);
    console.debug(
      "🚦 Registered collection stopper",
      // getStackTrace(2)
    );
    return collection as ReturnType<UseCollection>;
  };

  const useDocument: UseDocument = (...args: Parameters<UseDocument>) => {
    const document = initialUseDocument(...args);
    stoppers.value.push(document.stop);
    console.debug(
      "🚦 Registered collection stopper",
      //  getStackTrace(2)
    );
    return document;
  };

  const stopAll = () => {
    stoppers.value.forEach((stop) => stop(true));
    console.debug(
      `🛑 Stopped ${stoppers.value.length} collections`,
      // getStackTrace(),
    );
    // stoppers.value = [];
  };

  return {
    useCollection,
    useDocument,
    stopAll,
  };
};
