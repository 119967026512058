import { db } from "@/firebase";
import {
  companySchemaRead,
  type CompanySchema,
  type LocationMap,
  type LocationMapItem,
} from "@/schemas/company.schema";
import { whenever } from "@vueuse/core";
import { collection, doc } from "firebase/firestore";
import { defineStore, storeToRefs } from "pinia";
import { computed, ref, type ComputedRef, type Ref } from "vue";
import { useUserStore } from "./user";
import { useSettingsStore } from "./settings";
import { validationConverter } from "@/utils/firestore/converter";
import { useVuefire } from "@/utils/firestore/use-vuefire";

export const useCompanyStore = defineStore("company", () => {
  const { useDocument } = useVuefire();
  const locationMap: Ref<LocationMap> = ref({});
  const locationTree: Ref<any[]> = ref([]);
  const settingsStore = useSettingsStore();
  const userStore = useUserStore();
  // const { userInDb, fetchComplete: userFetchComplete } = storeToRefs(userStore); // returns undefineds for some reason!
  const { viewCompanyId, cropOverviewFilters } = storeToRefs(settingsStore);

  const userCompany = computed(() => userStore.userInDb?.company || null);
  const userCompanyId = computed(() => userCompany.value?.id || null);
  const currentCompany = computed(() => {
    return userStore.fetchComplete ? viewCompany.value : userCompany.value;
  }) as ComputedRef<CompanySchema | null>;

  const companyQuery = computed(() => {
    const id = viewCompanyId.value || userCompanyId.value;

    if (id) {
      return doc(collection(db, "companies"), id).withConverter(
        validationConverter(companySchemaRead),
      );
    }
    return null;
  });

  const {
    data: viewCompany,
    pending: isLoading,
    error,
  } = useDocument(companyQuery, {
    maxRefDepth: 0,
  });

  whenever(
    // company is loaded at the same time as the user,
    // we use the user.company payload to set the initial company value
    currentCompany,
    (company) => {
      console.debug(
        `✅ ${
          viewCompany.value ? "viewCompany" : "userCompany"
        } is set in company store`,
        company,
      );
    },
  );

  whenever(currentCompany, (company) => {
    locationMap.value = getLocationMap(company);
    locationTree.value = getLocationTree(company);
  });

  function setViewCompanyId(id: string) {
    cropOverviewFilters.value = {};
    viewCompanyId.value = id;
  }

  function getLocationMap(company: CompanySchema | null): LocationMap {
    // flat map of all locations, indexed by id for convenient lookups
    const idMap: LocationMap = {};
    if (!company) return idMap;
    company.profile?.locations?.forEach((loc) => {
      // business level
      idMap[loc.id] = {
        level: 0,
        location: { ...loc },
      } as LocationMapItem<0>;
      (loc.locationsLevel1 || []).forEach((loc1) => {
        // level 1
        idMap[loc1.id] = {
          level: 1,
          parentId: loc.id,
          location: { ...loc1 },
        } as LocationMapItem<1>;
        (loc1.locationsLevel2 || []).forEach((loc2) => {
          // level 2
          idMap[loc2.id] = {
            level: 2,
            parentId: loc1.id,
            location: { ...loc2 },
          } as LocationMapItem<2>;
        });
      });
    });
    return idMap;
  }

  const photoOwnerId = computed(() => {
    // legacy: photos used to be stored with the userId in the url,
    // for newer uploaded photos we use the companies id
    // @TODO:
    /* state.selectedCompany?.originalUserId || */
    /* state.selectedCompany?.id || */
    return currentCompany.value?.originalUserId || currentCompany.value?.id;
  });

  // TODO: try to filter by getters.allowedLocations
  const getLocationTree = (company: CompanySchema | null) => {
    if (!company) return [];
    const locations = company.profile?.locations || [];
    const tree = locations.map((loc) => ({
      id: loc.id,
      label: loc.name,
      ...(loc.locationsLevel1?.length
        ? {
            children: (loc.locationsLevel1 || []).map((loc1) => ({
              id: loc1.id,
              label: loc1.name,
              fullLabel: [loc.name, loc1.name].join(" > "),
              ...(loc1.locationsLevel2?.length
                ? {
                    children: (loc1.locationsLevel2 || []).map((loc2) => ({
                      id: loc2.id,
                      label: loc2.name,
                      fullLabel: [loc1.name, loc2.name].join(" > "),
                    })),
                  }
                : {}),
            })),
          }
        : {}),
    }));
    return tree;
  };

  function reset() {
    viewCompanyId.value = null;
    locationMap.value = {};
    locationTree.value = [];
  }

  return {
    isLoading,
    userCompany,
    setViewCompanyId,
    locationMap,
    locationTree,
    currentCompany,
    error,
    reset,
    photoOwnerId,
  };
});
