import { createI18n } from "vue-i18n";
import en from "@/locales/en";
import nl from "@/locales/nl";
import de from "@/locales/de";
import fr from "@/locales/fr";

const i18n = createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  missingWarn: false,
  fallbackWarn: false,
  messages: {
    en,
    nl,
    de,
    fr,
  },
});

export { i18n };

export const i18ng = i18n.global;
