import type { UserPermission, UserRole } from "@/models/user-role.model";
import { UserType } from "@/models/user.model";
import type { UserSchema } from "@/schemas/user.schema";
import {
  Ability,
  type AbilityClass,
  AbilityBuilder,
  detectSubjectType,
} from "@casl/ability";
import { i18ng } from "@/i18n";
import {
  isBoolean,
  isObject,
  intersection,
  mergeWith,
  uniq,
  startCase,
} from "lodash-es";
import { SUPERUSER_IDS } from "@/data/superusers";

export enum ActionKey {
  CREATE = "c",
  READ = "v",
  UPDATE = "e",
  DELETE = "d",
}
export type Actions = "create" | "view" | "edit" | "delete";
export type Subjects =
  | "userProfile-all"
  | "cropProfile-all"
  | "addCrop-all"
  | "companyProfile-sensorSettings"
  | "companyProfile-profile"
  | "companyProfile-noteChecklists"
  | "companyProfile-tankMixes"
  | "companyProfile-userManagement"
  | "companyProfile-climateData"
  | "companyProfile-climateBoxes"
  | "companyProfile-cropSettings"
  | "companyProfile-pdfSettings"
  | "companyProfile-substrates"
  | "companyProfile-fertilizationBins"
  | "companyProfile-locationSettings"
  | "general-advices"
  | "general-documents"
  | "tasks-all"
  | "tasks-my"
  | "cropNotes-all"
  | "roles"
  | "superadmin"
  | "labAnalyses-all"
  | "cropProtocol-all";

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export const ab = new Ability([], { detectSubjectType });

export const actionTypeMap: Record<ActionKey, Actions> = {
  [ActionKey.READ]: "view",
  [ActionKey.CREATE]: "create",
  [ActionKey.UPDATE]: "edit",
  [ActionKey.DELETE]: "delete",
};

const translations: Record<string, string> = {
  companyProfile: i18ng.t("layout.settings"),
  "companyProfile-profile": i18ng.t("layout.companySettings"),
  "companyProfile-cropSettings": i18ng.t("layout.createCropsSettings"),
  "companyProfile-pdfSettings": i18ng.t("layout.exportSettings"),
  "companyProfile-noteChecklists": `${i18ng.t("layout.notesAndChecklists")} ${i18ng.t("common.settings")}`,
  "tasks-all": i18ng.t("task.title.allTasks"),
  "tasks-my": i18ng.t("task.title.myTasks"),
};

export const getPermissionTranslation = (category: string, action?: string) => {
  const key = [category, action].filter((x) => x).join("-");
  if (translations[key]) return translations[key];

  if (action && action !== "all") {
    return startCase(action);
  } else {
    return startCase(category);
  }
};

export const defaultPermissions = (): UserPermission[] => [
  {
    category: "companyProfile",
    actions: {
      userManagement: {
        [ActionKey.READ]: false,
        [ActionKey.CREATE]: false,
        [ActionKey.UPDATE]: false,
        [ActionKey.DELETE]: false,
      },
      profile: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: null,
        [ActionKey.DELETE]: null,
      },
      cropSettings: {
        // newly added
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: null,
        [ActionKey.DELETE]: null,
      },
      pdfSettings: {
        // newly added
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: null,
        [ActionKey.DELETE]: null,
      },
      sensorSettings: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: null,
        [ActionKey.DELETE]: null,
      },
      locationSettings: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: null,
        [ActionKey.DELETE]: null,
      },
      substrates: {
        // newly added
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: null,
        [ActionKey.DELETE]: null,
      },
      exports: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: null,
        [ActionKey.DELETE]: null,
      },
      noteChecklists: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: true,
        [ActionKey.DELETE]: true,
      },
      tankMixes: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: true,
        [ActionKey.DELETE]: true,
      },
      cropsImport: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: true,
        [ActionKey.DELETE]: null,
      },
      fertilizationBins: {
        // newly added
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: true,
        [ActionKey.DELETE]: null,
      },
      climateData: {
        // newly added
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: true,
        [ActionKey.DELETE]: true,
      },
      climateBoxes: {
        // newly added
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: true,
        [ActionKey.DELETE]: null,
      },
    },
  },
  {
    category: "cropNotes",
    unbound: true,
    actions: {
      all: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: true,
        [ActionKey.DELETE]: true,
      },
    },
  },
  {
    category: "cropProfile",
    actions: {
      all: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: null,
        [ActionKey.DELETE]: true,
      },
    },
  },
  {
    category: "cropProtocol",
    actions: {
      all: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: true,
        [ActionKey.DELETE]: true,
      },
    },
  },
  {
    category: "labAnalyses",
    actions: {
      all: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: true,
        [ActionKey.DELETE]: true,
      },
    },
  },
  {
    category: "sensors",
    actions: {
      all: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: null,
        [ActionKey.UPDATE]: null,
        [ActionKey.DELETE]: null,
      },
    },
  },
  {
    category: "tasks",
    actions: {
      all: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: true,
        [ActionKey.DELETE]: true,
      },
      my: {
        // newly added
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: null,
        [ActionKey.UPDATE]: null,
        [ActionKey.DELETE]: null,
      },
    },
  },
  {
    category: "general",
    actions: {
      documents: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: true,
        [ActionKey.DELETE]: true,
      },
      advices: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: true,
        [ActionKey.DELETE]: true,
      },
    },
  },
  {
    category: "cultivationOverview",
    actions: {
      all: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: null,
        [ActionKey.UPDATE]: null,
        [ActionKey.DELETE]: null,
      },
    },
  },
  {
    category: "addCrop",
    actions: {
      all: {
        [ActionKey.READ]: null,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: null,
        [ActionKey.DELETE]: null,
      },
    },
  },
  {
    category: "userProfile",
    actions: {
      all: {
        [ActionKey.READ]: null,
        [ActionKey.CREATE]: true,
        [ActionKey.UPDATE]: true,
        [ActionKey.DELETE]: null,
      },
    },
  },
  {
    // newly added
    category: "dataExplorer",
    actions: {
      all: {
        [ActionKey.READ]: true,
        [ActionKey.CREATE]: null,
        [ActionKey.UPDATE]: null,
        [ActionKey.DELETE]: null,
      },
    },
  },
];

export const compileRules = ({
  id,
  type,
  role,
  owner,
}: {
  id: string;
  type: UserSchema["type"] | `${UserType.ADMIN}/${UserType.THIRD_PARTY}`;
  role: UserRole | null;
  owner: boolean;
}) => {
  const { can, cannot, rules } = new AbilityBuilder(Ability);

  const predefinedPermissions = {
    // special cases for some company pages... redundant?
    "crud-locations": [UserType.ADMIN, UserType.ADVISOR],
    "crud-user-management": [UserType.ADMIN, UserType.ADVISOR],

    // special case for documents
    "edit-documents-platform": [UserType.ADVISOR, UserType.THIRD_PARTY],
    "edit-documents-company": [UserType.ADMIN, UserType.ADVISOR],
    "edit-documents-crop": [UserType.ADMIN, UserType.ADVISOR],

    // fields in company profile
    "crud-labCustNumber": [UserType.ADMIN, UserType.ADVISOR],
    "crud-companyLabels": [UserType.ADMIN, UserType.ADVISOR],
    "crud-pdfWeatherOff": [UserType.ADMIN, UserType.ADVISOR],
    "crud-pdfWeekOff": [UserType.ADMIN, UserType.ADVISOR],
    "crud-bulbGrower": [UserType.ADMIN, UserType.ADVISOR],
    "crud-sensorProvider": [UserType.ADVISOR],

    "crud-admin-area": [UserType.ADVISOR],
    "crud-overview-area": [UserType.ADVISOR, UserType.THIRD_PARTY],

    // related to user roles
    "view-roles": SUPERUSER_IDS.includes(id)
      ? [UserType.ADVISOR]
      : owner
        ? [type]
        : [],
    "create-roles": SUPERUSER_IDS.includes(id) ? [UserType.ADVISOR] : [],
    "view-superadmin": SUPERUSER_IDS.includes(id) ? [UserType.ADVISOR] : [],
  };

  const types = type?.split("/");
  Object.entries(predefinedPermissions).forEach(
    ([permission, allowedTypes]) => {
      const [action, ...subjectc] = permission.split("-");
      const subject = subjectc.join("-");
      const allowed = !!intersection(allowedTypes, types).length;
      const rule = allowed ? can : cannot;
      rule(action, subject);
    },
  );

  let finalPermissions = defaultPermissions();
  const { permissions: customPermissions } = role || {};
  if (customPermissions) {
    const defaultKeys = defaultPermissions().map((x) => x.category);
    const roleKeys = role?.permissions.map((x) => x.category) || [];
    const mergedKeys = uniq([...defaultKeys, ...roleKeys]);

    const mergedPermissions = mergedKeys.flatMap((key) => {
      const currentPermissionsForCategory = role?.permissions.find(
        (x) => x.category === key,
      );
      const defaultPermissionsForCategory = defaultPermissions().find(
        (x) => x.category === key,
      );
      if (!currentPermissionsForCategory)
        return defaultPermissionsForCategory
          ? [defaultPermissionsForCategory]
          : [];

      const val = mergeWith(
        currentPermissionsForCategory,
        defaultPermissionsForCategory,
        mergePermissions,
      );
      return val ? [val] : [];
    });
    finalPermissions = mergedPermissions;
  }

  finalPermissions.forEach((permission) => {
    const { category, actions } = permission;
    Object.entries(actions).forEach(([action, values]) => {
      Object.entries(values).forEach(([actionType, value]) => {
        // allow all actions if the current user has no role assigned or are of advisor type
        const finValue =
          customPermissions &&
          [
            UserType.USER,
            UserType.GROWER,
            `${UserType.ADMIN}/${UserType.THIRD_PARTY}`,
            UserType.ADMIN,
            UserType.THIRD_PARTY,
          ].includes(type)
            ? value
            : true;
        if (finValue !== null) {
          const rule = finValue ? can : cannot;
          const subject = [category, action].join("-");
          rule(actionTypeMap[actionType as ActionKey], subject);
        }
      });
    });
  });

  return rules;
};

export const mergePermissions = (
  objValue: ReturnType<typeof defaultPermissions>[number],
  srcValue: ReturnType<typeof defaultPermissions>[number],
): ReturnType<typeof defaultPermissions>[number] => {
  if (isObject(objValue) && isObject(srcValue)) {
    return mergeWith(objValue, srcValue, mergePermissions);
  } else if (objValue === null && isBoolean(srcValue)) {
    return srcValue;
  }
  return objValue;
};
