import { db } from "@/firebase";
import { computed, ref, watch } from "vue";
import { defineStore } from "pinia";
import { collection, doc, updateDoc } from "firebase/firestore";

import { useDocument, useFirebaseAuth } from "vuefire";
import type { User } from "@/models/user.model.js";
import { whenever } from "@vueuse/core";
import { flattenObjectToDotNotation, type DeepPartial } from "@/utils/utils";
import { compileRules, ab } from "@/services/casl";
import { CompanyUser } from "@/models/company-user";
import { i18n } from "@/i18n";
import { updatePassword, type User as AuthUser } from "firebase/auth";
import { useCompany } from "@/composables/use-company";
import { useUserRoles } from "@/composables/use-user-roles";

export const useUserStore = defineStore("user", () => {
  const auth = useFirebaseAuth();
  const authUser = ref<AuthUser | null>(null);

  const { currentCompany } = useCompany();
  const { userRoles, isLoading: rolesLoading } = useUserRoles();

  const userQuery = computed(() => {
    if (authUser.value) return doc(collection(db, "users"), authUser.value.uid);
    return null;
  });

  const { data: userInDb, pending: isLoading } = useDocument<User>(userQuery, {
    maxRefDepth: 1,
  });
  const userCtx = computed(() => {
    if (!userInDb.value || rolesLoading.value) return null;
    const cuser = CompanyUser.create(userInDb.value, {
      currentCompany: currentCompany.value ?? null,
      userRoles: userRoles.value,
    });
    return {
      id: cuser.id,
      type: cuser.contextCmp.type,
      role: cuser.contextCmp.role.value,
      allowedLocations: cuser.contextCmp.allowedLocations,
      owner: userInDb.value.config.companyOwner ?? false,
    };
  });
  const setRules = () => {
    if (auth?.currentUser && auth.currentUser.uid === userCtx.value?.id) {
      const rules = compileRules({
        id: userCtx.value.id,
        type: userCtx.value.type,
        role: userCtx.value.role,
        owner: userCtx.value.owner,
      });
      ab.update(rules);
      rulesLoaded.value = true;
    } else {
      ab.update([]);
      rulesLoaded.value = false;
    }
  };

  auth?.onAuthStateChanged(() => {
    setRules();
  });

  const fetchComplete = ref(false);
  const rulesLoaded = ref(false);

  // const { locale } = useI18n();
  const { locale } = i18n.global;
  function setAuthUser(userObj: any) {
    authUser.value = userObj;
  }

  function reset() {
    authUser.value = null;
  }

  function getPhotoOwnerId() {
    // legacy: photos used to be stored with the userId in the url,
    // for newer uploaded photos we use the companies id
    // @TODO:
    /* state.selectedCompany?.originalUserId || */
    /* state.selectedCompany?.id || */
    return userInDb.value?.company?.originalUserId || userInDb.value?.companyId;
  }

  watch(
    [userCtx, fetchComplete],
    () => {
      if (fetchComplete.value === true) {
        setRules();
      }
    },
    { immediate: true },
  );

  // debug info
  whenever(authUser, () => console.debug(`👋 Firebase Auth user loaded`));
  whenever(userInDb, () => console.debug(`👋 User from db initially loaded`));

  enum Language {
    EN = "en",
    NL = "nl",
    DE = "de",
    FR = "fr",
  }
  watch(
    [userInDb, authUser],
    ([data, authData]) => {
      if (data?.company?.id && authData) {
        console.debug("✅ User from db fully loaded", data);
        fetchComplete.value = true;
      }
      const languages = [Language.EN, Language.NL, Language.DE, Language.FR];

      const languagePriority = [
        authData ? data?.profile?.preferredLanguage?.code : null,
        navigator.language.split("-")[0],
        "en",
      ].filter(
        (locale) =>
          typeof locale === "string" && languages.includes(locale as Language),
      ) as Language[];

      locale.value = languagePriority[0];
      // change in html tag
      document.documentElement.lang = locale.value;
    },
    { deep: true, immediate: true },
  );

  const saveUser = async (updateObj: DeepPartial<User>) => {
    const userId = userInDb.value?.id;
    if (!userId) throw new Error("No user id found");
    const flatUpdateObj = flattenObjectToDotNotation(updateObj);
    await updateDoc(doc(db, `users/${userId}`), flatUpdateObj);
  };

  const updatePasswordFn = async (password: string) => {
    if (!authUser.value) return;
    updatePassword(authUser.value, password);
  };

  return {
    setAuthUser,
    authUser,
    userInDb,
    getPhotoOwnerId,
    isLoading,
    reset,
    fetchComplete,
    rulesLoaded,
    userCtx,
    saveUser,
    updatePassword: updatePasswordFn,
  };
});
