import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { environment } from "@/environments/environment";

const firebaseConfig = {
  apiKey: environment.firebaseKey,
  authDomain: environment.firebaseAuthDomain,
  projectId: environment.firebaseProjectId,
  storageBucket:  environment.firebaseStorageBucket,
  messagingSenderId: environment.firebaseSenderId,
  appId: environment.firebaseAppId,
};

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
