import { defineStore, storeToRefs } from "pinia";
import { computed, ref, reactive, watch, watchEffect, type Ref } from "vue";
import { mergeWith, uniq } from "lodash-es";
import { useCompanyStore } from "./company";
import type { UserRole } from "@/models/user-role.model";
import { getUserRoles } from "@/queries/user-roles";
import { useVuefire } from "@/utils/firestore/use-vuefire";
import { defaultPermissions, mergePermissions } from "@/services/casl";

export const useUserRolesStore = defineStore("user-roles", () => {
  const { useCollection } = useVuefire();

  const userRoles = ref<Array<UserRole>>([]);

  const companyStore = useCompanyStore();
  const { currentCompany } = storeToRefs(companyStore);

  const userRolesQueries = computed(() => {
    const companyId = currentCompany.value?.id;
    if (!companyId) return null;
    return getUserRoles({
      companyId,
    });
  });

  const enrichRoles = (roles: UserRole[]) => {
    return roles.map((role) => {
      const defaultKeys = defaultPermissions().map((x) => x.category);
      const roleKeys = role.permissions.map((x) => x.category);
      const mergedKeys = uniq([...defaultKeys, ...roleKeys]);

      const mergedPermissions = mergedKeys.flatMap((key) => {
        const currentPermissionsForCategory = role.permissions.find(
          (x) => x.category === key,
        );
        const defaultPermissionsForCategory = defaultPermissions().find(
          (x) => x.category === key,
        );

        const val = mergeWith(
          currentPermissionsForCategory,
          defaultPermissionsForCategory,
          mergePermissions,
        );
        return val ? [val] : [];
      });

      return { ...role, permissions: mergedPermissions };
    });
  };

  const state = reactive<{ collections: Array<any> }>({ collections: [] });
  watch(
    userRolesQueries,
    (newUserRolesQueries: any) => {
      if (newUserRolesQueries) {
        state.collections = [];
        newUserRolesQueries.forEach((query: any) => {
          const collection = useCollection(query, {
            maxRefDepth: 0,
          });
          state.collections.push(collection);
        });
      }
    },
    { immediate: true },
  );

  const data: Ref<Array<any>> = ref([]);
  const isLoading = ref(true);
  let error: any = null;

  watchEffect(async () => {
    for (const collection of state.collections) {
      if (collection.pending.value) {
        isLoading.value = true;
        return;
      }
      if (collection.error.value) {
        error = collection.error;
        return;
      }
    }
    data.value = state.collections.flatMap(
      (collection) => collection.data.value,
    );
    isLoading.value = false;
  });

  watchEffect(() => {
    userRoles.value = enrichRoles(data.value);
  });

  return {
    isLoading,
    userRoles,
    error,
  };
});
