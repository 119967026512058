export enum CropField {
  GENUS = "genus",
  SPECIES = "species",
  CULTIVAR = "cultivar",
  ADD_SUBSTRATE_INFO = "addSubstrateInfo",
  REFERENCE_GENUS = "referenceGenus",
  PHASE = "phase",
  CONTAINER = "container",
  STARTING_NUMBER_OF_POTS = "startingNumberOfPots",
  CURRENT_NUMBER_OF_POTS = "currentNumberOfPots",
  DRIPPERS = "drippers",
  BATCH_NUMBER = "batchNumber",
  YOUNG_PLANT_SUPPLIERS = "youngPlantSuppliers",
  BUSINESS_LOCATION = "businessLocation",
  LOCATION_LEVEL_1 = "locationLevel1",
  LOCATION_LEVEL_2 = "locationLevel2",
  SIZE_DESCRIPTION = "sizeDescription",
  SUPPLIER_DOCUMENT_NR = "supplierDocumentNr",
  CONTAINER_NR = "containerNr",
  SUBSTRATE_MIXTURE = "substrateMixture",
  FERTILIZATION = "fertilization",
  BARCODE = "barcode",
  LAB_ID = "labId",
  SPACING_DATE = "spacingDate",
  WEEK_NUMBER = "weekNumber",
  POT_DATE = "potDate",
  END_DATE = "endDate",
  EXPECTED_CULTIVATION_TIME = "expectedCultivationTime",
  DESCRIPTION = "description",
  OBJECTIVES = "objectives",
  BULB_SIZE = "bulbSize",
  PERSON = "person",
  PLANTS_PER_CONTAINER = "plantsPerContainer",
  NUMBER_OF_CONTAINERS = "numberOfContainers",
}

interface CropFieldOption {
  id: CropField;
  label: string;
  disabled?: boolean;
}

export const cropFieldsOptions: CropFieldOption[] = [
  {
    id: CropField.GENUS,
    label: "addCrop.genericName",
    disabled: true,
  },
  {
    id: CropField.SPECIES,
    label: "addCrop.specificEpithet",
    disabled: true,
  },
  {
    id: CropField.CULTIVAR,
    label: "addCrop.cultivar",
    disabled: true,
  },
  { id: CropField.ADD_SUBSTRATE_INFO, label: "addCrop.addSubstrateInfo" },
  {
    id: CropField.REFERENCE_GENUS,
    label: "addCrop.referenceGenus",
    disabled: true,
  },
  { id: CropField.PHASE, label: "addCrop.phase", disabled: true },
  {
    id: CropField.CONTAINER,
    label: "addCrop.container",
    disabled: true,
  },
  {
    id: CropField.STARTING_NUMBER_OF_POTS,
    label: "tableComponent.startingNumberOfPots",
  },
  {
    id: CropField.CURRENT_NUMBER_OF_POTS,
    label: "tableComponent.currentNumberOfPots",
  },
  { id: CropField.DRIPPERS, label: "addCrop.drippers" },
  { id: CropField.BATCH_NUMBER, label: "addCrop.batchNumber" },
  {
    id: CropField.YOUNG_PLANT_SUPPLIERS,
    label: "addCrop.youngPlantSuppliers",
  },
  {
    id: CropField.BUSINESS_LOCATION,
    label: "addCrop.businessLocation",
    disabled: true,
  },
  {
    id: CropField.LOCATION_LEVEL_1,
    label: "locations.locationLevel1",
    disabled: true,
  },
  {
    id: CropField.LOCATION_LEVEL_2,
    label: "locations.locationLevel2",
    disabled: true,
  },
  { id: CropField.SIZE_DESCRIPTION, label: "addCrop.sizeDescription" },
  {
    id: CropField.SUPPLIER_DOCUMENT_NR,
    label: "tableComponent.supplierDocumentNr",
  },
  { id: CropField.CONTAINER_NR, label: "tableComponent.containerNr" },
  { id: CropField.SUBSTRATE_MIXTURE, label: "addCrop.substrateMixture" },
  { id: CropField.FERTILIZATION, label: "addCrop.fertilization" },
  { id: CropField.BARCODE, label: "addCrop.barcode" },
  { id: CropField.LAB_ID, label: "addCrop.labId", disabled: true },
  {
    id: CropField.SPACING_DATE,
    label: "addCrop.spacingDate",
  },
  {
    id: CropField.WEEK_NUMBER,
    label: "addCrop.weekInYear",
    disabled: true,
  },
  {
    id: CropField.POT_DATE,
    label: "addCrop.dateOfPotting",
    disabled: true,
  },
  { id: CropField.END_DATE, label: "addCrop.endDate", disabled: true },
  {
    id: CropField.EXPECTED_CULTIVATION_TIME,
    label: "addCrop.expectedCultivationTime",
    disabled: true,
  },
  { id: CropField.DESCRIPTION, label: "addCrop.description" },
  { id: CropField.OBJECTIVES, label: "addCrop.objectives" },
  { id: CropField.BULB_SIZE, label: "addCrop.bulbSize.title" },
  { id: CropField.PERSON, label: "tableComponent.personPlanted" },
  {
    id: CropField.PLANTS_PER_CONTAINER,
    label: "addCrop.plantsPerContainer",
  },
  {
    id: CropField.NUMBER_OF_CONTAINERS,
    label: "addCrop.numberOfContainers",
  },
];
