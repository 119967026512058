import { transform, isObject, isArray, each } from "lodash";

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? DeepPartialArray<U>
    : T[P] extends ReadonlyArray<infer U>
      ? DeepPartialReadonlyArray<U>
      : DeepPartial<T[P]>;
};

interface DeepPartialArray<T> extends Array<DeepPartial<T>> {}

interface DeepPartialReadonlyArray<T> extends ReadonlyArray<DeepPartial<T>> {}

interface FlattenedObject {
  [key: string]: any;
}

export const flattenObjectToDotNotation = (obj: any): FlattenedObject => {
  return transform(
    obj,
    (result: FlattenedObject, value: any, key: string | number) => {
      if (isObject(value) && !isArray(value)) {
        const flat = flattenObjectToDotNotation(value);
        each(flat, (val: any, nestedKey: string) => {
          result[key + "." + nestedKey] = val;
        });
      } else {
        result[key] = value;
      }
    },
    {},
  );
};

