export default {
  index: {
    vidFname: "log-and-solve-comp-nl.mp4",
    topNav: {
      item1: "Voordelen",
      item2: "Contact",
      item3: "Dashboard",
      item4: "Login",
    },
    hero: {
      title: "BETER TELEN MET DATA",
      intro:
        "<p>Log & Solve stelt u als kweker in staat om op eenvoudige wijze verschillende relevante teeltgegevens op één centrale plek te verzamelen.\n      </p>\n      <p>\n      De gecombineerde teeltdata geeft u nieuwe inzichten in het verloop van de teelt, teeltgroei of teeltverbeteringsmogelijkheden. Op basis hiervan kan u goed onderbouwde teeltbeslissingen nemen, met een hoger rendement als resultaat.\n      </p>\n      <p>\n      Vanaf nu kan iedere kweker in Nederland en België kosteloos gebruik maken van de basisversie Log & Solve.\n      </p>\n      ",
      email: "Email ons voor gratis deelname",
    },
    chats: [
      {
        title: "Vergelijk foto's",
        content: "Hoe is de stand van het gewas t.o.v. vorig jaar?",
      },
      {
        title:
          "Speel in drop plotseERROR  render function or template not defined in component: anonymouslinge klimaatschommelingen",
        content: "Vochtgehalte substraat is te laag t.o.v. instraling",
      },
      {
        title: "Geef minder water!",
        content: "Potkluit is te nat!",
      },
      {
        title: "Verhoog je EC",
        content: "Voedingsniveau te laag!",
      },
    ],
    cards: [
      {
        title: "Minimaliseer uitval",
        content:
          "Het combineren van teeltdata kan verklaren waarom planten soms slecht groeien. Dit helpt u meer uit uw teelt te halen en opbrengsten te verhogen.",
      },
      {
        title: "Directe communicatie met adviseurs",
        content:
          "Digitale teeltdata kan gedeeld worden met uw adviseur(s). Op deze manier bepaald u wie inzicht heeft in uw teeltgegevens en kunt u snel bijsturen indien nodig.",
      },
      {
        title: "Leg uw kennis en bedrijfswaarde vast",
        content:
          "Of u uw bedrijf ooit wilt verkopen of uw kennis wilt doorgeven aan de volgende generatie. Het loggen van teeltdata helpt u met het inzichtelijk maken van deze waarde",
      },
      {
        title: "Snelle en soepele certificering",
        content:
          "Registreer het gebruik van meststoffen, gewasbescherming etc. Log&Solve ondersteunt u in het verzamelen van data wat leid tot meer transparantie in communicatie met uw afnemer.",
      },
    ],
    companyPitch: {
      content:
        "Door het verzamelen van relevante data helpen wij u om meer inzicht te verkrijgen in uw teeltprocessen Continue loggen van notitites, foto's, water, substraatanalyses en andere relevante data helpt u met het in kaart brengen van uw situatie. Daardoor is het geven van goed advies en het snel vinden van oplossingen voor uw teelt problemen een stuk eenvoudiger en sneller. Wij streven naar het voorkomen van uitval, ziektes en andere uitdagingen bij de teelt van uw gewassen. Wij combineren intelligente software met de kennis van uw ervaren teeltadviseurs.",
      smallText: "Mede mogelijk gemaakt door Klasmann-Deilmann",
    },
    contact: {
      title: "Contact",
      intro:
        "Bent u geïnteresseerd in de mogelijkheden van Log & Solve voor uw kwekerij?  Vraag ernaar bij uw Klasmann-Deilmann vertegenwoordiger of neem direct contact met ons op via",
      buttonText: "E-mail ons voor informatie",
      persons: [
        {
          name: "Co-Anne van Waaij",
          email: "log.solve@klasmann-deilmann.com",
          phoneNumber: "tel: +31.6.13.53.50.54",
        },
      ],
    },
    faq: {
      titleOne: "Over GDPR, eigendom data en beveiliging",
      itemsOne: [
        {
          title: "Ben ik zelf de beheerder van mijn dashboard en data?",
          content:
            "Ja. U bent altijd eigenaar van uw data en kunt die op ieder moment uit het platform verwijderen. Log & Solve volgt de GDPR regelgeving op het gebied van data privacy; de General Data Protection Regulation opgesteld door de Europese Unie.",
        },
        {
          title: "Wat betekent GDPR?",
          content:
            "De General Data Protection Regulation is een Europese wet die is aangenomen op 25 mei 2018. Deze wet verplicht organisaties om persoonlijke data te beschermen en privacy rechten van gebruikers te respecteren. De wet beschrijft zeven data-bescherming principes ter implementatie, plus acht privacy wetten waar aan voldaan moet worden. Tevens stelt deze wetgeving lokale autoriteiten in staat sancties op te leggen aan bedrijven en organisaties die hier niet aan voldoen.",
        },
        {
          title: "Wie is de eigenaar van mijn data?",
          content:
            "Als gebruiker van Log & Solve – en de tools daarbinnen – bent u altijd eigenaar van uw data. Uw data wordt strikt vertrouwelijk behandeld en niet gedeeld met derde partijen zonder dat hiervoor expliciete toestemming wordt gevraagd. Log & Solve is geïnitieerd door- en eigendom van Klasmann-Deilmann.",
        },
        {
          title:
            "Voor welke doeleinden kan Klasmann-Deilmann uw data gebruiken?",
          content:
            "Het aanbieden van de Log & Solve services, Om u van dienst te zijn met specifiek advies en/of statistische analyse van uw data, Om geautomatiseerde alerts, opmerkingen en berichten aangaande uw teelten te sturen, Om geanonimiseerde en geaggregeerde statische analyses uit te voeren ten behoeve van product- en service verbetering, en Om onze gegevensbeveiliging verder te verbeteren en/of aan te passen.",
        },
        {
          title: "Wordt mijn historische data opgeslagen in mijn dashboard?",
          content:
            "Ja, uw historische data blijft veilig bewaard in Log & Solve. U kunt zelf nieuwe teelt tabbladen aanmaken, en oudere teelten vergelijken met uw huidige teelt. Deze functies zijn momenteel volop in ontwikkeling.",
        },
        {
          title: "Word mijn teeltdata veilig opgeslagen?",
          content:
            "Log & Solve volgt de hoge veiligheidsstandaarden die gangbaar zijn en breed geaccepteerd worden. De hosting van Log & Solve wordt verzorgd door Amazon AWS, een gerespecteerde en goed beveiligde cloud hosting aanbieder. Onze servers worden gemonitord op ieder teken van verdachte activiteit en de software wordt regelmatig bijgewerkt me beveiligingsupdates.",
        },
      ],
      titleTwo: "Kosten",
      itemsTwo: [
        {
          title: "Kan ik Log & Solve eerst kosteloos uitproberen?",
          content:
            "Ja. Het is mogelijk een test account twee maanden kosteloos te proberen.",
        },
        {
          title: "Wat kost het reguliere gebruik van Log & Solve?",
          content:
            "Log & Solve is beschikbaar in verschillende vormen, die op basis van een maandelijks abonnement in rekening worden gebracht. Dit is afhankelijk van het aantal gewenste functionaliteiten en extra’s. De basis van Log & Solve is het gebruik van dashboard en vastleggen van teelthandelingen, notities en foto’s.",
        },
        {
          title:
            "Aanvullend hierop biedt Log & Solve verschillende opties, die kunnen worden toegespitst op uw bedrijf en uw behoeftes. Bijvoorbeeld:",
          content:
            "Monstername potgrond, drain- en basinwater, technisch advies, inzet van sensoren voor real-time data (potvocht, temperatuur, luchtvochtigheid, lichtinstraling, …), en verschillende mogelijkheden om persoonlijke wensen mee te nemen in verdere ontwikkeling.",
        },
        {
          title:
            "Neemt u gerust contact op voor meer informatie of een persoonlijke afspraak.",
          content: "",
        },
      ],
      titleThree: "De functies van Log & Solve",
      itemsThree: [
        {
          title: "Kan ik teeltdata vastleggen per teeltpartij?",
          content:
            "Ja, het is mogelijk om allerlei zaken als notities, teeltacties, foto’s en analyse resultaat per gewas en per partij inzichtelijk te maken. Het team van Log & Solve kan u ondersteunen bij het opzetten van de verschillende pagina’s. U blijft toegang houden tot teeltdata van partijen die reeds zijn uitgeleverd.",
        },
        {
          title:
            "Hoe kan ik analyse resultaten (bijvoorbeeld laboratorium rapporten) toevoegen aan mijn dashboard?",
          content:
            "Alle analyseresultaten worden automatisch ingeladen in uw dashboard door ons team. De labs waarmee Log & Solve samenwerkt, leveren de gegevens aan in een format waarmee dit automatisch verwerkt kan worden. Het is tevens mogelijk dat Log & Solve de monstername voor uw bedrijf organiseert.",
        },
        {
          title:
            "Kan ik Log & Solve gebruiken op mijn mobiele apparaten (smartphone, tablet)?",
          content:
            "Ja, Log & Solve is goed bruikbaar op mobiele apparaten. De dashboards en alle functies zijn volledig te gebruiken op smartphones en tablets. Het is mogelijk om foto’s te maken met uw telefoon – of te importeren uit uw telefoon galerij – en direct toe te voegen aan uw dashboard. Ditzelfde geldt voor notities van waarnemingen en teelthandelingen. Log & Solve is gebaseerd op een webpagina, u hoeft geen app te downloaden.",
        },
        {
          title:
            "Hoe kan Klasmann-Deilmann mij van dienst zijn door middel van Log & Solve?",
          content:
            "Klasmann-Deilmann kan u ondersteunen in uw teelt aansturing door advies op basis van uw analyse resultaten, sensor data, notities en foto’s. Door dit structureel vast te leggen, ontstaat een belangrijke kennisdatabank waarmee fouten voorkomen kunnen worden. Log & Solve is een aanvulling op de diensten en producten van Klasmann-Deilmann. De service zoals u die gewend bent van uw vertegenwoordiger blijft onveranderd.",
        },
      ],
    },
    copyrightText: "Log & Solve is een digitale dienst van Klasmann-Deilmann",
  },
  docs: {
    title: "Documents",
    newDocument: "Nieuw document",
    create: "Aanmaken",
    edit: "Bewerk",
    companyDocument: "Bedrijfsdocument",
    companyDocuments: "Bedrijfsdocumenten",
    platformDocument: "platformdocument",
    platformDocuments: "Platformdocumenten",
    cropDocument: "Teeltdocument",
    cropDocuments: "Teeltdocumenten",
    addLinkHere: "Voeg hier een link toe",
    shareWith: "Deel met",
    shareWithAllCompanies: "Deel met alle bedrijven",
    file: "Bestand",
    link: "Link",
    allEn: "Alle Engelse bedrijven",
    allDe: "Alle Duitse bedrijven",
    allNl: "Alle Nederlandse bedrijven",
    allFr: "Alle Franse bedrijven",
    documentPreview: "Voorbeeld van een document",
    relatedToLocations: "Gerelateerd aan locaties",
    includeInCrops: "Neem deze informatie op in de teelten",
  },
  dashboard: {
    title: "Teeltdata",
    components: {
      mainNavigation: [
        {
          text: "Teeltdata",
        },
        {
          text: "Algemeen",
        },
        {
          text: "Mijn taken",
        },
        {
          text: "Gebruikersprofiel",
        },
        {
          text: "Sensoren",
        },
        {
          text: "Klasmann-Deilmann webshop",
        },
        {
          text: "Teeltoverzicht",
        },
        {
          text: "FAQ",
        },
        {
          text: "Speisekarte",
        },
        {
          text: "Voeg teelt toe",
        },
        {
          text: "Bedrijfsprofiel",
        },
        {
          text: "Uitloggen",
        },
        {
          text: "Notitie",
        },
        {
          text: "Admin",
        },
        {
          text: "Wissel van bedrijf",
        },
        {
          text: "Protocollen",
        },
        {
          text: "Samenvatting",
        },
      ],
      cropNavigation: {
        showMoretext: "Meer gewassen",
        items: [
          {
            id: "log",
            text: "Log",
            slug: "",
            icon: "clipboard-list",
          },
          {
            id: "labAnalyses",
            text: "Lab analyses",
            slug: "lab",
            icon: "flask",
          },
          {
            id: "photos",
            text: "Foto's",
            slug: "photos",
            icon: "image",
          },
          {
            id: "profile",
            text: "Gewasprofiel",
            slug: "edit",
            icon: "edit",
          },
          {
            id: "qrCode",
            text: "Download QR-code",
            slug: "pdf",
            icon: "file-pdf",
          },
          {
            id: "protocol",
            text: "Protocol",
            slug: "protocol",
            icon: "scroll",
          },
        ],
      },
      labDataChart: {
        lineDescription: "Optimum",
        selectMetrics: "Selecteer elementen",
        pickMetrics: "Kies parameters",
        pickWeather: "Kies weerparameter",
        ecNutrientContentConverted:
          "Het elementengehalte wordt omgerekend naar de referentie-EC",
        units: {
          length: "Lengte",
          diameter: "Diameter",
          ec: "EC",
          ph: "pH",
          weight: "Gewicht",
          yield: "Opbrengst",
          numberOfBuds: "Aantal knoppen/bloemen",
          substrate: "Substrate",
          drain: "Drain",
          gift: "Gift",
          ditch: "Ditch",
        },
      },
      composition: {
        title: "Verhoudingen",
      },
      ratio: {
        title: "Ratio",
      },
      ec: {
        title: "EC waarde",
      },
      ph: {
        title: "pH waarde",
      },
      analysis: {
        title: "Resultaten van analyses",
        labDataTable: {
          labId: "Lab id",
          date: "Datum",
          export: "Export",
          edit: "Bewerk",
          addNote: "Notitie",
          delete: "Verwijder",
          deleteNote: "Verwijder notitie",
          cancel: "Annuleer",
          addNoteHeader: "Voeg notitie toe",
          sampleDescription: "Monster omschrijving",
          table: {
            titleLeft: "Hoofdelementen in mmol/l",
            titleCenter: "Spoorelementen in umol/l",
            titleRight: "",
            titleFirstColumn: "Monster datum",
            titleUnit: "Unit",
          },
          tableLufa: {
            titleLeft: "Hoofdelementen in mg/l",
            titleCenter: "Spoorelementen in mg/l",
            titleRight: "",
            titleFirstColumn: "Monster datum",
            titleUnit: "Unit",
          },
          legend: {
            title: "Legenda",
            description:
              "Metingen ten opzichte van de Bemestings Advies Basis streefwaardes.",
            ranks: [
              {
                title: "te hoog",
                subTitle: "Afwijking hoger dan 2",
              },
              {
                title: "hoog",
                subTitle: "Afwijking van 1 of hoger",
              },
              {
                title: "laag",
                subTitle: "Afwijking van -1 of lager",
              },
              {
                title: "te laag",
                subTitle: "Afwijking lager dan -2",
              },
            ],
          },
          settings: {
            title: "Instellingen",
            displayUnit: "Weergave-eenheid",
          },
        },
        overview: "Overzicht analyse resultaten",
      },
      photos: {
        title: "Teelt foto's",
        buttons: {
          remove: {
            value: "Verwijder",
            singular: "foto",
            plural: "foto's",
          },
          edit: {
            value: "Bewerk",
          },
          view: {
            value: "Bewerking annuleren",
          },
        },
        options: {
          all: {
            label: "Alle foto's",
          },
          overview: {
            label: "Overzicht",
          },
          single: {
            label: "Enkele plant",
          },
          detail: {
            label: "Detail",
          },
          roots: {
            label: "Wortels",
          },
        },
      },
      uploadPhoto: {
        bigTitle: "Teelt Foto's",
        subTitle: "Foto details:",
        caption: "Opmerking",
        firstOption: "Kies een foto",
        secondOption: "Type foto",
        success: "Foto opgeslagen!",
        options: {
          overview: {
            value: "Overzicht",
          },
          single: {
            value: "Enkele plant",
          },
          detail: {
            value: "Detail",
          },
          roots: {
            value: "Wortels",
          },
        },
        thirdOption: "Datum",
        button: "Opslaan",
      },
      cropProfile: {
        bigTitle: "Teeltprofiel",
        firstCategory: {
          title: "Teeltprofiel",
          cultivation: {
            text: "Referentie soort",
            value: "Kies (vergelijkbaar) gewas",
            shrubsTrees: "(struik / boom)",
          },
          familyName: {
            text: "Soortnaam",
            placeholder: "Bijv. benjamina",
          },
          name: {
            text: "Variëteit",
            placeholder: "Bijv. danielle",
          },
          pottingDate: "Oppotdatum",
          spacingDate: "Datum binnenkomst stek",
          deliveryDate: "Afleverdatum (verwacht)",
          potSize: "Potmaat",
          location: {
            text: "Locatie in de kwekerij",
            placeholder: "Bijv. 'Kap 7' of 'Tuin 3'",
          },
          rideNumber: "Ritnummer substraatlevering",
          soilAdditive: "Overige potgrond toevoegingen",
          certification: {
            text: "Certificeringen",
            options: {
              mps: {
                text: "MPS",
              },
              globalGap: {
                text: "Global GAP",
              },
              skal: {
                text: "SKAL",
              },
            },
          },
          other: "Overig",
          button: "Opslaan",
        },
        secondCategory: {
          title: "Teeltomstandigheden",
          fertilization: {
            text: "Bemestings methode",
            placeholder: "Bijv. 'Osmocote' of 'AB bak'",
          },
          waterSource: {
            text: "Waterbron",
            placeholder: "Bijv. 'Bassin water' of 'Sloot water'",
          },
          irrigation: {
            text: "Bewateringsmethode",
            placeholder: "Bijv. 'Onderlangs' of 'Druppelaars'",
          },
          resource: "Gewasmiddelen",
          construction: {
            text: "Teeltconstructie",
            options: {
              containerField: {
                text: "Containerveld (buiten)",
              },
              greenhouse: {
                text: "Kas",
              },
              polytunnel: {
                text: "Folie tunnel",
              },
              outdoorShadowNet: {
                text: "Buiten teelt onder schaduwnet",
              },
              other: {
                text: "Anders",
              },
            },
          },
          extraRemarks: {
            text: "Extra notities teeltomstandigheden",
            placeholder: "Bijv. 'Biologisch' of 'Tuincentra kwaliteit'",
          },
          button: "Opslaan",
        },
        thirdCategory: {
          title: "Doelstellingen en uitdagingen voor deze teelt",
          comment: "",
          button: "Opslaan",
        },
      },
      notes: {
        bigTitle: "Teelt Notities",
        new: {
          title: "Nieuwe notitie",
          hiddenTitle: "Notitie categorie",
          classButton: "+ Voeg toe",
          saveButton: "Opslaan",
        },
        saved: {
          title: "Opgeslagen notities",
          firstAttributes: "Datum:",
          secondAttributes: "Categorie:",
          buttonAttributes: "Verwijder",
          hiddenAttributes: "Notitie",
        },
        categories: {
          waterAndFertilization: {
            label: "Water en bemesting",
          },
          cropControl: {
            label: "Middelen registratie",
          },
          cultivationActivity: {
            label: "Teelthandeling",
          },
          observation: {
            label: "Observatie",
          },
          misc: {
            label: "Overig",
          },
        },
      },
      selectLanguage: {
        firstSelect: "Selecteer taal",
      },
    },
    dashboard: "Dashboard",
    dashboardCaption: " Een samengevoegd overzicht van al uw gegevens.",
    cultivations: "Teeltoverzicht",
    cultivationsCaption: " Een samengevoegd overzicht van al uw teeltgegevens.",
    loveYourFeedback: " We ontvangen graag uw feedback",
    latestActivity: " Laatste activiteit",
    protocols: "Protocollen",
    recentTasks: " Recente taken",
  },
  userManagement: {
    title: "Gebruikersmanagement",
    user: {
      title: "Gebruiker",
      name: "Naam",
      permissions: "Gebruikersrechten",
      role: "Rol",
      active: "Actief",
      actions: "Acties",
      email: "Email",
      status: "Status",
      inactive: "Inactief",
      userConfirmation: " Bevestiging van gebruiker",
      pendingRequest: "Aanvraag in behandeling",
      awaitingRequest: "Verzoek in afwachting",
    },
    thirdParty: {
      title: "Derde partij gebruikers",
      accept: "Accepteren",
      reject: "Afwijzen",
      awaitingAction: "In afwachting van reactie",
      revokeAccess: "Toegang intrekken",
      requestAccess: "Verzoek toegang derde partij",
      revoked: "Toegang ingetrokken voor derde gebruiker",
      submit: "Verzenden",
      requestSent: "Derde partij uitnodiging verstuurd",
      alreadyInvited:
        "Er is al eerder een uitnodiging naar dit email adres verstuurd",
      backToOverview: "Terug naar overzicht",
      demote: " Degraderen",
    },
    save: "Opslaan",
    userUpdated: "Gebruiker succesvol bijgewerkt!",
    userTypes: [
      {
        id: "user",
        name: "Gebruiker",
      },
      {
        id: "admin",
        name: "Beheerder",
      },
    ],
    advisors: "Adviseur gebruikers",
    emails: {
      accepted: {
        subject: "Uw aanvraag voor een derde partij is geaccepteerd!",
        message: "Uw aanvraag voor een derde partij is geaccepteerd!",
      },
      rejected: {
        subject: "Uw aanvraag voor een derde partij is afgewezen!",
        message: "Uw aanvraag voor een derde partij is afgewezen!",
      },
    },
    admin: {
      title: "Beheerder",
    },
    editPermissions: "Bewerk rechten",
    inviteUser: {
      title: "Maak account aan",
      fields: {
        email: "Email adres",
        prefLang: "Taal voorkeur",
        invitationType: "Soort uitnodiging",
        selectRole: "Selecteer gebruikersrol",
      },
      submit: "Versturen",
      inviteUrl: "Uitnodigingslink",
    },
    previewingRole: "Voorbeeldrol",
    forAllCompanies: "voor alle bedrijven",
  },
  admin: {
    title: "Beheerder",
    companyName: "Naam",
    contactPerson: "Contact person",
    addCompany: "Voeg bedrijf toe",
    addHelpText: "De naam van het nieuwe toe te voegen bedrijf",
    defaultLanguage: "Gewenste taal",
    addLanguageHelpText:
      "Stel de standaard taalinstelling in van het nieuwe bedrijf",
    companyLabels: "Bedrijfskenmerken",
    companyLabelsHelpText: "Set properties",
    cta: "Voeg bedrijf toe",
    advisorConfig: {
      title: "Advisor configuratie",
      addAdvisor: "Voeg advisor toe",
      updateAdvisor: "Update advisor",
      deleteAdvisor: "Verwijder advisor",
      cancel: "Annuleer",
      editAction: "Wijzig",
      thSlug: "Naam",
      thCompanies: "Bedrijven",
      thAction: "Actie",
      fieldSlug: "Slug",
      fieldCompanyIDs: "CompanyIDs",
      thirdPartyConfig: "Third party config",
    },
    roleConfig: {
      title: "Role Config",
      addRole: "Add role",
      editRole: "Edit role",
      rolesTable: {
        title: "Roles",
        columns: ["Role", "Actions"],
      },
      roleName: "Role name",
      permissionsTable: {
        columns: ["Action", "View", "Create", "Edit", "Delete"],
      },
      allowedLocations: {
        title: "Allowed locations",
        label: "Selecteer de locaties waartoe de gebruiker toegang krijgt",
      },
      forAllCompanies: "Toon bij alle bedrijven",
      forThirdParty: "Rol voor derde partijen",
      forThirdPartyDefault: "Wijs standaard toe aan derde partijen",
      save: "Opslaan",
      cancel: "Annuleren",
    },
    adminOwnerConfig: {
      title: "Admin Owner Config",
      selectCompany: "Select company",
      selectUser: "Select user",
      save: "Save",
    },
    userRoleConfig: {
      title: "User Role Config",
      usersTable: {
        title: "Users",
        columns: ["Name", "Role", "Actions"],
      },
      save: "Save",
    },
    sensorConfig: {
      title: "Sensor Config",
      selectCompany: "Selecteer bedrijf",
      save: "Opslaan",
    },
    weatherDataConfig: {
      title: "Weerdata",
      addWeather: "Voeg weerdata toe",
      selectCompany: "Selecteer bedrijf",
      selectLocation: "Selecteer locatie",
      uploadWeatherData: "Upload weerdata",
      save: "Opslaan",
    },
    locationsConfig: {
      title: "Locaties",
      updateLocations: "Update locaties",
      selectLocation: "Selecteer locatie",
      addLocation: "Voeg locatie toe",
      noLocations: "Geen locaties",
      cancel: "Annuleer",
      editAction: "Wijzig",
      fieldSlug: "Slug",
      thSlug: "Slug",
      thLocations: "Locaties",
      thAction: "Actie",
      thName: "Bedrijf",
      thLatitude: "Breedtegraad",
      thLongitude: "Lengtegraad",
    },
    qrCodeSubstrate: {
      title: "QR code substraat",
      uploadFormTitle: "Upload QR code substraat file",
      upload: "Upload",
      success: "{nr} substraat items aangemaakt",
      failure:
        "Er ging iets mis bij het uploaden van handmatige substraat items",
    },
    analyticsConfig: {
      title: "Analytics",
      btnCompanyAnalytics: "Download company analytics",
      btnTrackingAnalytics: "Download tracking analytics",
    },
    account: "Account",
    yourCompanyAccountDetails: " Uw bedrijfsaccountgegevens.",
    yourPersonalAccount: " Uw persoonlijke account.",
    adminOwner: "Beheerder eigenaar",
    addThirdPartyUsers: "Derde partijen toevoegen",
  },
  companyProfile: {
    title: "Bedrijfsprofiel",
    companyId: "Dashboard ID",
    labCustNumber: "Klantnummer laboratorium",
    invite: "Gebruikers uitnodigen",
    inviteType: "Type uitnodiging",
    inviteTypes: [
      {
        id: "user",
        name: "User",
      },
      {
        id: "admin",
        name: "Admin",
      },
    ],
    inviteEmailType: "Invitation email type",
    inviteEmailTypes: [
      {
        id: "newCustomer",
        name: "New customer",
      },
      {
        id: "substrateSampleService",
        name: "Substrate sample service",
      },
      {
        id: "cropServiceLabAnalyses",
        name: "Crop service, lab analyses",
      },
    ],
    addThirdPartyUsers: "Voeg dedrde partij gebruikers toe",
    inviteCta: "Verstuur uitnodiging",
    inviteSuccess: "Uitnodiging verstuurd!",
    userManagement: "Gebruikersbeheer",
    inviteHelp:
      "Geef hier toegang aan andere personen tot uw dashboard. Voer het e-mailadres in om een nieuwe gebruiker uit te nodigen voor dit dashboard.",
    invitationLink: "Uitnodigingslink",
    noPreferredLanguage: "Geen voorkeurstaal",
    sidebar: {
      profile: "Profiel",
      sensorConfig: "Sensor instellingen",
      locationConfig: "Locatie instellingen",
      exports: "Exports",
      tasks: "Alle taken",
      noteChecklists: "Notitie checklists",
      tankMixes: "Tankmixen",
      cropsImport: "Importeer teelten",
      locationsQr: "Locatie QR code",
      userManagement: "User management",
      protocols: "Protocollen",
    },
    InvitationLink: "Uitnodigingslink",
  },
  sensorConfig: {
    providers: {
      blockbax: "Blockbax",
      tentacles: "Tentacles",
    },
  },
  general: {
    sidebar: {
      documents: "Documenten",
      climate: "Klimaatdata",
      fertilization: "Bemesting",
      notes: "Algemene notities",
      labAnalyses: "Lab analyses",
      advices: "Adviezen",
      substrates: "Substraat data",
    },
  },
  languages: {
    nl: "Nederlands",
    en: "Engels",
    de: "Duits",
    fr: "Frans",
  },
  profile: {
    title: "Gebuikersprofiel",
    name: "Naam",
    phone: "Telefoonnummer",
    password: "Wachtwoord",
    changePassword: "Wachtwoord aanpassen",
    confirmPassword: "Herhaal wachtwoord",
    passwordsDontMatch: "Wachtwoord komt niet overeen",
    preferredLanguage: "Gewenste taal",
    youngPlantSuppliers: {
      title: "Leveranciers van uitgangsmateriaal",
    },
    sensorProvider: "Sensor provider",
    bulbGrower: "Bollenteler",
    taskSensorNotificationPreference: "Taak / sensormelding voorkeur",
    contact: {
      title: "Contact",
      companyName: "Bedrijfsnaam",
      clientNumber: "Klantnummer",
      adress: "Adres",
      contactPerson: "Contactpersoon",
      phoneNumber: "Telefoonnummer",
      email: "Email",
    },
    location: {
      title: "Locatie",
      locationAmount: {
        title: "Aantal locaties",
        placeholder: "Aantal teeltlocaties",
      },
      surface: {
        title: "Oppervlakte locatie(s)",
        placeholder: "Bijvoorbeeld '5 hectare'",
      },
      cropAmount: {
        title: "Aantal plant soorten",
        placeholder: "Totaal aantal plantensoorten in uw kwekerij",
      },
      notes: {
        title: "Extra notities",
        placeholder: "Wat is relevant voor adviseurs om te weten?",
      },
      addLocation: "Voeg locatie toe",
      locationsList: "Locatie lijst",
      addLocationLevel1: "Voeg een locatie level 1 toe",
      editLocationLevel1: "Wijzig een locatie level 1",
      addLocationLevel2: "Voeg een locatie level 2 toe",
      editLocationLevel2: "Wijzig een locatie level 2",
      addSurfaceArea: "Voeg oppervlakte toe",
      editSurfaceArea: "Wijzig het oppervlakte",
    },
    growingConditions: {
      title: "Teeltomstandigheden",
      fertilizationMethod: {
        title: "Bemestings methode",
        placeholder: "Bijvoorbeeld 'Osmocote' of 'AB bak'",
      },
      waterSource: {
        title: "Waterbron",
        placeholder: "Bijvoorbeeld 'Bassinwater' of 'Slootwater'",
      },
      waterSample: {
        title: "Bewateringsmethode",
        placeholder: "Bijvoorbeeld 'Onderlangs' of 'Druppelaars'",
      },
      environment: {
        title: "Opzet",
        setupMethods: {
          containerField: {
            text: "Containerveld",
          },
          greenhouse: {
            text: "Kas",
          },
          openSoil: {
            text: "Open grond",
          },
          polytunnel: {
            text: "Polytunnel",
          },
          other: {
            text: "Anders",
          },
        },
        type: {
          title: "Buiten of binnen",
        },
        notes: {
          title: "Extra notities teeltomstandigheden",
          placeholder: "Wat is relevant voor adviseurs om te weten?",
        },
        challenges: {
          title: "Uitdagingen/aandachtspunten",
          placeholder:
            "Bijvoorbeeld 'Biologische bestrijding' of 'Watergebruik verminderen'",
        },
        certification: {
          title: "Certificeringen",
          placeholder: "Bijvoorbeeld 'MPS-A'",
        },
        buttonText: "Opslaan",
      },
    },
    cropFields: {
      title: "Optionele teeltvelden",
    },
    pdfFields: {
      title: "Optionele PDF velden",
    },
    cultivationActivities: {
      title: "Selecteer teelthandelingen in notitieformulier",
    },
    measurementTypes: {
      title: "Selecteer metingen in notitieformulier",
    },
    rating: {
      title: "Selecteer beoordelingsitems in notitieformulier",
    },
    noteLabels: {
      title: "Selecteer aangepaste notitie labels in notitieformulier",
    },
    photoLabels: {
      title: "Selecteer aangepaste fotolabels in notitieformulier",
    },
    wateringUnits: {
      title: "Selecteer standaard bewateringseenheid in notitieformulier",
    },
    cropLabels: {
      fields: "Selecteer gegevens voor op teelt QR-code labels",
      sizes: {
        select: "Selecteer grootte QR code labels",
        large: "Groot",
        small: "Klein",
      },
    },
    settings: {
      title: "Instellingen",
      weatherStats: {
        title: "Verberg weerstatistieken",
        placeholder: "",
        options: {
          tavgAvg: "Gem. Temperatuur",
          tminAvg: "Min. Temperatuur",
          tmaxAvg: "Max. Temperatuur",
          humAvg: "RV",
          irrSum: "Instraling",
          leafSum: "Bladnat",
          rainSum: "Neerslag",
        },
      },
      notificationSettings: {
        title: "Notificatie instellingen",
        sensorAlerts: "Sensor alerts",
        taskAlerts: "Taak alerts",
        dailySummaryEmails: "Dagelijkse samenvatting e-mails",
        endOfCultivationAlertsEmails: "Einde teelt alert e-mails",
      },
    },
    misc: {
      title: "Miscellaneous",
      companyLogo: {
        title: "Bedrijfslogo",
      },
    },
    savedSuccessfully: "Profiel bijgewerkt!",
    unsubscribedSuccessfully: "Unsubscribed from daily alerts.",
    chooseHowToGetNotifications: " Kies hoe u uw meldingen wilt ontvangen",
    chooseWhatMessagesToGet: " Kies welke berichten u wilt ontvangen",
    chooseYourPreferredLanguage: "Kies uw voorkeurstaal",
    substrateMixtures: {
      title: "Codes voor substraatmengsels",
    },
    fillSurfaceForNewCrop: {
      title:
        "Gebruik de volledige oppervlakte gedefinieerd door de locatie bij het toevoegen van een nieuwe teelten",
    },
    cropFullSurfaceAreaForTasks: {
      title:
        "Gebruik volledige oppervlakte gedefinieerd door locatie voor oude teelten zonder oppervlakte gespecificeerd.        (Nuttig wanneer je één teelt per locatie hebt en de bovenstaande optie te veel moeite kost om de oppervlakte voor elke teelt te wijzigen)",
    },
    wateringItems: {
      title: "Velden inbegrepen in notitietype Watergift + EC + Drainage",
    },
  },
  sensors: {
    title: "Sensoren",
    config: "Sensor instellingen",
    loading: "Sensoren laden",
    selectSensors: "Selecteer sensoren",
    selectAll: "Selecteer alles",
    backToAll: "Terug",
    pickYourSensors: "Selecteer sensoren",
    sensorId: "id",
    externalId: "extern id",
    allSensors: "Alle sensoren",
    noSensors: "Geen sensoren gekoppeld",
    noConnectedCrops: "Geen teelten gekoppeld",
    loadingMeasurements: "Loading measurements",
    noMeasurements: "No measurements",
    editSensor: {
      title: "Bewerk sensor",
      connectedCrops: {
        title: "Teelten",
        add: "toevoegen",
        edit: "wijzigen",
        remove: "verwijderen",
        addEdit: "Toevoegen/bewerken gekoppelde teelten aan sensoren",
        crop: "Teelt",
        startEndDate: "Select start / end date",
        noStartDate: "Geen startdatum",
        noEndDate: "Geen einddatum",
      },
      dataYAxisBounds: {
        label: "Gegevens grenzen y-as",
        options: {
          default: "Standaard grenzen",
          boundByValues: "Grenswaarden bepaald door metingen",
        },
      },
      highSoilMoisture: "Hoge bodemvochtigheid",
      highTemperature: "Hoge bodemtemperatuur",
      lowSoilMoisture: "Lage bodemvochtigheid",
      lowTemperature: "Lage bodemtemperatuur",
      receiveAlerts: "Ontvang alerts",
      button: "Opslaan",
    },
    timeRange: {
      title: "Tijdsduur",
      placeholder: "Selecteer",
      options: {
        lastDay: "Laatste dag",
        lastWeek: "Laatste week",
        lastMonth: "Laatste maand",
        lastYear: "Laatste jaar",
      },
    },
    settings: "Instellingen",
    noSensorMessage:
      "Het is mogelijk om sensoren toe te voegen aan uw Log & Solve dashboard. Er zijn twee type sensoren beschikbaar die kunnen worden uitgelezen in Log & Solve. Beide worden in de pot geplaatst om verschillende parameters te monitoren. Grofweg zijn de sensoren onder te verdelen in",
    sensorOffer1: "Meet uw potvocht en pottemperatuur in de pot 4x per uur.",
    sensorOffer2:
      "Meet uw potvocht en pottemperatuur in de pot, lichtinstraling, omgevingstemperatuur, luchtvochtigheid net boven de pot, 12 x per uur.",
    sensorOfferContact:
      "De keuze voor type sensor hangt af van uw wensen, type gewas en teeltsysteem. Voor meer informatie kunt u contact opnemen met Co-Anne van Waaij via co-anne.vanwaaij@klasmann-deilmann.com of +31 6 13 53 50 54.",
    sensorOfferDisclaimer:
      "Let op: Op dit moment kan data uw klimaatcomputer nog niet worden ingelezen in Log & Solve.",
    sensorName: "Sensor naam",
    action: "Actie",
    yourSensorsData: "Uw sensor data",
    aggView: "Een samengevoegd overzicht van al uw gekoppelde sensoren",
    pickMetrics: " Kies parameters",
    noSensorData: "Geen sensorgegevens voor dit datumbereik",
    pleasePickSensors: "Kies sensoren en parameters om gegevens weer te geven",
    sensors: "sensoren",
  },
  locations: {
    title: "Locaties",
    config: "Locatie instellingen",
    businessLocations: "Bedrijfslocaties",
    locations: "Locaties",
    location: "Locatie",
    slevels: ["Bedrijfslocatie", "Locatieniveau 1", "Locatieniveau 2"],
    mlevels: [
      "Bedrijfslocaties",
      "Locatieniveau 1 Locaties",
      "Locatieniveau 2 locaties",
    ],
    divideSurface: "Verdeel oppervlakte",
    surfaceArea: "Oppervlakte",
    addNew: "Voeg nieuwe level {lvl} locatie toe",
    edit: "Wijzig",
    remove: "Verwijder",
    saveAdd: "Opslaan + nieuw",
    save: "Opslaan",
    cancel: "Cancel",
    onlySameBusinessLocs:
      "U kunt de locatie niet wijzigen voor gewassen met verschillende bedrijfslocaties.",
    errorSavingLocations: "Fout bewerken locatie instellingen!",
    locationLevel1: "Locatie level 1",
    locationLevel2: "Locatie level 2",
    manageBusinessLocationSettings:
      " Beheer de instellingen van uw bedrijfslocatie.",
    newLocation: "Nieuwe locatie",
    lCaseLocations: "locatie",
    newLevel1: "Nieuwe level 1 locatie",
    locationName: "Locatie naam",
    locationAddress: "Locatie adres",
    newLevel2: "Nieuwe level 2 locatie",
    level2Locations: "Level 2 locatie",
    businessLocation: "Bedrijfslocatie",
  },
  cropsQr: {
    title: "Teeltpagina QR code",
    urlType: {
      label: "URL type",
      options: [
        {
          id: "noteForm",
          name: "Notitie formulier",
          docLabel: "Notitie - foto",
        },
        {
          id: "cropLog",
          name: "Logboek",
          docLabel: "Logboek",
        },
        {
          id: "cropProfile",
          name: "Gewasprofiel",
          docLabel: "Gewasprofiel",
        },
      ],
      noteForm: "Notitie formulier",
      cropLog: "Teelt logboek",
      cropProfile: "Teeltprofiel",
    },
    week: "Startdatum",
    objectcode: "Objectcode",
    ends: "Einddatum",
    start: "Startdatum",
    end: "Einddatum",
    wk: "wk",
    cropLabel: "Teelt label",
    openField: "Vollegrond",
    qrCode: "QR-code",
  },
  locationsQr: {
    title: "Locations QR code",
    cropsAtLocations: "Notitie bij teelten op locatie",
    notitie: "Notitie",
    foto: "foto",
  },
  exports: {
    title: "Exports",
    cropControl: {
      fileType: "Bestandstype",
      export: "Export",
      typeOptions: {
        cropProtection: "Gewasbescherming",
        fertilization: "Bemesting",
        cultivation: "gewas",
      },
      button: "Exporteren",
    },
  },
  exporters: {
    exportPdf: "Naar PDF",
    exportXlsx: "Naar Excel",
    exportCsv: "Naar CSV",
  },
  assesment: {
    title: "Beoordeling {name}",
  },
  weather: {
    title: "Historische Weer Data",
    tavgAvg: "Gem. Temperatuur",
    tminAvg: "Min. Temperatuur",
    tmaxAvg: "Max. Temperatuur",
    humAvg: "RV",
    irrSum: "Instraling",
    rainSum: "Neerslag",
    leafSum: "Bladnat",
  },
  signUp: {
    title: "Account activeren",
    errors: {
      emailInUse: "Email adres reeds in gebruik.",
      passwordWeak: "Wachtwoord moet minstens 6 tekens lang zijn",
      passwordMismatch: "Wachtwoorden moeten overeenkomen",
    },
  },
  signIn: {
    title: "Inloggen",
    generalErrors: {
      tooManyRequests: "Te veel inlog pogingen in korte tijd.",
      networkRequestFailed: "Geen internet connectie.",
      default: "Er is een onbekende fout opgetreden: {0}",
    },
    form: {
      emailAddress: {
        text: "Email adres",
        errors: {
          invalidEmailAddress: "Ongeldig email adres.",
          userEmailNotFound: "Geen account gevonden met dit email adres.",
          userDisabled: "User currently disabled.",
          required: "E-mail adres is vereist.",
        },
      },
      password: {
        text: "Wachtwoord",
        error: "Incorrect wachtwoord.",
        required: "Wachtwoord is vereist.",
      },
      button: "Inloggen",
      linkText: "Wachtwoord vergeten?",
      noAccountText:
        "Indien u nog geen account heeft, vraag dan hier een account aan",
    },
    signInToYourAccount: "Meld u aan bij uw account",
  },
  signedInRedirect: {
    text: "Inloggen...",
  },
  signOut: {
    title: "Succesvol uitgelogd",
    linkText: "Opnieuw inloggen?",
  },
  disclaimer: {
    titleOne: "Aansprakelijkheid",
    textOne:
      "Wij aanvaarden geen enkele verantwoordelijkheid voor de actualiteit, juistheid en volledigheid van de verstrekte bemestingsbeoordelingen en aanbevelingen. Aansprakelijkheidsclaims jegens ons, met betrekking tot materiële schade of gevolgschade veroorzaakt door het gebruik of niet-gebruik van de verstrekte bemestingsaanbevelingen of door het gebruik van onjuiste en onvolledige aanbevelingen, zijn in principe uitgesloten, tenzij duidelijk aantoonbaar sprake is van opzettelijke, grove nalatigheid in handelen. Deze uitsluiting van aansprakelijkheid is niet van toepassing op letselschade of aansprakelijkheid bij onrechtmatig handelen. We behouden ons nadrukkelijk het recht voor om Log & Solve of delen daarvan zonder voorafgaande kennisgeving te wijzigen, aan te vullen of te verwijderen of om het gebruik ervan tijdelijk of permanent te stoppen.",
    titleTwo: "Interpretatie teeltdata",
    textTwo:
      "De beoordeling van de analyses is gewasafhankelijk. Op basis van onderzoek en ervaring is er ooit een Bemestigsadviesbasis* opgesteld waarbij gewassen worden ingedeeld naar voedingsbehoefte, zoutgevoeligheid en pH. Deze bemestingsadviesbasis is een leidraad om de analyseresultaten te waarderen en om een optimaal bemestingsadvies te kunnen opstellen. Voor het opstellen van een bemestingsadvies en de interpretatie van de analyseresultaten zijn gewasstand (groei, kleur etc.) en de bemesting-strategie voorafgaand aan de bemonstering mede van belang. Het kan ook zijn dat u door de jaren heen op basis van uw omstandigheden een eigen waardering en strategie heeft opgebouwd. Bijgaande streefwaarden en grenzen zijn dus een leidraad. \n \n * Bij de beoordeling van de analyse worden de analyseresultaten omgerekend naar een standaard EC-waarde, welke per gewas is opgesteld. Hierdoor kunnen analyseresultaten naar verhouding met de EC worden omgerekend en door de tijd goed met elkaar worden vergeleken.",
  },
  overview: {
    title: "Telerslijst",
    searchGrower: "Zoek teler",
  },
  team: {
    intro:
      "Log & Solve is een digitale tool, ontwikkeld en beheerd door het Innovatieteam van Klasmann-Deilmann. Ontmoet het team achter Log & Solve",
    members: [
      {
        name: "Sjors Beijer",
        portrait: "sjors.jpg",
        position: "Innovatie Manager Klasmann-Deilmann",
        description:
          "Sjors werkt sinds juni 2016 bij Klasmann-Deilmann als Innovatie Manager, na eerder een studie aan Wageningen Universiteit te hebben gevolgd. Sjors was een van de initiatiefnemers van Log & Solve. “De trend om zuiniger om te gaan met grondstoffen, energie en chemie en duurzamer te telen, vraagt om aanpassingen in teeltstrategie en daarvoor is teeltmonitoring erg belangrijk. Ook helpt goede monitoring ondernemers om specifieke teeltkennis beter vast te leggen, dit te optimaliseren en te bewaren binnen de onderneming. Ik geloof dat digitale hulpmiddelen zoals Log & Solve een steeds belangrijkere toegevoegde waarde kunnen zijn voor potplant kwekers. Door slim gebruik van hulpmiddelen kunnen telers meer pro-actief anticiperen op problemen en uitval verminderen. Log & Solve is gemaakt voor – maar in het bijzonder samen met telers.”",
      },
      {
        name: "Co-Anne van Waaij",
        portrait: "co-anne.jpg",
        position: "Business Developer",
        description:
          "Co-Anne is een enthousiaste en innovatieve kwekersdochter. Sinds 2018 werkt Co-Anne bij Klasmann-Deilmann en is zij actief betrokken bij de ontwikkeling van Log & Solve als contactpersoon met kwekers. Samen met de gebruikers van Log & Solve kijkt zij hoe de tool het best op dagelijkse basis kan worden ingezet. “Ik werk graag samen met kwekers om de behoefte uit de praktijk te koppelen aan Log & Solve, zodat Log & Solve gaat bijdragen aan een beter teeltresultaat. In het ontwikkeltraject hebben we echt samengewerkt met kwekers, in plaats van iets op kantoor te ontwikkelen en dat zomaar in de markt te plaatsen. Ik geloof in deze manier van werken, zogenaamde co-creatie, om tot het beste resultaat te komen voor en met de kweker.”",
      },
      {
        name: "Gerard Wapenaar",
        portrait: "gerard.jpg",
        position: "Technisch Adviseur",
        description:
          "Gerard werkt al vele jaren in de tuinbouw. Bij Klasmann-Deilmann is Gerard Product Manager, een technisch expert op substraat gebied. Gerard ziet Log & Solve als een krachtig hulpmiddel voor kwekers waarin de dagelijkse teeltaansturing naar een hoger plan getild kan worden. “Tijdig anticiperen – op basis van data uit eerdere jaren of vergelijkbare situaties – maakt het mogelijk om teelt technische verbetering te realiseren. Ik beoordeel cijfers van potgrond-, bemesting- en watermonsters die in Log & Solve verzameld worden en kan sparring partner zijn voor allerhande bemestingsvraagstukken.”",
      },
      {
        name: "Hermann Konnemann",
        portrait: "hermann.jpg",
        position: "Technisch Adviseur",
        description:
          "Hermann werkt sinds 2007 bij Klasmann Deilmann als Technisch Adviseur, na eerst een aantal jaar als onderzoeker bij een proefstation te hebben gewerkt. Gebaseerd op zijn adviesdiensten voor kwekers in Europa en Oost-Azië, ziet hij voordelen van Log & Solve: “Het geven van advies wordt makkelijker en betrouwbaarder. Als adviseur kan ik nu Log & Solve gebruiken om analyse resultaten direct te vergelijken met de foto’s en additionele notities van onze klanten ten aanzien van gewasstand en teeltaansturing. Dit verhoogt de kwaliteit van onze adviesdiensten.”",
      },
      {
        name: "Balie Nasution",
        portrait: "balie.jpg",
        position: "Product Owner en Admin",
        description:
          "Balie is werkzaam als internationaal trainee sinds zomer 2019. Met een achtergrond in bodem- en plantenkunde op Wageningen Universiteit, gecombineerd met interesse en vaardigheden in IT ontwikkeling, past Balie goed bij het team van Log & Solve. “In de toekomst wordt het werkveld meer data-gedreven. Het gaat helpen om handelsketens transparanter te maken en teeltbeslissingen te nemen op basis van data in plaats van ‘groene vingers’. Digitale tools zoals Log & Solve maken het mogelijk om data uit zowel verleden als huidige situatie te integreren, zodat uiteindelijk kwekers beter in staat zijn teeltrendementen te verhogen.”",
      },
      {
        name: "Sören Houwing",
        portrait: "soren.jpg",
        position: "Coordinator International Markets",
        description:
          "In het najaar 2016 heeft Sören Houwing het team Technische Coördinatie Verkoop versterkt bij Klasmann-Deilmann in Duitsland. Door een praktijkstage op een kwekerij en een studie aan de tuinbouw hogeschool in Osnabrück heeft Sören veel affiniteit met de praktijk. Zijn interesse in processen waarin IT een belangrijke rol speelt hebben hem geïnspireerd voor Log & Solve. “Tijdens mijn stages en werkzaamheden als in de kas, heb ik veel data analoog moeten bijhouden en verwerken en was ik blij geweest met een digitale oplossing als Log & Solve. Daarom zie ik de toegevoegde waarde van Log & Solve en wil ik me inzetten om het bijhouden van teelt-informatie en -handelingen makkelijker, sneller, slimmer en efficiënter te maken voor onze klanten.”",
      },
    ],
  },
  cropsList: [
    {
      text: "Berichten",
    },
    {
      text: "Toevoegen",
    },
    {
      text: "Toevoegen aan",
    },
    {
      text: "Alle",
    },
  ],
  cropsListPage: {},
  tableComponent: {
    icon: "Icon",
    showColumns: "Kolomweergave",
    noColumns: "Selecteer kolommen om teelten weer te geven",
    reset: "Reset",
    label: "Label",
    alerts: "Alerts",
    progress: "Voortgang",
    date: "Pot datum",
    endDate: "Einddatum",
    weekNr: "Startweek",
    potDate: "Pot datum",
    potSize: "Potmaat",
    tray: "Tray",
    trayCellsAmount: "Aantal vakken per tray",
    traysAmount: "Aantal trays",
    plugType: "Plug type",
    growcoon: "Growcoon type",
    openField: "Open field",
    startingNumberOfPots: "Start aantal",
    currentNumberOfPots: "Huidige aantal",
    genus: "Geslacht",
    genusName: "Geslacht",
    speciesName: "Soort",
    cultivarName: "Cultivar",
    labDataId: "Lab id",
    barcode: "Barcode",
    batchNumber: "Partij nr.",
    youngPlantSuppliers: "Leveranciers van uitgangsmateriaal",
    bulbSize: "Bol maat",
    personPlanted: "Persoon die geplant heeft",
    isTrialCrop: "Toon alleen proeven",
    species: "Soort",
    cultivar: "Cultivar",
    variety: "Variëteit",
    location: "Locatie",
    businessLocation: "Bedrijfslocatie",
    locationLevel1: "Locatieniveau 1",
    locationLevel2: "Locatieniveau 2",
    sizeDescription: "Maat beschrijving",
    supplierDocumentNr: "Supplier document nr.",
    containerNr: "Container number",
    measure: "Meten",
    sort: "Soort",
    show_location: "Toon locatie",
    status: "Toon inactief",
    search_placeholder: "Zoek...",
    select: {
      all: "Alle",
      month: "Maand",
      week: "Week",
    },
    next: "Volgende",
    prev: "Vorige",
    noData: "Geen data",
    locationToggle: "Toon locatie",
    labDataIdToggle: "Toon lab id",
    alert: {
      "new-note": "NOTITIE",
      "new-photo": "FOTO",
      "lab-data": "LAB ANALYSE",
      "lab-data-comment": "NOTITIE BIJ ANALYSE",
      "lab-data-pending": "INKOMENDE LAB ANALYSES",
      "new-task-due": "TAAK",
      protocol: "KIJK UIT VOOR",
      "new-advice": "ADVIES",
      "new-sensor-trigger": "SENSOR TRIGGER",
      "new-note-mention": "NOTITIE VERMELDING",
      "new-document": "Nieuw document",
    },
    actions: {
      generateQrCodes: "Genereer QR codes",
      duplicateCrops: "Dupliceer",
    },
    advanced: {
      label: "Geavanceerd",
      title: "Geavanceerde zoek filters",
      add: "Voeg toe",
      update: "Bijwerken",
      execute: "Uitvoeren",
      clear: "Reset",
    },
    view: "Bekijk",
    cropLabel: "Teelt label",
    pottingWeek: "Oppot week",
    container: "Potmaat",
    startingDates: "Startdatum",
    updates: "Updates",
    manageColumns: " Kolommen beheren",
    showHideSortCols: " Kolommen tonen, verbergen of sorteren",
    selected: "geselecteerd",
    search: "Zoek",
    noteType: "Type notitie",
    showInactive: "Toon inactief",
    stickyColumn: "Sticky kolom",
    showAllRows: "Alle rijen weergeven",
    substrateInfo: "Substraat info",
    referenceGenus: "Referentiegeslacht",
    phase: "Fase",
    drippers: "Druppelaars",
    spacingDate: "Datum wijderzetten",
    expectedCultivationTime: "Verwachte teeltduur",
    description: "Beschrijving",
    objectives: "Doelen",
    plantsPerContainer: "Planten per container",
    numberOfContainers: "Aantal containers",
    substrateMixture: "Substraatmix",
    fertilization: "Bemesting",
  },
  notificationLis: {
    new: "Nieuw",
  },
  addCrop: {
    duplicateCrop: "Dupliceer teelt",
    selectCrop: "Selecteer teelt",
    phase: "Teeltfase",
    classification: "Classificatie",
    substrateMixture: "Substraat receptuur",
    referenceGenus: "Referentie soort",
    fertilization: "Bemesting",
    noteDate: "Datum",
    duplicateForm: "Dupliceren vanuit",
    cropType: "Teeltnaam",
    genericName: "Geslacht",
    choose: "Kies",
    specificEpithet: "Soort",
    cultivar: "Cultivar",
    chooseYourName: "Kies je eigen naam...",
    or: "of",
    details: "Details",
    potSize: "Potmaat",
    startingNumberOfPots: "Start aantal",
    currentNumberOfPots: "Huidige aantal",
    numberOfCellsPerTray: "Aantal vakken per tray",
    numberOfTrays: "Aantal trays",
    plugType: "Plug type",
    growcoonType: "Growcoon type",
    drippers: "Druppelaars",
    startingAmount: "Start aantal",
    currentAmount: "Huidige aantal",
    batchNumber: "Partijnummer",
    bulbSize: {
      title: "Bollen maat",
      placeholder: "Maat van de bol",
    },
    personPlanted: {
      title: "Medewerker tijdens planten",
      placeholder: "Medewerker tijdens planten",
    },
    number: "Nummer",
    cultivationTime: "Teeltduur",
    dateOfPotting: "Oppotdatum",
    endDate: "Einddatum",
    expectedCultivationTime: "Verwachte teeltduur (in weken)",
    numberOfWeeks: "Aantal weken",
    trialCrop: "Teeltproef",
    submit: "Versturen",
    delete: "Delete",
    confirmDelete: "Weet je zeker dat je deze teelt wilt verwijderen?",
    edit: "Wijzig teelt",
    search: "Zoek",
    next: "Volgende",
    back: "Terug",
    cropAddedSuccessfully: "Nieuwe teelt toegevoegd!",
    cropEditedSuccessfully: "Teelt succesvol bewerkt!",
    cropDeletedSuccessfully: "Teelt succesvol verwijderd!",
    weekInYear: "Weeknummer",
    description: "Beschrijving",
    sizeDescription: "Maatbeschrijving",
    supplierDocumentNr: "Document nr. leverancier",
    containerNr: "Potmaat",
    descPlaceholder: "Teeltbeschrijving..",
    sizeDescPlaceholder: "Maataanduiding..",
    labDataId: "Lab id",
    barcode: "Barcode",
    labIdNotUnique: "Dit lab id bestaat al",
    statusActive: "Actief",
    statusInactive: "Inactief",
    recipe: "Recept",
    productionDate: "Leverdatum",
    orderNo: "Order nr.",
    targetPh: "Streef pH",
    targetEc: "Streef EC",
    businessLocation: "Bedrijfslocatie",
    locationLevel1: "Locatieniveau 1",
    locationLevel2: "Locatieniveau 2",
    recipeDescription: "Substraatreceptuur",
    addSubstrateInfo: "Voeg substraat informatie toe",
    loadingSubstrateOrder: "Substraat order informatie laden...",
    noSubstrateOrder:
      "Er is geen substraat order informatie aanwezig, neem contact op",
    protocol: "Protocol",
    containerOptions: [
      {
        id: "pot",
        label: "Pot",
      },
      {
        id: "tray",
        label: "Tray",
      },
      {
        id: "open",
        label: "Open field",
      },
    ],
    plugTypeOptions: [
      {
        id: "loose",
        name: "Loose filled trays",
      },
      {
        id: "growcoon",
        name: "Growcoon",
      },
      {
        id: "paper",
        name: "Paper Pot",
      },
      {
        id: "glued",
        name: "Glued plugs",
      },
      {
        id: "rockwool",
        name: "Rockwool",
      },
      {
        id: "oasis",
        name: "Oasis",
      },
    ],
    trayName: "Tray naam",
    cropLabelName: "Label naam",
    cropLabel: "Teelt label",
    cropLabels: ["Teelt proef", "Hoofdteelt"],
    objectives: "Doelstellingen en uitdagingen voor dit gewas",
    spacingDate: "Aankomstdatum jong plantmateriaal",
    container: "Potmaat",
    labId: "Lab ID",
    openField: "Open veld",
    tray: "Tray",
    profile: "Teeltpagina",
    substrateUniqueKey: "Unieke substraat code",
    noSubstrateUniqueKey: "Geen unieke substraat code",
    nameDetails: "Teeltnaam details",
    selectOption: "Selecteer optie",
    typeDetails: "Teeltsoort details",
    selectOptionOrCreate: "Selecteer optie of maak nieuwe aan",
    growcoon: "Growcoon",
    locationBusiness: "Bedrijfslocatie",
    locationsLevel1: "Locatie level 1",
    locationsLevel2: "Locatie level 2",
    startNumber: "Start aantal",
    currentNumber: "Huidig aantal",
    youngPlantSuppliers: "Leveranciers van uitgangsmateriaal",
    labels: "Teelt labels",
    supplierDocumentNumber: "Document nummer leverancier",
    containerNumber: "Container nummer",
    goals: "Doelen",
    timeDetails: "Teeltduur",
    pot: "Pot",
    generative: "Generative",
    vegetative: "Vegetative",
    errors: {
      genusRequired: "Geslacht vereist",
      potSizeRequired: "Potmaat vereist",
      trayRequired: "Tray is vereist",
      cellsPerTrayMin: "Aantal cellen per tray kan niet minder zijn dan 1",
      traysMin: "Aantal trays kan niet minder zijn dan 1",
      plugTypeRequired: "Plug soort is vereist",
      weekNumberRequired: "Week nummer is vereist",
      pottingStartDateRequired: "Oppot datum is vereist",
      endDateRequired: "Einddatum is vereist",
    },
    cultivationTrial: "Teeltproef",
    mainCrop: "Hoofdteelt",
    add: "Nieuwe teelt",
    saveChanges: "Wijzigingen opslaan",
    saveNew: "Nieuwe teelt opslaan",
    deleteCrop: "Verwijder teelt",
    createCrop: "Nieuwe teelt",
    notOpenField: "Geen open veld",
    selectClassification: "Selecteer referentie",
    youngPlantSupplier: "Leverancier(s) van uitgangsmateriaal",
    labelName: "Labelnaam",
    numberOfContainers: "Aantal tafels",
    plantsPerContainer: "Aantal planten per tafel",
    noSurfaceArea: "Geef oppervlakte op voor locatie in instellingen",
    totals: "Totalen",
    amount: "Aantal",
    surfaceArea: "Oppervlakte",
    amountsAndSurfaceArea: "Hoeveelheden en oppervlakte",
    cropsPerSquareMeter: "Teelten / m²",
    squareMetersPerCrop: "m² / teelt",
    percentageOfLocation: "% van de locatie",
    totalSurfaceArea: "Totale oppervlakte op locatie",
    plantsAtLocation: "Planten op locatie",
  },
  inputComponent: {
    loading: "Gegevens ophalen...",
    search_placeholder: "Voer geslacht in ...",
  },
  noteChecklists: {
    title: "Checklists",
    createNew: "Nieuw",
    createEdit: "Checklist maken of wijzigen",
    name: "Naam checklist",
    selectBranch: "Selecteer",
    displayIn:
      "Toon de checklist onder de volgende categorie in het notitieformulier",
    customLabel: "Sla de notities op onder de volgende label",
    save: "Opslaan checklist",
    noChecklists: "Geen checklist aangemaakt",
    selectChecklist: "Selecteer checklist",
    add: "Voeg checklist toe",
    steps: "Stappen",
    details: "Checklist details",
    selectAction: " Selecteer een actie",
    action: "Actie",
    addAction: "Voeg actie toe",
  },
  tankMixes: {
    title: "Tankmixen aanmaken",
    createNew: "Nieuw",
    createEdit: "Tankmix maken of wijzigen",
    name: "Tankmix naam",
    type: "Tank mix type",
    save: "Tankmix opslaan",
    newMix: "Nieuwe mix",
    predefinedMix: "Voorgedefinieerde mix",
    emptyProduct: "Selecteer product",
    noTankMixes: "Nog geen tankmix aangemaakt",
    selectTankMix: "Selecteer een tankmix",
    selectPredefinedMix: "Kies voorgedefinieerde tankmix",
    editMix: "Bewerk tank mix",
    amount: "Hoeveelheid",
    product: "Product",
  },
  documents: {
    platform: "Documenten",
    company: "Bedrijfsdocumenten",
    crop: "Teeltdocumenten",
    categories: [
      {
        id: "third-party",
        label: "Derde partij",
      },
      {
        id: "log-and-solve",
        label: "Log & Solve",
      },
      {
        id: "documentation",
        label: "Documentatie",
      },
    ],
    showAll: "Toon alles",
    add: "Toevoegen",
    edit: "Wijzigen",
    document: "document",
    title: "Titel",
    category: "Categorie",
    type: "Type",
    shareWith: "Delen met",
    allCompanies: "Alle bedrijven",
    allIn: "Alle bedrijven in het",
    file: "Bestand",
    link: "Link",
    submit: "Versturen",
    noEntries: "Geen documenten toegevoegd",
    crops: "teelten",
    description: "Beschrijving",
    createYourFirstDocument: "Maak je eerste document",
    greatWay:
      "Documenten zijn een geweldige manier om je kennis te delen met je team",
    openDocuments: "Documenten openen",
  },
  advices: {
    title: "Advies",
    advice: "Advies",
    description: "Beschrijving",
    viewAttachment: "Bekijk bijlage",
    createYourFirstAdvice: "Voeg je eerste advies toe",
    greatWay:
      "Adviezen zijn een geweldige manier om uw kennis met uw team te delen",
    openAdvices: " Open adviezen",
    newAdvice: " Nieuw advies",
    createAdvice: "Advies toevoegen",
    editAdvice: " Advies bewerken",
    preview: " Advies voorbeeld",
  },
  generalNotes: {
    title: "Notities",
    note: "notitie",
    selectLocation: {
      label: "Selecteer locatie",
      placeholder: "Kies locaties",
    },
    description: "Beschrijving",
    viewAttachment: "Bekijk bijlage",
    includeInCrops: "Voeg notitie toe aan teelten van geselecteerde locatie",
  },
  climateData: {
    title: "Klimaatdata",
    paramTypes: [
      {
        id: "set",
        name: "Klimaat instellingen",
      },
      {
        id: "achieved",
        name: "Gerealiseerd",
      },
    ],
    parameters: {
      temperature: "Temperatuur",
      light: "Licht",
      airh: "Luchtvochtigheid",
      co2: "CO2",
      radiation: "Straling",
      "ventilation-a": "Ventillatie A",
      "ventilation-b": "Ventillatie B",
      shading: "Schermen",
      substrate_temperature: "Substraat temperatuur",
      temperatureMax: "Temperatuur max",
      temperatureMin: "Temperatuur min",
    },
    timeframes: {
      "24h": "per 24h",
      day: "dag",
      night: "nacht",
      agendaWeekNr: "per week nr.",
      "24h-avg": "24h-gem.",
      week: "week",
    },
    units: {
      c: "℃",
      f: "f",
      "j/cm2-som": "J/cm²-som",
      "mol/m2": "mol/m²",
      "%": "%",
      av: "AV",
      vd: "VD",
      ppm: "ppm",
      "wind m/s": "wind m/s",
      radiation: "instraling",
      airh: "luchtvochtigheid",
      "w/m2": "W/m²",
      "umol/m2/s": "µmol/m²/s",
      daylength: "daglengte",
      h: "h",
      "kg/ha": "kg/ha",
    },
    wizard: {
      title: "Nieuw",
      manage: "Invoer beheren",
      selectLocations: "Selecteer locaties",
      subtitle: "Beheer en analyseer klimaat data naast je teeltvoortgang",
    },
    settings: {
      title: "Instellingen",
      manage: "Instellingen beheren",
      settingsUpdated: "Instellingen zijn opgeslagen",
    },
    week: "Week",
    save: "Opslaan",
    getStarted: "Maak nieuwe klimaatdata instellingen om te beginnen",
    createSettings: "Maken",
    categories: {
      indoor: "Klimaatcomputer",
      outdoor: "Weer",
    },
    options: {
      parameters: "Parameters",
      timeframes: "Tijdschema's",
      units: "Eenheden",
    },
  },
  fertilizationBins: {
    amount: "Hoeveelheid",
    bin: "Bak",
    concentration: "Concentratie",
    dateEnd: "Eind datum",
    dateStart: "Start datum",
    locations: "Locaties",
    name: "Bak naam",
    product: "Product",
    searchForProduct: "Zoek naar meststof",
    settings: {
      createEdit: "Mestbak maken of wijzigen",
      createNew: "Nieuw",
      manage: "Instellingen beheren",
      noBins: "Geen mestbakken",
      selectLocations: "Selecteer locaties",
      settingsUpdated: "Instellingen zijn opgeslagen",
      title: "Instellingen",
    },
    title: "Bemesting",
    type: "Bak type",
    unit: "Eenheid",
    wizard: {
      manage: "Invoer beheren",
      pickBin: "Selecteer een bak",
      subtitle: "Creëer en beheer de bemesting van je teelt",
      title: "Registreer nieuw",
    },
  },
  pendingLabAnalysis: {
    title: "Inkomende analyses",
    submit: "Bevestigen",
    noEntries: "Geen inkomende analyses",
    completed: "{nr} De lab analyses zijn toegevoegd.",
    dateSamplingUnit: "Datum van analyse",
  },
  cropsImport: {
    title: "Importeer teelten",
    menu: {
      wizard: "Wizard",
      settings: "Instellingen",
    },
    inputFormats: {
      voorbeeld: "Wintree TSD",
      wouters: "Mprise agriware",
      voorraadpaartij: "GroenVision",
    },
    getStarted: "Maak nieuwe gewas import instellingen om te beginnen",
    createSettings: "Maken",
    selectFile: "Selecteer Excel import bestand",
    importFile: "Importeer bestand",
    reset: "Reset",
    progress: {
      created: "Aangemaakt",
      updated: "Bijgewerkt",
      skipped: "Overgeslagen",
      failed: "Mislukt",
    },
    execute: "Uitvoeren",
    completed: "Importeren van teelten is voltooid",
    validationError: "Please verify your input and try again",
    settings: {
      familyCodes: "Teeltnaam code",
      familyCode: "Familie code",
      locationCodes: "Locatie codes",
      locationCode: "Locatie code",
      potsizeCodes: "Potmaat code",
      trayCodes: "Tray codes",
      growcoonCodes: "Growcoon codes",
      plugTypeCodes: "Plug type codes",
      fieldsMaps: "Veld mappen",
      dedicatedLocCols: "Toegewijde locatiekolommen",
      save: "Opslaan",
      settingsUpdated: "Teelt-import instellingen bijgewerkt",
    },
    processFile: "Importeer bestand",
    saveCrops: "Uitvoeren",
    table: {
      imported: {
        heading: "Geïmporteerd uit bestand.",
        description:
          "Bekijk je teeltgegevens. Klik op een rij om de gewasinformatie te bewerken.",
      },
      failed: {
        heading: "Ongeldige teeltgegevens.",
        description:
          "Er klopt iets niet. Controleer de aankomende gegevens. Controleer eerst deze teelten voordat je verder kunt gaan met importeren. Bewerk de teelten of neem de teelten niet op.",
      },
    },
    include: "Opnemen",
  },
  tableHeaders: {
    barcode: "Barcode",
    genus: "Geslacht",
    species: "Soort",
    cultivar: "Cultivar",
    ownName: "Eigen naam",
    container: "Container",
    potSize: "Potmaat",
    tray: "Tray",
    plugType: "Plug type",
    growcoon: "Growcoon",
    cellsPerTray: "Aantal cellen per tray",
    trays: "Aantal trays",
    startingAmount: "Start aantal",
    currentAmount: "Huidige aantal",
    batchNo: "Partijnummer",
    youngPlantSupplier: "Leveranciers van uitgangsmateriaal",
    locationCode: "Locatie code",
    businessLocation: "Bedrijfslocatie",
    level1Location: "Level 1 locatie",
    level2Location: "Level 2 locatie",
    weekNumber: "Start weeknummer",
    pottingStart: "Startdatum",
    pottingEnd: "Einddatum",
    expectedCultivationTime: "Verwachte teeltduur (in weken)",
    fertilization: "Meststoffen",
    substrateMixture: "Substraat recept",
    description: "Beschrijving",
    sizeDescription: "Maatbeschrijving",
    supplierDocumentNr: "Supplier document nr.",
    containerNr: "Container number",
    protocol: "Protocol",
    label: "Label",
    labelName: "Label naam",
    phase: "fase",
    objectives: "Doelstellingen",
    spacingDate: "Aankomstdatum jong plantmateriaal",
    bulbSize: "Maat van de bol",
    person: "Persoon",
    drippers: "Druppelaars",
  },
  cropActions: {
    duplicateCrops: {
      title: "Dupliceer teelten",
      progress: {
        created: "Aangemaakt",
        updated: "Bijgewerkt",
        skipped: "Overgeslagen",
        failed: "Mislukt",
      },
      execute: "Uitvoeren",
      proceed: "Ga verder",
      completed: "{count} van de {total} teelten zijn succesvol gedupliceerd",
      validationError: "Controleer uw invoer en probeer het opnieuw",
      clickOnRow: "Klik op een rij om de teelten te bewerken.",
    },
  },
  cropForm: {
    selected: "{nr} teelten geselecteerd",
    selectLocation: "Selecteer locatie",
    selectYourLocation: "Selecteer locatie",
    cropsSelected: "{nr} teelten geselecteerd",
    noActiveCrops: "Momenteel zijn er geen teelten actief op deze locatie",
    selectCrops: "Selecteer teelten",
    crops: "teelten",
    locations: "Locaties",
    selectedLocations: "Geselecteerde locaties",
    search: "Zoek",
    selectAll: "Selecteer alles",
    showMore: "Toon meer",
    stepNo: "Stap {cs} van {ms}",
    labData: "Lab data",
    climateData: "Klimaatdata",
    fertilizationBins: "Mestbak samenstelling",
    advice: "Advice",
    "general-note": "General note",
    checklist: "Checklist",
    fertilizationEc: "EC tijdens watergift",
    watering: "Watergift",
    ecWateringDrainage: "Watergift + EC + Drainage",
    drainage: "Drainage",
    measurementEC: "EC meting",
    measurementpH: "pH meting",
    EC: "EC meting",
    "fertilization-Water": "Bewatering",
    amountSpreadable: "Hoevelheid",
    concentrationSpreadable: "Concentratie",
    "fertilzation-fertilzation": "Bladbemesting",
    amountAgent: "Aantal",
    choose: "Kiezen",
    cause: "Reden van toepassing",
    or: "of",
    andOr: "en/of",
    selectedCrop: "Geselecteerde teelt",
    change: "Verander teelt",
    completed: "voltooid",
    qrCode: "Scan QR-code",
    start: "Begin",
    save: "Opslaan",
    saveAddNew: "Opslaan + nieuw",
    ok: "Ok",
    cancel: "Cancel",
    pick: "Kies",
    measurement: "Meting",
    substrate: "Substraat",
    drain: "Drain",
    gift: "Gift",
    ditch: "Sloot",
    observation: "Observatie",
    waterAndFertilization: "Water en bemesting",
    logTask: "Registratie van",
    cultivationActivity: "Teelthandeling",
    units: "Units",
    addPhoto: {
      label: "Voeg foto toe",
      overzicht: "Overzicht",
      enkelePlant: "Enkele plant",
      detail: "Detail",
      wortels: "Wortels",
    },
    photoType: "Foto type",
    category: "Categorie",
    whatMeasurement: "Registratie van:",
    addPH: "Voeg pH-meting toe",
    addLength: "Lengte toevoegen in cm",
    addDiameter: "Diameter in cm toevoegen",
    addBuds: "Aantal knoppen/bloemen:",
    addWeight: "Voeg gewicht toe",
    addYield: "Voeg opbrengst toe",
    diameter: "Diameter",
    numberOfBuds: "Aantal knoppen/bloemen",
    weight: "Gewicht",
    yield: "Opbrengst",
    whatObservation: "Observatie:",
    defectPhenomenon: {
      label: "Gebreksverschijnsel",
      options: {
        bladvlekkenGeel: "Bladvlekken - geel",
        bladvlekkenBruin: "Bladvlekken - bruin",
        bladrandGeel: "Bladrand - geel",
        BladrandBruin: "Bladrand - bruin",
        krullendBlad: "Krullend blad",
        bladval: "Bladval",
        knopval: "Knopval",
      },
    },
    leafObservation: "Blad observatie",
    defectPhenomenonIn: "Gebrekverschijnsel in:",
    youngLeaves: "Jong blad",
    oldLeaves: "Oud blad",
    leafBurn: "Bladverbranding",
    flowering: "Bloei",
    fungus: {
      label: "Schimmel- en bacterieziekte",
      options: {
        altenaria: "Altenaria",
        bladvlekkenziekte: "Bladvlekkenziekte",
        bloemrot: "Bloemrot",
        botrytis: "Botrytis",
        didymella: "Didymella",
        fusarium: "Fusarium",
        japanseRoest: "Japanse roest",
        echteMeeldauw: "Echte Meeldauw",
        valseMeeldauw: "Valse meeldauw",
        phytophthora: "Phytophthora",
        pythium: "Pythium (wortelrot)",
        roetdauw: "Roetdauw",
        sclerotinia: "Sclerotinia",
        stengelbasisrot: "Stengelbasisrot",
        verticillium: "Verticillium",
        zwarteVlekkenziekte: "Zwarte vlekkenziekte",
        nofungus: "Geen schimmel- en bacterieziekte gescout",
      },
    },
    plague: {
      label: "Plaag",
      options: {
        aaltjes: "Aaltjes",
        bladluizen: "Bladluizen",
        cicaden: "Cicaden",
        kevers: "Kevers",
        mineervliegen: "Mineervliegen",
        muggenVliegen: "Muggen & vliegen",
        rupsen: "Rupsen, motten & vlinders",
        mijten: "(Spint)mijten",
        trips: "Trips",
        wantsen: "Wantsen",
        mole: "Mollen",
        otherInsects: "Insecten overige",
        mice: "Muizen",
        symphyla: "Wortelduizendpoot",
        weeds: "Onkruid",
        slugs: "Slakken",
        springtail: "Springstaarten",
        whiteFly: "Witte vlieg",
        wolluizen: "Wolluizen",
        dopluizen: "Dopluizen",
        schildluizen: "Schildluizen",
        noplagues: "Geen plagen gescout",
      },
    },
    color: "Kleur",
    failure: "Uitval",
    rating: {
      label: "Beoordelen",
      options: {
        germRate: "Kiem cijfer",
        leafColor: "Bladkleur",
        leafGloss: "Bladglans",
        shootFormation: "Scheutvorming",
        flowerSize: "Bloemgrootte",
        flowerFirmness: "Bloem stevigheid",
        floridity: "Bloeirijkheid",
        rooting: "Beworteling",
        uniformity: "Uniformiteit",
        moisture: "Vochtigheid",
        branching: "Vertakking",
        plantFirmness: "Plant stevigheid",
        plantStructure: "Plantopbouw",
        compactness: "Compactheid",
        plantQuality: "Plant kwaliteit",
        leafQuality: "Blad kwaliteit",
        stemDiameter: "Stam diameter",
        tipSize: "Kopmaat",
        plantVolume: "Plant volume",
        cutlings: "Stek",
        growth: "Groei",
      },
    },
    intensity: {
      label: "Intensiteit",
      levels: ["Geen", "Licht", "Beetje", "Medium", "Veel", "Zeer"],
    },
    LeafTips: "Bladpunten",
    selectDefect: "Selecteer",
    selectFungi: "Kies een observatie:",
    selectPlague: "Selecteer plaag:",
    addEC: "EC tijdens watergift",
    addECMeasurement: "Voeg EC-meting toe",
    addWaterMeasurement: "Hoeveelheid water gegeven",
    whichFertilizationCategory: "Wat voor soort bemesting?",
    cropControl: "Middelen registratie",
    whichCropControl: "Welke gewasbescherming?",
    misc: "Overig",
    miscellaneous: "Overig",
    numberOfMeters: "Oppervlakte in m2",
    concentration: "Concentratie ml/100 liter",
    photoUpload: "Foto uploaden",
    pruning: "Snoeien",
    budding: "Toppen",
    widening: "Wijderzetten",
    sorting: "Sorteren",
    moveCultivationBatch: "Verplaatsen van partij",
    changePotsize: "Ompotten",
    rotate: "Indraaien",
    cutting: "Dieven",
    cuttingLeaf: "Blad snijden",
    layFoil: "Folie leggen",
    removeFoil: "Folie verwijderen",
    harvest: "Oogsten",
    lower: "Zakken",
    settle: "Vastzetten (ringen)",
    removeLeafs: "Blad weghalen",
    bendIn: "Inbuigen",
    placeSticks: "Stokken plaatsen",
    siftingThrough: "Bestuiven",
    pollinate: "Pollinate",
    binding: "Binden",
    weeding: "Onkruid wieden",
    deflower: "Ontbloemen",
    removeTendrils: "Ranken uithalen",
    placeCatchCards: "Vangkaarten plaatsen",
    tendrils: "Afranken",
    raiseTheGauze: "Gaas omhoog halen ",
    cropMaintenance: "Gewasonderhoud ",
    removeBuds: "Knoppen verwijderen",
    potting: "Oppotten",
    deliverFinishedPlants: "Afleveren",
    pottingUnit: "plugs/cuttings",
    deliverFinishedPlantsUnit: "planten",
    floridityUnit: "average amount of flowers",
    sow: "Zaaien",
    makeCuttings: "Stekken maken",
    cuttingUnder: "Ondersnijden",
    cuttingRound: "Rondsteken",
    technicalService: "Technische dienst",
    length: "Lengtemeting",
    chemicalProtection: {
      label: "Vloeibare gewasbescherming",
      options: {
        batavia: "Batavia",
        boxer: "Boxer",
        butisanS: "Butisan S",
        bonzi: "Bonzi",
        configuresc: "Configure Sc",
        collis: "Collis",
        cecece: "CeCeCe",
        conserve: "Conserve",
        AZ500: "AZ 500",
        abamectineHf: "Abamectine HF",
        centurionplus: "Centurion Plus",
        carex10sc: "Carex 10 SC",
        decis: "Decis",
        dithane: "Dithane NeoTec",
        dimanin: "Dimanin",
        floramite: "Floramite",
        flint: "Flint",
        gnatrolSc: "Gnatrol SC",
        karateZeon: "Karate zeon",
        limocide: "Limocide",
        lunaPrivilige: "Luna Privilege",
        mirageElan: "Mirage Elan",
        miragePlus: "Mirage plus",
        movento: "Movento",
        nissorun: "Nissorun",
        ortiva: "Ortiva",
        ortivaTop: "Ortiva Top",
        paraat: "Paraat",
        previcurEnergy: "Previcur Energy",
        proplant: "Proplant",
        ranman: "Ranman",
        rovral: "Rovral",
        serenade: "Serenade",
        scelta: "Scelta",
        sivantoPrime: "Silvanto Prime",
        siltac: "Siltac",
        signum: "Signum",
        steward: "Spuitzwavel Microsulfo",
        sumicidinSuper: "Sumicidin Super",
        switch: "Switch",
        teldor: "Teldor",
        teppeki: "Teppeki",
        tracer: "Tracer",
        xentarie: "Xentarie",
        vertimec: "Vertimec",
        azatin: "Azatin",
        biosweet: "Biosweet",
        bitterzout: "Bitterzout",
        contans: "Contans",
        dipel: "Dipel",
        dolokal: "Dolokal",
        kumulusS: "Kumulus S",
        kumar: "Kumar",
        microsulfo: "Spuitzwavel Micro Sulfo",
        nectarMagnwsiumDuo: "Nectar magnesium duo",
        neemazal: "NeemAzal ",
        suitzwavel: "Zwavel",
        trianium: "Trianum",
        aCSzwavel800: "ACS- zwavel 800",
        admiral: "Admiral",
        alibiflora: "Alibi flora",
        altacor: "Altacor",
        applaud: "Applaud",
        asperello: "Asperello",
        attracker: "Attracker",
        bifasto: "Bifasto",
        biosoap: "Biosoap",
        botanigardWP: "Botanigard WP",
        captosan500sc: "Captosan 500 sc",
        closer: "Closer",
        dazideenhance: "Dazide enhance",
        ecomist: "Eco mist",
        ethrelA: "Ethrel A",
        fado: "Fado",
        falgro: "Falgro",
        flipper: "Flipper",
        frupica: "Frupica",
        fungaflash: "Fungaflash",
        gazelle: "Gazelle",
        geoxe: "Geoxe",
        hasten: "Hasten",
        hfOnkruidVrij: "HF Onkruid vrij",
        hiCure: "Hi Cure",
        hiwett: "Hi wett",
        inssimo: "Inssimo",
        karma: "Karma",
        mainspring: "Mainspring",
        MCPA500: "MCPA 500",
        nocturn: "Nocturn",
        nomolt: "Nomolt",
        oberon: "Oberon",
        oroganic: "Oroganic",
        pedimentrookontwikkelaar: "Pediment rookontwikkelaar",
        pirimor: "Pirimor",
        pironpushbox: "Piron pushbox",
        prolong: "Prolong",
        ranmanTop: "Ranman Top",
        rhizopon: "Rhizopon",
        rocket: "Rocket",
        rodilinHavermix: "Rodilin Havermix",
        rodilonsoftblock: "Rodilon soft block",
        ridomilGold: "Ridomil Gold",
        rizolex: "Rizolex",
        runner: "Runner",
        saponin: "Saponin",
        score250EC: "Score250EC",
        silwetgold: "Silwet gold",
        sporgon: "Sporgon",
        sluxx: "Sluxx",
        sonata: "Sonata",
        suikerlokstof: "Suikerlokstof",
        tzwavel: "T- zwavel",
        taegro: "Taegro",
        takumi: "Takumi",
        tomCatRTU: "Tom Cat RTU",
        topaz: "Topaz",
        topsinmutra: "Topsin M utra",
        turexPowder: "Turex Poeder",
        vacciplant: "Vacciplant",
        valiosoSt: "Violoso-ST",
        vivando: "Vivando",
        abir: "Abir",
        mycotal: "Mycotal",
        motto: "Motto",
        meltatox: "Meltatox",
        winner: "Winner",
        fusiladeMax: "Fusilade Max",
        wingP: "Wing P",
        agralGold: "Agral Gold",
        goltixWG: "Goltix WG",
        roundupDynamic: "Roundup Dynamic",
        roundupEvolution: "Roundup Evolution",
        concreteHF: "Concrete HF",
        corzalSE: "Corzal SE",
        calciumShuttle: "Calcium Shuttle",
        basfoliar36Extra: "Basfoliar 36 Extra",
        calciumChoride: "Calcium Choride",
        ridemilGold: "Ridemil Gold",
        pergado: "Pergado",
        abemectineHF: "Abemectine HF",
        captosan500Sc: "Captosan 500 sc",
        helioterpen: "Helioterpen",
        thripsLok: "ThripsLok",
        fulvic: "Fulvic",
      },
    },
    addChemicalProtection: "Selecteer product:",
    addOrganicProtection: "Selecteer product:",
    organicProtection: {
      label: "Strooibare gewasbescherming (bio)",
      options: {
        adaliaBipunctata: "Adalia bipunctata (lieveheersbeestje)",
        amblydromalusLimonicus: "Amblydromalus limonicus",
        amblyseiusSwirskii: "Amblyseius swirskii",
        aphelinusAbdomalis: "Aphelinus abdomalis",
        aphidiusColemani: "Aphidius colemani",
        aphidiusErvi: "Aphidius ervi",
        aphidoletesAphidimyza: "Aphidoletes aphidimyza",
        azatin: "Azatin",
        biosweet: "Biosweet",
        bitterzout: "Bitterzout",
        chrysoperlaCarnea: "Chrysoperla carnea",
        contans: "Contans",
        dacnusaSibirica: "Dacnusa sibirica",
        diglyphusIsaea: "Diglyphus isaea",
        dipel: "Dipel",
        dolokal: "Dolokal",
        encarsiaFormosa: "Encarsia formosa",
        eretmocerusEremicus: "Eretmocerus eremicus",
        feltiellaAcarisuga: "Feltiella acarisuga",
        hypoaspisMiles: "Hypoaspis miles",
        kumulusS: "Kumulus S",
        macrochelesRobustulus: "Macrocheles robustulus",
        macrolophusPygameus: "Macrolophus pygameus",
        microsulfo: "Microsulfo",
        nectarMagnwsiumDuo: "Nectar magnesium duo",
        neemazal: "NeemAzal",
        neoseiulusCalifornicus: "Neoseiulus californicus",
        neoseiulusCucumeris: "Neoseiulus cucumeris",
        oriusLaevigatus: "Orius laevigatus",
        phytoseiulusPersimilis: "Phytoseiulus persimilis",
        springbok: "Springbok",
        suitzwavel: "Spuitzwavel",
        steinernemaCarpocapsae: "Steinernema carpocapsae",
        steinernemaFeltiae: "Steinernema feltiae",
        transeiusMontdorensis: "Transeius montdorensis",
        trianium: "Trianum",
        trichogrammaArchaeae: "Trichogramma archaeae",
        anagyrusVladimiri: "Anagyrus vladimiri",
        aphidiusMatricariae: "Aphidius matricariae",
        pekelkreeftcystenArtemiaspp: "Pekelkreeftcysten artemia spp",
        athetaCoriaria: "Atheta coriaria",
        bombusTerrestris: "Bombus terrestris (Hommels)",
        cryptolaemusMontrouzieri: "Cryptolaemus montrouzieri",
        delphastusCatalinae: "Delphastus catalinae",
        enermix: "Enermix",
        ephestiaKuehniella: "Ephestia kuehniella",
        carpoglyphusLactis: "Carpoglyphus lactis",
        lokdoosMetGif: "Lokdoos met gif",
        lokdoosZonderGif: "Lokdoos zonder gif",
        hollandFytozide: "holland Fytozide",
        Teppeki: "Teppeki",
        AZ500: "AZ 500",
      },
    },
    PPP: "Gewasbeschermingsmiddelen",
    biocide: "Biociden",
    adjuvant: "Uitvloeier",
    addgrowthRegulation: "Selecteer product:",
    addMeasurement: "Oppervlakte in m2:",
    remmen: {
      label: "Groeiregulatie (remmen)",
      options: {
        alar: "Alar",
        bonzi: "Bonzi",
        calciumchloride: "Calciumchloride",
        chryzopon: "Chryzopon",
        controlGrowdesign: "Control Growdesign",
        cycocel: "Cycocel",
        dazide: "Dazide",
        elastoG5: "Elasto G5",
        koper: "Koper",
        magnesiumchloride: "Magnesiumchloride",
        pirouette: "Pirouette",
        regalis: "Regalis",
        rhizoponAa: "Rhizopon AA",
        uplccc750: "Upl ccc 750",
        questuran: "Questuran",
      },
    },
    addRemmen: "Selecteer product:",
    floweringTreatment: {
      label: "Bloeibehandeling",
      options: {
        auxine: "Auxine",
        bonzi: "Bonzi",
        carousel: "Carousel",
        configureSc: "Configure SC",
        ethylene: "Ethyleen",
        florgib: "Florgib",
        gibbereline: "Gibberelline",
        valiosoSt: "Valioso ST",
      },
    },
    addFloweringTreatment: "Selecteer product:",
    addNote: "Voeg notitie toe",
    filters: {
      title: "Filters",
      gotoLevel2: "Ga naar level 2",
    },
    edit: "Bewerk",
    reset: "Reset",
    uploadPhoto: "Foto",
    whatCrop: "Bij welke teelt hoort de notitie?",
    whatToDo: "Wat zou je willen doen?",
    whatTask: "Welke taak heb je uitgevoerd?",
    whichRegulator: "Welke groeiregulator heb je gebruikt?",
    whatObserve: "Aanvullende opmerking",
    howMiliter: "Hoeveel milliliter?",
    notAdded: "Succes! Wil je nog iets toevoegen?",
    yes: "Ja",
    no: "Nee",
    both: "Beide",
    amDone: "Nee, ik ben klaar!",
    yourNote: "Extra notitie...",
    yourOwn: "Type hier een andere product naam",
    skip: "Overslaan",
    skipQuestion: "Vraag overslaan",
    tankage: {
      label: "Tank grootte",
      concentration: "Oppervlakte in m2",
      tankage: "Tankinhoud in liters",
    },
    productAmount: {
      label: "Hoeveelheid product",
      concentrationTotal: "Concentratie product",
      concentrationTotalKg: "Concentratie product in kg / Ha",
      tankageTotal: "Totale inhoud tank (in L)",
      tankageTotalKg: "Totale hoeveelheid in kg",
      amountAgent: "Absolute producthoeveelheid",
      numberOf: "Aantal eenheden toegepast",
      pieces: "Stuks per eenheid",
      totalApplied: "Totaal aantal toegepast",
      totalAppliedFertilizer: "Totaal toegepaste bemesting (in Kg)",
      kgPer: "Kg per eenheid",
    },
    product: "Product",
    tankageMix: "Product {idx} van {total}",
    fertilization: {
      label: "Bemesting",
      spreadible: {
        label: "Strooibare meststoffen",
        title: "Selecteer product:",
        options: {
          dcmMix1: "DCM mix 1",
          dcmMix2: "DCM mix 2",
          dcmMix3: "DCM mix 3",
          dcmMix4: "DCM mix 4",
          dcmMix5: "DCM mix 5",
          dcmMix6: "DCM mix 6",
          vivikali: "Vivikali",
          dolokal: "Dolokal",
          lokdoosMetGif: "Lokdoos met gif",
          lokdoosZonderGif: "Lokdoos zonder gif",
          kalkalmmonsalpeter: "Kalkalmmonsalpeter",
          magnesamonsalpeter: "Magnesamonsalpeter",
          patentkali: "Patentkali",
          protixflytilizer: "Protix Flytilizer",
          innogreenecogroei: "Innogreen ECO groei",
          tripelsuperfosfaat: "Tripelsuperfosfaat",
        },
      },
      foliar: {
        label: "Bladmeststoffen",
        title: "Selecteer product:",
        options: {
          cuprum: "Cuprum",
          calciumchloride: "Calciumchloride",
          salifort: "Salifort",
          soluplantSF31661414CaO: "Soluplant SF3 16-6-14+14CaO",
          olfAg: "OLF Ag",
          espotop: "Bitterzout Epso Top",
          bitterzout: "Bitterzout",
          fertigroMSN: "Fertigro MSN",
          fertigroCN: "Fertigro CN",
          zwavelzuur37: "Zwavelzuur 37%",
          superFK: "Super FK",
          powerP: "Power P",
          universolgroen: "Universol Groen 23-6-10",
          kalksalpeter: "Kalksalpeter",
          kalksalpeterCalcinite: "Kalksalpeter Calcinite",
          kalksalpeterTropicote: "Kalksalpeter Tropicote",
          calsal: "Calsal Substrafeed Yara",
          yaraNitromag: "YaraBela Nitromag 27+4 MgO",
          spuitzwavel: "spuitzwavel",
          yaraSulfan: "YaraBela Sulfan 24+18SO3",
          ureumprills: "Ureum Prills",
          HuwaSan: "Huwa-San TR-50",
          vitalance: "Vitalance",
          siliforce: "Siliforce",
        },
      },
    },
    causesCropcontrol: {
      options: {
        aaltjes: "Aaltjes",
        altenaria: "Altenaria",
        bacterienov: "Bacterie",
        bladvlekkenziekte: "Bladvlekkenziekte",
        beworteling: "Beworteling",
        bloemrot: "Bloemrot",
        botrytis: "Botrytis",
        bladluizen: "Bladluizen",
        bloeibehandeling: "Bloeibehandeling",
        cicaden: "Cicaden",
        echteMeeldauw: "Echte Meeldauw",
        erwinia: "Erwinia",
        valseMeeldauw: "Valse meeldauw",
        kevers: "Kevers",
        mineervliegen: "Mineervliegen",
        muggenVliegen: "Muggen & vliegen",
        rupsen: "Rupsen, motten & vlinders",
        mijten: "(Spint)mijten",
        trips: "Trips",
        wantsen: "Wantsen",
        wolluizen: "Wolluizen",
        didymella: "Didymella",
        fusarium: "Fusarium",
        japanseRoest: "Japanse roest",
        phytophthora: "Phytophthora",
        pythium: "Pythium",
        roetdauw: "Roetdauw",
        sclerotinia: "Sclerotinia",
        stengelbasisrot: "Stengelbasisrot",
        verticillium: "Verticillium",
        zwarteVlekkenziekte: "Zwarte vlekkenziekte",
        otherBacteria: "Overige bacteriën",
        rooting: "Beworteling",
        leafSpot: "Bladvlekkenziekte",
        floweringTreatment: "Bloeibehandeling",
        centerRot: "Hartrot",
        adhesive: "Hechtmiddel",
        otherInsects: "Insecten overige",
        beetles: "Kevers",
        tuberRot: "Knolrot",
        attractant: "Lokstof",
        aphids: "Luizen",
        mole: "Mollen",
        mice: "Muizen",
        shoreFly: "Oevervlieg",
        weeds: "Onkruid",
        disinfection: "Ontsmetting",
        misk: "Overige",
        plantResistance: "Plantweerbaarheid",
        rats: "Ratten",
        remmen: "Groeiregulatie (remmen)",
        rhizoctonia: "Rhizoctonia",
        caterpillars: "Rupsen",
        otherFungus: "Schimmel overige",
        slugs: "Slakken",
        spiders: "Spint",
        springtail: "Springstaarten",
        sugarRot: "Suikerrot",
        whiteFly: "Witte vlieg",
        rootRot: "Wortelrot",
        symphyla: "Wortelduizendpoot",
        dopluizen: "Dopluizen",
        schildluizen: "Schildluizen",
        branchMortality: "Taksterfte",
        tensides: "Uitvloeier",
      },
    },
    back: "Terug",
    next: "Volgende",
    done: "Klaar",
    pleaseSelectCrops: "Selecteer teelten voordat u verder gaat.",
    backgroundSaveInProgress:
      "Sommige gegevens worden op de achtergrond opgeslagen...",
    pleaseWaitbackgroundSave:
      "Gelieve te wachten tot het opslaan van de gegevens is voltooid",
    document: "Document",
    "fertilization-fertilization": "Bladbemesting",
    brix: " Brix-waarde",
    addBrix: " Brix-waarde toeoegen",
    numberOfRunners: "Aantal lopers",
    addNumberOfRunners: "Aantal lopers toevoegen",
    numberOfCrowns: " Aantal kronen",
    addNumberOfCrowns: " Aantal kronen toevoegen",
    numberOfFlowers: "Aantal bloemen",
    addNumberOfFlowers: "Aantal bloemen toevoegen",
    leafLength: "Blad lengte",
    nr: "nr",
    chooseActivity: "Kies activiteit",
    chooseChecklist: "Kies checklist",
    nextChecklistNote: "Volgende checklistnotitie",
    backChecklistNote: " Terug naar de vorige checklistnotitie",
    note: "Notitie",
    for: "voor",
    createANote: " Maak een notitie voor uw teelt(en)",
    customLabel: " Aangepast label",
    measurementValue: " Meetwaarde",
    addProduct: "Product toevoegen",
    photoLabel: "Fotolabel",
    pinching: "Toppen",
    spacing: "Wijderzetten",
    move: "Partij verplaatsen",
    liquidCropControl: "Vloeibare gewasbescherming",
    spreadingCropControl: "Strooibare gewasbescherming",
    growthRegulation: "Groeiregulatie (remmen)",
    controlRound: "Controle ronde",
    spreadableFertilizers: "Strooibare bemesting",
    foliarFertilizers: "Bladmeststoffen",
    scouting: "Scouten",
    selectACrop: "Selecteer een teelt",
    fertilizationBin: "Bemestingstanks",
    plantGrowthHead: "Plantengroei: kop (dikte)",
    plantGrowthInternodes: "Plantengroei: internodiën",
    trussFormation: "Vorming van trossen",
    numberOfFruitsPerTruss: "Aantal vruchten per tros",
    numberOfFruitsPerPlant: "Aantal vruchten per plant",
    averageFruitWeight: "Gemiddeld vruchtgewicht",
    fruitDiameter: "Diameter fruit",
    numberOfOpenFlowers: "Bloei: aantal open bloemen",
    repeat: "Herhaal",
    defectWhere: "Defect in",
    ecWatering: "Bewatering + EC",
    selectedCrops: "Geselecteerde teelten",
    addWateringAmount: "Hoeveelheid/duur van water geven",
  },
  resetPassword: {
    title: "Wachtwoord reset",
    message:
      "Voer het e-mailadres van uw gebruikersaccount in en we sturen u een link voor het opnieuw instellen van uw wachtwoord.",
    placeholder: "Vul je e-mailadres in",
    send: "E-mail voor opnieuw instellen van wachtwoord verzenden",
    successMessage: "Er is een link verzonden!",
    emailNotUsed: "Deze e-mail wordt niet gebruikt!",
    updateSuccessMessage: "Wachtwoord bijgewerkt!",
  },
  footer: {
    disclaimer: "Disclaimer",
    contact: "Contact",
    faq: "FAQ",
  },
  timeControl: {
    selectRange: "Datumbereik selecteren",
    last: "Afgelopen",
    deltas: {
      hour: "uur",
      day: "dag",
      week: "week",
      month: "maand",
      year: "Jaar",
    },
  },
  notesFeed: {
    log: "Log",
    dailySummary: "Dagelijkse samenvatting",
    fertilizationEc: "EC tijdens watergift",
    EC: "EC",
    "fertilization-Water": "Bewatering",
    amountSpreadable: "Hoeveelheid",
    concentrationSpreadable: "Concentratie",
    "fertilization-fertilization": "Strooibare bemesting",
    spreadible: "Strooibare bemesting",
    foliar: "Bladbemesting",
    numberOf: "Aantal eenheden",
    numberOfUnits: "Aantal eenheden",
    kgPer: "Kg per eenheid",
    kgPerUnit: "Kg per eenheid",
    totalIrrigation: "Totale irrigatie",
    floridity: "Bloeirijkheid",
    units: {
      pieces: "stuks",
      "pieces / m2": "stuks / m2",
      "ml / 100 liters": "ml / 100 liters",
      "gram / 100 liters": "gram / 100 liters",
      kg: "kg",
      gr: "gram",
      units: "units",
    },
    totalApplied: "Totaal toegepast:",
    amountAgent: "Hoeveelheid middel:",
    labData: "Lab data",
    color: "Kleur",
    floweringTreatment: "Bloeibehandeling",
    ec: "EC",
    ph: "pH",
    water: "Water",
    remmen: "Groeiregulatie (remmen)",
    length: "Lengte",
    diameter: "Diameter",
    numberOfBuds: "Aantal knoppen/bloemen",
    weight: "Gewicht",
    yield: "Opbrengst",
    surface: "Oppervlakte",
    size: "Tankinhoud",
    tankage: "Tankinhoud",
    concentration: "Concentratie",
    amount: "Tank inhoud",
    chemicalProtection: "Vloeibare gewasbescherming",
    organicProtection: "Strooibare gewasbescherming (Bio)",
    compare: "Vergelijken",
    edit: "Bewerk",
    download: "Download",
    m2: "Oppervlakte",
    ml: "Hoeveelheid middel (ml)",
    cause: "Reden",
    cancelEdit: "Annuleer bewerking",
    toggleView: "Grafiek weergave",
    successfullyDeleted: "Notities zijn succesvol verwijderd",
    remove: "Verwijderen",
    note: "Opmerking",
    notes: "Notities",
    leafObservation: "Blad observatie",
    defectPhenomenon: "Bladrand - geel",
    leafBurn: "Bladverbranding",
    flowering: "Bloei",
    intensity: "Intensiteit",
    counting: "Getelde",
    fungus: "Schimmel",
    plague: "Plaag",
    failure: "Uitval",
    failureRate: "Uitvalpercentage",
    rating: "beoordelen",
    ratingValue: "Rating",
    LeafTips: "Bladpunten",
    pieces: "Stuks per eenheid",
    miscellaneous: "Overig",
    misc: "Overige",
    addPhoto: "Foto's",
    formBranches: [
      {
        id: "all",
        label: "Toon alles",
      },
      {
        id: "favorites",
        label: "Belangrijk",
      },
      {
        id: "cultivationActivity",
        label: "Teelthandeling",
      },
      {
        id: "measurement",
        label: "Meting",
      },
      {
        id: "observation",
        label: "Observatie",
      },
      {
        id: "waterAndFertilization",
        label: "Water en bemesting",
      },
      {
        id: "cropControl",
        label: "Middelen registratie",
      },
      {
        id: "labData",
        label: "Lab data",
      },
      {
        id: "addPhoto",
        label: "Foto's",
      },
      {
        id: "misc",
        label: "Overig",
      },
      {
        id: "hasPhoto",
        label: "Picture mode",
      },
    ],
    addFavorite: "Markeer als belangrijk",
    removeFavorite: "Belangrijk",
    labResults: "Lab resultaten",
    started: "Teelt gestart op",
    substrate: "Substraat",
    drain: "Drain",
    gift: "Gift",
    ditch: "Sloot",
    changed: "Aangepast",
    from: "van",
    to: "naar",
    added: "Toegevoegd",
    removed: "Verwijderd",
    containerTypes: {
      container: "container",
      tray: "tray",
      potSize: "potmaat",
      plugType: "plug type",
      growcoon: "growcoon",
    },
    potSizeChange: "De potmaat is gewijzigd van",
    potSizeChangeTo: "naar",
    locationChange: "De locatie is gewijzigd van",
    locationChangeTo: "naar",
    print: {
      companyName: "Bedrijfsnaam",
      label: "Label",
      genus: "Geslacht",
      species: "Soort",
      cultivar: "Cultivar",
      startWeek: "Start week",
      potSize: "Potmaat",
      labId: "L&S ID",
      startingAmount: "Start aantal",
      currentAmount: "Huidige aantal",
      businessLocation: "Bedrijfslocatie",
      level1Locations: "Level 1 locaties",
      level2Locations: "Level 2 locaties",
      cropOverview: "Teelt overzicht",
      referenceNumber: "Referentie nummer",
      photoReference: "Foto referentie",
      photosOverview: "Foto overzicht",
      log: "Log",
      summary: "Samenvatting",
      at: "op",
      by: "Door",
      batchNumber: "Batch nummer",
      youngPlantSuppliers: "Leveranciers van uitgangsmateriaal",
      sizeDescription: "Maat beschrijving",
      substrateMixture: "Substraat mengsel",
      supplierDocumentNr: "Supplier document nr.",
      containerNr: "Container number",
      fertilization: "Bemesting",
      barcode: "Barcode",
      weeknumber: "Week nummer",
      potDate: "Pot datum",
      endDate: "Einddatum",
      spacingDate: "Aankomstdatum jong plantmateriaal",
      description: "Beschrijving",
      objectives: "Doelstellingen en uitdagingen voor dit gewas",
      container: "Container",
      openField: "Open veld",
      tray: "Tray",
      plugType: "Plug type",
      amount: "Aantal",
    },
    startWeek: "Start week",
    week: "Week",
    weather: "Weer",
    confirmDelete: "Weet je zeker dat je deze notitie wilt verwijderen?",
    confirmDeleteMultiple:
      "Weet je zeker dat je deze notitie bij alle teelten wilt verwijderen?",
    confirmEditMultiple:
      "Weet je zeker dat je deze notitie bij alle teelten wilt bewerken?",
    confirmEdit: "Weet je zeker dat je deze notitie wilt bewerken?",
    delete: "Verwijder",
    cancel: "Cancel",
    update: "Update",
    show: "Geef weer",
    moreNotesInBatch: "Meerdere notities in batch",
    collapse: "Invouwen",
    PPP: " Gewasbeschermingsmiddel",
    biocide: "Biociden",
    adjuvant: "uitvloeier",
    exportPdf: "Naar PDF",
    exportXlsx: "Naar Excel",
    uploadPhoto: "Foto uploaden",
    extraNote: "Extra notitie",
    totalTankage: "Totale tankinhoud",
    favourite: "Favoriete",
    cultivationStart: "Teelt is gestart",
    cultivationEnd: "Teelt is beëindigd",
    selectAll: "Alles selecteren",
    deselectAll: "Alles deselecteren",
    clearSelection: "Selectie wissen",
  },
  cropCard: {
    startDate: "START WEEK",
    expectedDate: "VERWACHTE EINDDATUM",
    endDate: "EINDDATUM",
    location: "LOCATIE",
    potSize: "POTMAAT",
    tray: "TRAY",
    growcoon: "GROWCOON",
    plugType: "PLUG TYPE",
    openField: "OPEN FIELD",
    labId: "LAB ID",
    changePhoto: "WIJZIG FOTO",
    editBatch: "Wijzig info",
    week: "Week",
    cultivationWeek: "Cultivation week",
    calendarWeek: "Calendar week",
    sizeDescription: "MAATBESCHRIJVING",
    started: "Gestart",
    expected: "Verwacht",
    finished: "Geëindigd",
    showDetails: "Toon details",
    editCropProfile: "Bewerk teeltpagina",
    logHistory: "Log geschiedenis",
    labAnalyses: "Lab analyses",
    protocol: "Protocol",
    advice: "Advies",
    cropProtection: "Gewasbescherming",
    documents: "Documenten",
  },
  updateEmail: {
    intro: "De dashboards van jouw klanten zijn bijgewerkt",
    dear: "Beste",
    new: "Nieuwe",
    addedTo: "toegevoegd aan",
    subject: "Jouw dagelijkse samenvatting",
    notes: "notities",
    photos: "foto's",
    labSamples: "Lab analyses",
    labSampleComments: "opmerkingen bij lab analyses",
    greetings: "Vriendelijke groet",
    unsub: "Uitschrijven dagelijkse updates",
  },
  compareLogs: {
    compareLogs: "Vergelijk logboeken",
    selectCrop: {
      "1": "Selecteer eerste teelt:",
      "2": " Selecteer tweede teelt:",
      "3": " Selecteer derde teelt:",
    },
    add: "Voeg toe",
    useLandscape: " Gebruik landschap modus om te bekijken ",
    noData: "Geen data",
    selectCrops: "Selecteer teelten..",
    reminderModal: {
      title: "Voeg een nieuwe herinnering toe",
      datePickerPlaceholder: "Selecteer datum",
      textareadPlaceholder: "Beschrijving herinnering...",
      button: "Opslaan",
      reminderAddedSuccessfully: "Nieuwe herinnering toegevoegd!",
      reminderAddedNotSuccessfully: "Geen nieuwe herinnering toegevoegd!",
    },
  },
  task: {
    title: {
      create: "Maak een taak aan",
      edit: "Bewerk",
      list: "Lijst met taken",
      mytasks: "Taken",
      mentionedUsers: "Benoem gebruiker(s)",
      crops: "Teelten",
      assignedTo: "Toegewezen aan",
      notAssigned: " Niet toegewezen",
      taskDetails: "Taak details",
      taskType: "Taaksoort",
      deadline: "Deadline",
      createdBy: " Gemaakt door",
      protocol: "Protocol",
      done: "Klaar",
      startDate: "Startdatum",
      cultivationName: "Teeltnaam",
      efficientlyManage:
        " Beheer taken en protocoltoewijzingen efficiënt met uw team.",
      newTask: "Nieuwe taak",
      selected: "Geselecteerd",
      perform: "Uitvoeren",
      for: "Type",
      progress: "Voortgang",
      status: "Status",
      noProtocol: "geen protocol",
      todo: "To-do",
      today: "Vandaag",
      myTasks: "Mijn taken",
      allTasks: "Alle taken",
      description: "Beschrijving",
      details: "Details",
      rescheduleTasks: "Taken opnieuw plannen",
    },
    type: {
      label: "Type taak",
      name: {
        pruning: "Snoeien",
        pinching: "Toppen",
        spacing: "Wijder zetten",
        move: "Verplaatsen van partij",
        sorting: "Sorteren",
        changePotsize: "Ompotten",
        rotate: "Indraaien",
        cuttingLeaf: "Blad snijden",
        layFoil: "Folie leggen",
        removeFoil: "Folie verwijderen",
        harvest: "Oogsten",
        lower: "Zakken",
        settle: "Vastzetten",
        removeLeafs: "Blad weghalen",
        bendIn: "Inbuigen",
        placeSticks: "Stokken plaatsen",
        siftingThrough: "Pluizen",
        pollinate: "Bestuiven",
        binding: "Binden",
        weeding: "Wieden",
        deflower: "Bloemen verwijderen",
        removeTendrils: "Ranken verwijderen",
        placeCatchCards: "Vangkaarten plaatsen",
        tendrils: "Ranken",
        raiseTheGauze: "Gaas verhogen",
        cropMaintenance: "Gewas onderhoud",
        removeBuds: "Knoppen verwijderen",
        potting: "Oppotten",
        deliverFinishedPlants: "Afleveren",
        sow: "Zaaien",
        makeCuttings: "Stekken maken",
        technicalService: "Technishe service",
        PPP: "Gewasbeschermingsmiddelen",
        biocide: "Biociden",
        adjuvant: "Uitvloeier",
        liquidCropControl: "Vloeibare gewasbescherming",
        spreadingCropControl: "Strooibare gewasbescherming (bio)",
        growthRegulation: "Groeiregulatie (remmen)",
        floweringTreatment: "Bloeibehandeling",
        controlRound: "Controle ronde",
        spreadableFertilizers: "Strooibare meststoffen",
        foliarFertilizers: "Bladbemesting",
        scouting: "Scouting",
        misc: "Overig",
        checklist: "Checklist",
        widening: "Wijderzetten",
        addPhoto: "Voeg foto toe",
        budding: "Toppen",
      },
      defaultDescription: {
        pruning: "Partij snoeien",
        pinching: "Partij toppen",
        spacing: "Partij wijder zetten",
        move: "Partij verplaatsen",
        sorting: "Partij sorteren",
        changePotsize: "Partij om-potten naar nieuwe potmaat",
        rotate: "Indraaien",
        cuttingLeaf: "Blad snijden",
        layFoil: "Folie leggen",
        removeFoil: "Folie verwijderen",
        harvest: "Oogst de productie",
        lower: "Zakken",
        settle: "Vastzetten",
        removeLeafs: "Blad weghalen",
        bendIn: "Inbuigen",
        placeSticks: "Stokken plaatsen",
        siftingThrough: "Pluizen",
        pollinate: "Bestuiven",
        binding: "Binden",
        weeding: "Wieden",
        deflower: "Bloemen verwijderen",
        removeTendrils: "Ranken verwijderen",
        placeCatchCards: "Vangkaarten plaatsen",
        tendrils: "Ranken",
        raiseTheGauze: "Gaas verhogen",
        cropMaintenance: "Gewas onderhoud",
        removeBuds: "Knoppen verwijderen",
        potting: "Oppotten",
        deliverFinishedPlants: "Afleveren",
        sow: "Zaaien",
        makeCuttings: "Stekken maken",
        technicalService: "Technische service",
        PPP: "Gewasbeschermingsmiddelen",
        biocide: "Biociden",
        adjuvant: "Uitvloeier",
        liquidCropControl: "Gewasbescherming toepassen",
        spreadingCropControl: "Gewasbescherming toepassen",
        growthRegulation: "Groei behandeling toepassen",
        floweringTreatment: "Bloeibehandeling toepassen",
        controlRound:
          "Scouting en controle ronde uitvoeren: metingen, foto, observaties",
        spreadableFertilizers: "Strooibare meststoffen uitstrooien",
        foliarFertilizers: "Bladbemesting toepassen",
        scouting:
          "Scouten op ziekte, plagen, bacteriën en gebreksverschijnselen",
        misc: "Overige taken",
        checklist: "Checklist",
      },
    },
    checklist: {
      label: "Checklist",
    },
    selectCrop: "Selecteer een teelt",
    openField: "Open Veld",
    selectLocation: {
      label: "Selecteer alle teelten van locatie",
      placeholder: "Kies locaties",
    },
    description: {
      label: "Omschrijving",
      placeholder: "Omschrijving",
    },
    user: {
      label: "Verantwoordelijke",
      noUser: "No user",
    },
    date: "Datum",
    completed: "Afgerond",
    repeats: {
      label: "Aantal keren herhalen",
      help: "Het aantal keren dat deze taak wordt herhaald",
    },
    repeatWeekOffset: {
      label: "Elke zoveel weken herhalen:",
      help: "Het aantal weken tussen herhalingen. Kies 1 voor wekelijks herhalende taken, 2 voor tweewekelijks enz.",
    },
    deadline: "Deadline",
    startDate: "Startdatum",
    isRecurringTask: "Terugkerende taak",
    button: {
      create: "Maak aan",
      update: "Update",
      delete: "Verwijder",
      addTask: "Voeg taak toe",
      allTasks: "Alle taken",
    },
    filter: {
      dueToday: "Voor vandaag",
      thisWeek: "Deze week",
      showAll: "Toon alles",
    },
    more: "Meer",
    delete: "Verwijder taak",
    edit: "Bewerk taak",
    confirmDelete: "Weet je zeker dat je deze taak wilt verwijderen?",
    column: {
      name: "Teeltnaam",
      businessLocation: "bedrijfslocatie",
      level1Location: "Level 1 locatie",
      level2Location: "Level 2 locatie",
      batchNumber: "Batch nummer",
      potDate: "Startdatum",
      potSize: "Potmaat",
      startingAmount: "Start aantal",
      currentAmount: "Huidige aantal",
      surface: "Oppervlakte",
    },
    addNoteToSelected: "Voeg notitie toe aan geselecteerde",
    markSelectedAsDone: "Markeer geselecteerde als gedaan",
    performBy: "Uitgevoerd door",
    crops: "Teelten",
    crop: "Teelt",
    assignedTo: "Toegewezen aan:",
    createdBy: "Gemaakt door:",
    you: "Jou",
    task: "Taak",
    protocol: "Protocol",
    createdByType: {
      user: "User",
      protocol: "Protocol",
      all: "All",
    },
    taskDetails: "Taak details",
    details: "Details",
    totalOf: "Totaal geselecteerde teelten",
    finished: "Afgerond",
    showCompletedOnly: "Toon alleen afgeronde taken",
    buttons: {
      showInactiveCrops: " Toon inactieve teelten",
      edit: " Taak bewerken",
      markDone: "Markeer als klaar",
      addNoteToSelected: " Voeg een notitie toe aan de geselecteerde",
      markSelectedAsDone: "Markeer geselecteerd als klaar",
      deselect: "Deselecteer",
      delete: "Verwijder taak",
      addNoteToRemaining: "Voeg een notitie toe aan resterende teelten",
      removeSelected: "Geselecteerde verwijderen",
      rescheduleTasks: "Taken opnieuw plannen",
    },
    withOverdue: "Met verlopen taken",
    progressInHectares: "Vooruitgang in hectaren",
  },
  alerts: {
    markAllAsRead: "Markeer als gelezen",
    noAlerts: "Helemaal bijgewerkt!",
    markAllAsSeen: "Markeer alles als gezien",
    mentionedYou: " heeft je genoemd",
    addedANewNote: "heeft een nieuwe notitie toegevoegd",
    addedANewAdvice: "heeft een nieuw advies toegevoegd",
    commentedOnLabData: "heeft commentaar gegeven op lab data",
    newPendingLabData: "Nieuwe inkomende lab data",
    newLabData: "Nieuwe lab data",
    newNotifications: "Nieuwe notificatie",
    olderNotifications: "Oudere notificatie",
  },
  pdfmake: {
    title: "Genereer PDF",
    regeneratePdf: "Vernieuwen",
    downloadPdf: "Download",
  },
  vuelidate: {
    unknownLabel: "Dit veld",
    errors: {
      required: "{label} is een verplicht veld.",
      minLength: "{label} moet minimaal {min} of meer karakters bevatten.",
      maxLength: "{label} kan maximaal {max} karakters bevatten.",
      minValue: "{label} moet groter zijn dan {min}.",
      maxValue: "{label} moet kleiner zijn dan {max}.",
      email: "{label} moet een gelding emailadres zijn.",
      phoneNumber: "{label} moet een geldig mobiel nummer zijn.",
      sameAsPassword: "{label} moet overeen komen met wachtwoord hierboven.",
      unknownError: "{label} heeft een ongeldige waarde.",
    },
  },
  companyLabels: {
    consumptionCrops: {
      title: "Consumptiegewassen",
      options: {
        largeFruit: "Groot Fruit",
        smallFruit: "Klein Fruit",
        leafVegetables: "Bladgroenten",
        legumes: "Peulgroenten",
        fruitVegetables: "Vruchtgroenten",
        cabbageCrops: "Koolgewassen",
        rootAndTuberVegetables: "Wortel- en Knolgewassen",
        stalkVegetables: "Stengelgroenten",
        herbs: "Kruidgewassen",
        edibleMushrooms: "Eetbare paddenstoelen",
      },
    },
    ornamentals: {
      title: "Sierteeltgewassen",
      options: {
        flowerBulbsTubers: "Bloembollen en bloemknollen",
        bulbFlowersTubers: "Bolbloemen en knolbloemen",
        pottedPlants: "Potplanten",
        cutFlowers: "Snijbloemen",
        draftShrubs: "Trekheesters",
        cutGreen: "Snijgroen",
        beddingPlants: "Perkplanten",
        treeNursery: "Boomkwekerij",
        growingPerennials: "Vaste planten teelt",
        flowerSeedCultivation: "Bloemenzaadteelt",
        swampWaterPlants: "Moeras- en waterplanten",
        breedingBasicSeedProduction: "Veredelingsteelt en basiszaad productie",
        temporarilyUncultivatedTerrain: "Tijdelijk onbeteeld terrein",
        permanentlyUncultivatedTerrain: "Permanent onbeteeld terrein",
      },
    },
    cultivationMethod: {
      title: "Teelt methode",
      options: {
        biological: "Biologisch",
        regular: "Regulier",
        openField: "Vollegrond teelt",
        substrateBased: "Substraat teelt",
        hydroponic: "Hydroponic",
        verticalFarming: "Vertical Farming",
      },
    },
    countries: {
      title: "Landen",
      options: {
        nl: "Nederland",
        be: "België",
        de: "Duitsland",
        uk: "Verenigd Koninkrijk",
        fr: "Frankrijk",
        na: "Noord-Amerika",
        sa: "Zuid-Amerika",
        as: "Azië",
        es: "Spanje",
        pl: "Polen",
        ch: "Zwitserland",
        dk: "Denemarken",
        pt: "Portugal",
      },
    },
  },
  substrate: {
    title: "Substraat",
    unavailable: "Substraat is niet beschikbaar",
    chooseCrops: "kies de teelt om het substraat toe te voegen",
    notApplied: "Substraat niet toegevoegd.",
    button: {
      import: "Importeer substraat data",
      goToLogin: "Ga naar login",
      goToCultivationData: "Ga naar teeltdata",
      confirmImport: "Bevestig import",
      apply: "Voeg toe",
    },
    field: {
      id: "Unieke code",
      deliveryDate: "Leverdatum",
      recipeNumber: "Recept nummer",
      recipeName: "Recept naam",
      targetPh: "Doel PH",
      targetEc: "Doel EC",
      percentages: "Percentage (veen) alternatieven",
      plannedQuantity: "Geplande kwaliteit",
      moistureLevel: "Vochtgehalte",
      fertilizerProducts: "Bemestingsproducten",
      rawMaterials: "Grondstoffen",
      recipe: "Substraat receptuur",
      appliedTo: " Toegepast op",
    },
  },
  generalSubstrates: {
    title: "Substraat data",
    noEntries: "Geen data toegevoegd",
    substrates: "Substraat",
    addSubstrate: "Voeg substraat toe",
    preview: " Voorbeeld",
    new: "Nieuw substraat",
    insertId: "Voer de substraat-id in",
    notFound: "Substraat niet gevonden",
  },
  common: {
    of: "of",
    updated: " Bijgewerkt",
    export: "Export",
    exportCSV: "Export als CSV",
    exportXLSX: "Export als XLSX",
    name: "Naam",
    apply: "Toepassen",
    pleaseFillAllFields: "Vul alle verplichte velden in",
    deselect: " Deselecteer",
    duplicate: "Dupliceer",
    nothingFound: " Niets gevonden",
    save: "Opslaan",
    saveAddNew: "Opslaan + nieuw",
    remove: "Verwijderen",
    next: "Volgende",
    back: "Terug",
    loading: "Bezig met laden",
    edit: "Bewerk",
    yesterday: "Gister",
    today: "Vandaag",
    more: "Meer",
    download: "Download",
    title: "Titel",
    description: "Beschrijving",
    owner: "Eigenaar",
    lastModified: " Laatst gewijzigd",
    viewAll: " Bekijk alles",
    open: "Open",
    create: "Toevoegen",
    date: "Datum",
    saveChanges: " Wijzigingen opslaan",
    fieldIsRequired: "Veld is vereist",
    code: "Code",
    label: "Label",
    cancel: " Annuleren",
    update: "Update",
    complete: "Voltooien",
    action: "Actie",
    created: " Aangemaakt",
    upload: "Upload",
    forUsers: "Voor gebruikers",
    saveAndRepeat: " Opslaan + herhalen",
    saveAndNew: " Opslaan + nieuw",
    active: "Actief",
    inactive: "Inactief",
    prefillData: "Voeg details toe",
    cropRequired: "Teelt is vereist",
    dateRequired: "Datum is vereist",
    noDataToDisplay: "Geen gegevens om weer te geven",
    no: "Nee",
    exportType: "Kies export type",
    fileType: "Kies bestand type",
    preview: "Voorbeeld",
    done: "Klaar",
    reset: "Reset",
    skipAndSaveAndNew: "Overslaan + opslaan + nieuw",
    skipAndSaveAndRepeat: "Overslaan + opslaan + herhalen",
    companies: "Bedrijven",
    isARequiredField: "Is een vereist veld",
    clickToUpload: "Klik om te uploaden",
    orDragAndDrop: "of sleep en laat los",
    added: "Toegevoegd",
    mention: "Benoem",
    new: "Nieuw",
    settings: "Settings",
    backToHome: "Back to home",
    select: "Selecteer",
    includeInactive: "Inactieve meenemen",
    typeToSearch: "Voer tekst in om te zoeken",
  },
  protocol: {
    title: {
      myProtocols: "Protocollen",
      applyProtocol: "Voeg protocol toe",
      create: "Maak een protocol",
      potSizes: "Potmaat:",
      protocolName: "Protocol:",
      kpis: "KPI's:",
      week: "Week",
      stage: "Fase:",
      startDate: "Startdatum:",
      any: "Elke",
      unnamed: "Naamloos",
      protocol: "Protocol",
      tank: "Tank",
      action: "Acties",
      alert: "Alert",
      protocolsOverview: "Protocollen overzicht",
      trays: "Trays:",
      openField: "Open veld:",
      protocolKPIs: "Protocol KPI's:",
      protocolPlanning: "Protocol planning",
      createYourFirst: " Maak uw eerste protocol",
      developStrategy:
        "Ontwikkel een gewasstrategie die u kunt delen en afstemmen met uw team",
      startCreating: " Begin met het maken van uw protocol",
      or: "of",
      applyExisting: "Pas toe vanuit  een bestaand",
      duplicateProtocol: "Dupliceer protocol",
      editProtocol: "Bewerk protocol",
      addProtocol: "Voeg protocol toe",
      weeks: "Weken",
      withoutStage: "Zonder fase",
      allInOne: " Al uw protocollen in één overzicht.",
      observations: " Protocolobservaties:",
      createAndManage: "Maak en beheer de protocollen voor uw teelt.",
      new: "Nieuw protocol",
      plantingStartDates: " Startdatum teelt",
      plantingEndDates: "Einddatum teelt",
      taxonomy: "Taxonomie",
      container: "Container",
      plantingDates: "Teelt datum",
      tanksAndDescriptions: " Tanks en beschrijvingen",
      weatherAndDescription: " Weer en beschrijving",
      combinedProtocolsPlanning: "Gecombineerde protocol plannen",
      separateProtocolsPlanning: "Aparte protocol plannen",
      number: "Nummer",
    },
    alerts: {
      added: "Nieuw protocol toegevoegd!",
      updated: "Protocol succesvol geupdate!",
      deleted: "Protocol successvol verwijderd!",
      error: "Er is iets misgegaan!",
    },
    button: {
      add: "Voeg protocol toe",
      duplicateFrom: "Dupliceer vanuit",
      next: "Volgende",
      back: "Terug",
      addStage: "Voeg fase toe",
      addFirstStage: "Voeg je eerste fase toe",
      removeStage: "Verwijder fase",
      addKpi: "Voeg KPI toe",
      addFirstKpi: "Voeg je eerste KPI toe",
      removeKpi: "Verwijder KPI",
      addAction: "Voeg actie toe",
      addFirstAction: "Voeg je eerste actie toe",
      removeAction: "Verwijder actie",
      addAlert: "Voeg alert toe",
      addFirstAlert: "Voeg je eerste alert toe",
      removeAlert: "Verwijder alert",
      cancel: "Cancel",
      save: "Opslaan",
      edit: "Aanpassen",
      clone: "Dupliceer",
      update: "Update",
      delete: "Verwijder",
      select: "Selecteer",
      addCondition: "Voeg conditie toe",
      addFirstCondition: "Voeg je eerste conditie toe",
      addFertilizationSchema: "Voeg bemestingsschema toe",
      addFirstFertilizationSchema: "Voeg je eerste bemestingsschema toe",
      addClimateSchema: "Voeg klimaatschema toe",
      addFirstClimateSchema: "Voeg je eerste klimaatschema toe",
      addWeatherData: "Voeg klimaat parameter toe",
      addTank: "Voeg C-tank toe",
      addStartDates: "Voeg startdatum toe",
      pdf: "PDF",
      goToTask: "Ga naar taak",
      addActionTo: " Actie toevoegen aan",
      addAlertTo: " Waarschuwing toevoegen aan",
      addIngredient: " Voeg ingrediënt toe",
      addWeatherTarget: " Weerdoel toevoegen",
      addActionWithoutStage: "Actie toevoegen zonder fase",
      addAlertWithoutStage: "Alerts toevoegen zonder fase",
    },
    add: {
      stage: {
        type: "Soort",
        stages: "Fases",
        kpis: "KPI's",
        actions: "Acties",
        alerts: "Alerts",
        conditions: "Teelt conditie",
        generalConditions: "Algemene teelt conditie",
        fertilizationSchematics: "Bemestingschema's",
        climateSchema: "Klimaatschema",
      },
      label: {
        genus: "Geslacht",
        species: "Soort",
        cultivar: "Cultivar",
        potSize: "Potmaat",
        tray: "Tray",
        openField: "Open veld",
        checklist: "Selecteer checklist",
        startDate: "Startdatum tussen",
        stageName: {
          stage: "Fase",
          name: "Naam",
        },
        value: "Waarde",
        week: "Week",
        from: "Van",
        until: "Tot",
        fromWeek: "Van week",
        untilWeek: "Tot week",
        kpi: "KPI",
        selectKpi: "Selecteer KPI",
        number: "Aantal",
        units: "Eenheid",
        actionType: "Actie type",
        selectType: "Selecteer type",
        description: "Beschrijving",
        observationType: "Observatie type",
        protocolsName: "Protocol naam",
        name: "Naam",
        min: "Min",
        max: "Max",
        conditionType: "Conditie type",
        fertilizationSchema: "Bemestingsschema",
        climateSchema: "Klimaat schema",
        recurringAction: "Terugkerende taak",
        repeatWeekOffset: "Elke zoveel weken herhalen",
        repeats: "Aantal keren herhalen",
        endStartDate: "Einde van startdatum",
        enabled: "Enabled",
        startMonth: "Start month",
        startDay: " Begin maand",
        endMonth: " Einde maand",
        endDay: " Einde dag",
        assignToUsersByDefault: " Standaard toewijzen aan gebruikers",
        users: " Gebruikers",
        active: "Actief",
        failureAmount: "Antaal van uitval",
        option: "Optie",
        secondOption: "Tweede optie",
        product: "Product",
        parameter: "Parameter",
        timeframe: "Tijdsbestek",
        pot: "Pot",
        values: "Waardes",
        amount: "Hoeveelheid",
        assignToSpecificUsers: "Toewijzen aan specifieke gebruikers",
        checklistItem: "Checklist",
      },
    },
    kpi: [
      {
        id: "length",
        name: "Lengte",
        units: [
          {
            id: "cm",
            name: "cm",
          },
          {
            id: "inch",
            name: "inch",
          },
          {
            id: "m",
            name: "m",
          },
          {
            id: "mm",
            name: "mm",
          },
          {
            id: "ft",
            name: "ft",
          },
        ],
      },
      {
        id: "width",
        name: "Width",
        units: [
          {
            id: "cm",
            name: "cm",
          },
          {
            id: "inch",
            name: "inch",
          },
          {
            id: "m",
            name: "m",
          },
          {
            id: "mm",
            name: "mm",
          },
          {
            id: "ft",
            name: "ft",
          },
        ],
      },
      {
        id: "numberOfFlowers",
        name: "Aantal bloemen",
        units: [
          {
            id: "pieces",
            name: "Stuks",
          },
        ],
      },
    ],
    overview: {
      dateActive: "Datum actief",
      cropNameDescription: "Teelt naam / beschrijving",
      locations: "Locaties",
      batch: "Batch nummer",
      potweek: "Potweek",
      potSize: "Potmaat",
      plantNumber: "Aantal planten",
      dates: "Data",
    },
    condition: [
      {
        id: "potting_planting",
        name: "Potten/Planten",
      },
      {
        id: "substrate",
        name: "Substraat",
      },
      {
        id: "starting_material",
        name: "Start materiaal",
      },
      {
        id: "fertilization",
        name: "Bemesting",
      },
      {
        id: "rooting",
        name: "Beworteling",
      },
      {
        id: "irrigation_water_ec",
        name: "Irrigatie water EC",
      },
    ],
    annotation: {
      crop: "Kies een teelt",
      stage: " Voeg fase toe",
      kpi: "Voeg KPI's (kritieke prestatie indicators) toe",
      action: "Voeg actie toe",
      alert: "voeg alerts toe",
      conditionSchema: "Voeg algemene condities toe ",
      fertSchema: "Voeg bemestingsschema's toe",
      climateSchema: "Voeg Klimaatschema's toe",
      notApplied: "Protocol is niet toegepast.",
      noAvailableProtocols: "Geen protocol beschikbaar voor deze teelt.",
      considerCreating: ["Consider", "Maak een protocol aan"],
      applyProtocol: "Pas protocol toe",
    },
  },
  agreement: {
    weValueYourPrivacy: "Wij waarderen uw privacy",
    dataSafety:
      "Uw gegevens zijn veilig bij ons. Alle gegevens worden opgeslagen op AVG-compatibele servers. Wij verkopen uw gegevens niet aan externe partijen. We slaan geen gegevens op die we niet nodig hebben. Maar we willen onze softwareservices graag blijven verbeteren. Wil meer weten? Lees de privacyverklaring of stuur ons een e-mail.",
    read: "Lees de voorwaarden",
  },
  datePicker: {
    selectDate: "Selecteer datum",
    selectStart: "Selecteer startdatum",
    selectEnd: "Selecteer einddatum",
  },
  pagination: {
    showing: "Tonen",
    of: "van de",
    showMore: "Toon meer",
  },
  labAnalysis: {
    title: "Lab analyses",
    description: "Al je lab analyse data op één plek",
    benchmarking: {
      description:
        "Benchmarking gebeurt op basis van de streefwaarden van de ‘Bemestings Advies Basis’",
      title: "Code",
      tooHigh: "Te hoog",
      high: "Hoog",
      low: "Laag",
      tooLow: "Te laag",
    },
    dateSamplingUnit: "Datum analyse",
    descriptionAndNotes: " Beschrijving en opmerkingen",
    sampleDescription: " Analyse beschrijving",
    saveAnalysis: "Sla het lab rapport op",
    createAnalysis: "Maak lab rapport aan",
    chooseType: "Kies het soort",
    saveNote: "Notitie opslaan",
    addANoteToSampleOf: "Notitie toevoegen aan analyse van",
    showLabAnalysis: "Toon lab analyse verslag",
    noteText: "Notitie tekst",
  },
  layout: {
    cultivations: "Teelten",
    closeSidebar: "Sluit zijbalk",
    settings: "Instellingen",
    faq: "FAQ",
    contact: "Contact",
    disclaimer: "Disclaimer",
    openSidebar: "Open zijbalk",
    photoComparison: "Foto vergelijking",
    viewMyAccount: " Bekijk mijn profiel",
    companyAccount: "Account",
    account: "Bedrijfsaccount",
    switchCompany: "Wissel van bedrijf",
    signOut: "Uitloggen",
    dashboard: "Dashboard",
    sensors: "Sensoren",
    myTasks: "Mijn taken",
    protocols: "Protocollen",
    advices: "Adviezen",
    documents: "Documenten",
    addCrop: "Nieuwe teelt",
    addNote: "Notitie toevoegen",
    companySettings: " Bedrijfsinstellingen",
    locationSettings: "Locatie instellingen",
    sensorSettings: " Sensorinstellingen",
    userManagement: " Gebruikersbeheer",
    lsSettings: "Log & Solve instellingen",
    appSettings: "App instellingen",
    createCropsSettings: "Teelt instellingen aanmaken",
    exportSettings: "Export instellingen",
    cultivationActivities: "Teelthandeling",
    fertilization: "Bemesting",
    protectionTankMixes: "Tank mixen beschermingsmiddelen",
    notesAndChecklists: " Notities en checklists",
    substrates: "Substraten",
    climateBoxes: "Climate boxes",
    importCultivationData: "Import teelten",
    labAnalisis: "Lab analyses",
    summary: "Samenvatting",
    exportCultivationData: "Exporteer teeltdata",
    climateData: "Klimaat data",
  },
  add: {
    newCrop: "Nieuwe Teelt",
    newNote: "Nieuwe notitie",
    newProtocol: "Nieuw protocol",
    newTask: "Nieuwe taak",
  },
  noteActivity: {
    newNote: "Nieuwe notitie",
    userHaseCreated: " heeft een nieuwe toegevoegd",
    noteFor: "notitie voor",
  },
  noteCard: {
    pieces: "stuks",
    failureUnits: "Uitval",
    before: "Voor",
    after: "Na",
    saveFor: "Opslaan voor",
    crops: "Teelten",
    favourites: "Favorieten",
    editDescription: "Bewerk beschrijving",
    deleteBatch: "Verwijder allen",
  },
  dataExplorer: {
    title: "Data Explorer",
    aggView:
      "Een duidelijk overzicht waarin alle databronnen op één plek samen komen voor nieuwe inzichten",
    graphs: {
      measurements: "Measurements",
      sensors: "Sensors",
      conditions: "Climate & Weather",
      climate: "Climate",
      weather: "Weather",
      activities: "Activities",
    },
    photoExplorer: {
      noPhotos: "Er zijn geen foto's.",
      photoSizeOptions: {
        smallSquare: "Klein (vierkant)",
        medium: "Middelgroot",
        large: "Groot",
      },
      photoSizeOptionsPlaceholder: "Fotoformaat",
    },
    allMeasurements: "Alle metingen",
    measurements: "Metingen",
    noMeasurements: "Geen metingen",
    allSensors: "Alle sensoren",
    noSensors: "Geen sensoren",
    allConditions: "Alle condities",
    noConditions: "Geen condities",
    allActivities: "Alle activiteiten",
    activities: "activiteiten",
    noActivities: "Geen activiteiten",
    cropPhotosComparison: "Teeltfoto's vergelijken",
    sort: {
      none: "Standaard sorteren",
      agenda: "Sorteren op agenda week",
      cultivation: "Sorteren op teeltweek",
    },
    conditions: {
      title: "condities",
      parameters: {
        moisture: "Vocht",
        radiation: "Straling",
        rv: "RV",
        humidity: "Vochtigheid",
        irradiance: "Som van de stralingssterkte",
        leaf: "Som van het aantal uren nat blad",
        rain: "Som van totale neerslag",
      },
      sensors: {
        title: "sensoren",
        parameters: {
          Moist: "Vocht",
          temp: "Temperatuur",
          light: "Licht",
          battery: "Battery",
        },
      },
    },
    labData: {
      labEc: "Lab EC",
      labPh: "Lab pH",
    },
    sensors: {
      title: "sensoren",
      parameters: {
        Moist: "Vocht",
        temp: "Temperatuur",
        light: "Licht",
        battery: "Batterij",
      },
    },
    tabs: {
      data: "Data",
      photo: "Foto verkenner",
    },
  },
  settings: {
    company: {
      title: "Bedrijfsinstellingen",
      fertilizationMethod: {
        title: "Bemestingsstrategie",
        placeholder: "bijv. gecontroleerd vrijkomende meststof of 'A-B bassin'",
      },
      waterSource: {
        title: "Waterbron",
        placeholder: " bijv. 'boorgatwater' of 'regenwater'",
      },
      irrigationMethod: {
        title: " Irrigatiemethode",
        placeholder: "bijv. 'eb en vloed' of 'druppelaars'",
      },
      typeOfCultivation: {
        title: "Soort teelt",
        options: {
          containerField: " Vloeroppervlak container (buiten)",
          greenhouse: "Kas",
          openSoil: "Open veld",
          polytunnel: "Plastic tunnel",
          other: "Anders",
        },
      },
      cultivationIndoorOrOutdoor: {
        title: "Teelt binnen of buiten",
      },
      notes: {
        title: "Aanvullende opmerkingen over de groeiomstandigheden",
        placeholder: " Wat is relevant voor adviseurs om te weten?",
      },
      challenges: {
        title: "Uitdagingen/aandachtspunten",
        placeholder:
          " bijv. 'biologische controle' of 'waterverbruik verminderen'",
      },
      certifications: {
        title: " Certificeringen",
        placeholder: "Bijvoorbeeld MPS A+",
      },
      bulbGrower: {
        title: " Is uw bedrijf een Bollenkweker?",
        options: {
          yes: "Ja",
          no: "Nee",
        },
      },
    },
    notes: {
      title: "Notitie instellingen",
    },
    crops: {
      title: " Creëer teelt instellingen",
    },
    export: {
      title: "Export instellingen",
      pdfWeatherOff: "Weerdata",
      pdfWeekOff: " Teeltweek + agendaweek",
    },
  },
  exportCultivationData: {
    title: "Exporteer teeltdata",
    description:
      "Exporteer uw teeltdata als bestand en deel uw voortgang met andere telers.",
    notAvailable: "Geen items die overeenkomen met geselecteerde filters",
    exportType: {
      cultivation: "Teelt",
      fertilization: "Bemesting",
      cropProtection: "gewasbescherming",
      fertilizationBins: "Bemestingstanks",
    },
    exportRange: "Exporteer bereik",
    entries: "invoer",
  },
  summary: {
    gotoSummary: "Ga naar samenvatting",
    branch: {
      withPhoto: "Met foto",
      labData: "Labdata",
      advice: "Advies",
      document: "Document",
      fertilizationBin: "Bemestingstanks",
      climateData: "Klimaatdata",
    },
    title: "Samenvatting & inzichten van de kweker",
    description:
      "Moeiteloos navigeren door je teeltstrategie en deze optimaliseren voor weloverwogen beslissingen",
    summary: "Samenvatting",
    insights: "Inzichten",
    lastWeek: "Vorige week",
    lastTwoWeeks: "Laatste twee weken",
    lastMonth: "Vorige maand",
    custom: "Aangepast",
    addTaskToSelected: "Taak toevoegen aan selectie",
    addAdviceToSelected: "Advies toevoegen aan selectie",
  },
  labData: {
    pending: "Inkomende analyses",
    completed: "Voltooide analyses",
  },
  errors: {
    fileUpload: "Bestand uploaden mislukt",
    someFieldsAreFilledIncorrectly: "Sommige velden zijn onjuist ingevuld",
    completeTask: "Er is een fout opgetreden tijdens het voltooien van de taak",
    weDidNotManageTo: "We zijn er niet in geslaagd om de {context} te {type}.",
    helpUsImprove:
      "Help ons uw ervaring te verbeteren door een foutenrapport te sturen.",
    somethingWentWrong: "Oeps! Er ging iets mis!",
    type: {
      update: "update",
      create: "Maken",
      delete: "Verwijderen",
      read: "Lezen",
      download: "downloaden",
      import: "importeren",
      upload: "uploaden",
      validation: "valideren",
      unhandled: "handelen",
    },
    context: {
      crop: "teelt",
      user: "gebruiker",
      note: "notitie",
      alerts: "alerts",
      company: "bedrijf",
      unknown: "onbekend",
      file: "bestand",
      sensor: "sensor",
      document: "document",
      advice: "advies",
      protocol: "protocol",
      tankmix: "tankmix",
    },
  },
  upload: {
    progress: "Voortgang",
    speed: "Snelheid",
    timeLeft: "Resterende tijd",
    seconds: "s",
  },
  warnings: {
    slowPhotoUpload: {
      title: "Let op! Uploaden duurt langer dan normaal.",
      message:
        "De foto uploaden duurt langer dan verwacht. Probeer een lagere fotoresolutie in de camera-app in te stellen of gebruik een sneller netwerk.",
    },
    cameraPermissions: {
      title: "Cameratoestemming",
      message:
        "Je moet camera-rechten inschakelen voor deze site om qr-codes te kunnen scannen.",
    },
    cameraNotSupported: {
      title: "Apparaat of browser wordt niet ondersteund",
      message:
        "Uw apparaat of browser ondersteunt het scannen met een camera niet. Neem contact op met Log & Solve-ondersteuning.",
    },
  },
  exportLogData: {
    columns: {
      tankage: "Tankinhoud",
      calendarWeek: "Kalender week",
      calendarYear: "Kalender jaar",
      cultivationWeek: "Teeltweek",
      temperatureC: "Temperatuur (°C)",
      temperatureF: "Temperatuur (°F)",
      temperatureCMin: "Temperatuur min (°C)",
      temperatureCMax: "Temperatuur max (°C)",
      humidityPercent: "Vochtigheid (%)",
      precipitation: "Neerslag",
      irradiation: "Instraling",
      leafWetness: "Natheid van bladeren",
      date: "Datum",
      note: "Notitie",
      failureUnitsByLocation: "Storingseenheden per locatie",
      deliveredUnitsByLocation: "Geleverde eenheden per locatie",
      locationChange: "Locatiewijziging",
      labData: "Lab data",
      activity: "Activiteit",
      climateDataLocations: "Klimaat data locaties",
      climateData: "Klimaat data",
      fertilizationBinLocations: "Locaties van bemestingsbakken",
      fertilizationBin: "Bemestingsbak",
      failureUnits: "Storing eenheden",
      failureRate: "Faalpercentage (%)",
      cause: "Oorzaak",
      ratingSubject: "Beoordeling onderwerp",
      observationValue: "Observatie waarde",
      observationCount: "Aantal observaties",
      observationRating: "Beoordeling observatie",
      products: "Producten",
      actionUnits: "Actie-eenheden",
      actionValue: "Actie waarde",
      wateringAmount: "Hoeveelheid water geven",
      wateringEc: "EC tijdens water geven",
      measurementUnits: "Meeteenheden",
      measurementSourceType: "Type meetbron",
      measurementValue: "Meetwaarde",
      user: "Gebruiker",
      photos: "Foto's",
      photoLabel: "Fotolabel",
      labels: "Labels",
    },
  },
  crud: {
    create: {
      protocol: "Protocol succesvol aangemaakt",
      notes: "Notitie(s) succesvol aangemaakt",
      crop: "Teelt(en) succesvol aangemaakt",
      tankmix: "Tankmix succesvol gemaakt",
      advice: "Advies succesvol gemaakt",
      document: "Document succesvol aangemaakt",
      task: "Taak succesvol aangemaakt",
    },
    update: {
      protocol: "Protocol succesvol bijgewerkt",
      alerts: "Waarschuwing(en) succesvol bijgewerkt",
      crop: "Teelten succesvol bijgewerkt",
      notes: "Notitie(s) succesvol bijgewerkt",
      tankmix: "Tankmix succesvol bijgewerkt",
      advice: "Advies succesvol bijgewerkt",
      document: "ument succesvol bijgewerkt",
      company: "Bedrijf succesvol bijgewerkt",
      task: "Taak succesvol bijgewerkt",
    },
    reset: {
      auth: "Wachtwoord reset link succesvol verzonden",
    },
    apply: {
      protocol: "Protocol succesvol toegepast",
    },
    complete: {
      task: "Taak/taken succesvol afgerond",
    },
    delete: {
      task: "Taak/taken succesvol verwijderd",
      notes: "Notitie(s) succesvol verwijderd",
      tankmix: "Tankmix succesvol verwijderd",
      advice: "Advies succesvol verwijderd",
      document: "Document succesvol verwijderd",
      protocol: "Protocol succesvol verwijderd",
    },
    context: {
      protocol: "protocol",
    },
    upload: {
      file: "Bestand succesvol geüpload",
    },
    success: "Gelukt",
  },
  selectCropSettings: {
    cropOptionCard: "Teeltpagina opties",
    firstRow: "Eerse rij",
    secondRow: "Tweede rij",
    thirdRow: "Derde rij",
    availableOptions: "Beschikbare opties",
    draggingTutorial: "Sleep chips tussen rijen en beschikbare opties",
    sortBy: "Sorteer op",
    direction: "Richting",
    ascending: "Oplopend",
    descending: "Aflopend",
  },
  eoc: {
    title: "Einde van de teelt",
    updateStatus: "Werk de status van de teelt bij",
    extendByWeeks: "Verlengen met weken",
    endDate: "Einddatum",
    options: {
      keep: "Houd de verwachte einddatum van de teelt aan",
      extend: "Verleng de teeltperiode",
      end: "Einde teelt",
    },
    update: "Update",
  },
  router: {
    login: "Inloggen",
    passwordReset: "Wachtwoord opnieuw instellen",
    signUp: "Aanmelden",
    locales: "Talen",
    switchCompany: "Wisselen van bedrijf",
    cropsOverview: "Teeltoverzicht",
    dashboard: "Dashboard",
    summary: "Samenvatting",
    sensors: "Sensoren",
    lab: "Lab analyses",
    notesOverview: "Notite overzicht",
    tasksOverview: "Taken overzicht",
    addTask: "Voeg taak toe",
    editTask: "Bewerk taak",
    protocolsOverview: "Overzicht protocollen",
    addProtocol: "Voeg protocol toe",
    editProtocol: "Bewerk protocol",
    duplicateProtocol: "Dupliceer protocol",
    advicesOverview: "Adviezen overzicht",
    companyDocuments: "Bedrijfsdocumenten",
    platformDocuments: "Platformdocumenten",
    cropDocuments: "Teeltdocumenten",
    dataExplorer: "Data explorer",
    climateOverview: "Klimaat overzicht",
    addCrop: "Voeg teelt toe",
    editCrop: "Bewerk teelt",
    duplicateCrop: "Dupliceer teelt",
    addNote: "Voeg notitie toe",
    companySettings: "Bedrijf",
    cropsSettings: "Teelten",
    exportsettings: "Export",
    substratesSettings: "Substraat",
    sensorSettings: "Sensoren",
    locationSettings: "Locaties",
    userManagementSettings: "Gebruikersbeheer",
    notesSettings: "Notities",
    protocolsSettings: "Protocollen",
    tankMixesSettings: "Tankmixen",
    fertilizationBinsSettings: "Bemestingsbakken",
    climateDataSettings: "Klimaat data invoer",
    climateBoxesSettings: "Klimaat data koppeling",
    importCultivationData: "Teeltgegevens importeren",
    exportCultivationData: "Teeltgegevens exporteren",
    companyProfile: "Bedrijfsprofiel",
    userProfile: "Gebruikersprofiel",
    companiesSettings: "Bedrijfsinstellingen",
    advisorsSettings: "Instellingen adviseurs",
    rolesSettings: "Instellingen gebruikersrollen",
    unauthorized: "Ongeautoriseerd",
    agreement: "Overeenkomst",
  },
};
