import { db } from "@/firebase";
import { DocumentReference, Timestamp, doc } from "firebase/firestore";
import { ZodString, ZodType, z } from "zod";

export const timestamp = z.preprocess((val) => {
  if (val instanceof Timestamp) {
    return val.toDate();
  } else if (typeof val === "string") {
    try {
      const dateObject = new Date(val);
      if (dateObject.toString() !== "Invalid Date") {
        return dateObject;
      }
    } catch {
      return val;
    }
  }
  return val;
}, z.date());

export const documentReference = <T extends boolean>(string: T) => {
  const zodType = string
    ? z.string()
    : z.custom<DocumentReference>((d) => d instanceof DocumentReference);
  // @ts-ignore
  return z.preprocess<T extends true ? ZodString : ZodType<DocumentReference>>(
    (val) => {
      if (typeof val === "string") {
        return string ? val : doc(db, val);
      } else if (val instanceof DocumentReference) {
        return string ? val.path : val;
      }
      return val;
    },
    zodType,
  );
};
