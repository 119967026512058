import { CropField } from "@/data/crop-field-options";
import type { CompanySchema } from "@/schemas/company.schema";
import { useCompanyStore } from "@/stores/company";
import { Columns, useSettingsStore } from "@/stores/settings";
import { storeToRefs } from "pinia";
import { computed, type Ref } from "vue";

export function useCompany() {
  /** Company from store */
  const companyStore = useCompanyStore();

  const { tableColumns, selectCropOption } = storeToRefs(useSettingsStore());
  const { currentCompany, userCompany, isLoading, locationMap } =
    storeToRefs(companyStore);

  const cropFields = computed(
    () => currentCompany.value?.profile?.settings?.cropFields || [],
  );

  const isNl = computed(() =>
    (currentCompany.value?.profile?.companyLabels?.countries ?? []).includes(
      "nl",
    ),
  );

  const allowedTableColumns = computed({
    get() {
      return Object.fromEntries(
        Object.entries(tableColumns.value)
          .map((col) => {
            switch (col[0] as Columns) {
              case Columns.SUBSTRATE_INFO:
                return !cropFields.value.some(
                  (f) => f === CropField.ADD_SUBSTRATE_INFO,
                )
                  ? null
                  : col;

              case Columns.STARTING_AMOUNT:
                return !cropFields.value.some(
                  (f) => f === CropField.STARTING_NUMBER_OF_POTS,
                )
                  ? null
                  : col;

              case Columns.CURRENT_AMOUNT:
                return !cropFields.value.some(
                  (f) => f === CropField.CURRENT_NUMBER_OF_POTS,
                )
                  ? null
                  : col;

              case Columns.DRIPPERS:
                return !cropFields.value.some((f) => f === CropField.DRIPPERS)
                  ? null
                  : col;

              case Columns.BATCH_NUMBER:
                return !cropFields.value.some(
                  (f) => f === CropField.BATCH_NUMBER,
                )
                  ? null
                  : col;

              case Columns.YOUNG_PLANT_SUPPLIERS:
                return !cropFields.value.some(
                  (f) => f === CropField.YOUNG_PLANT_SUPPLIERS,
                )
                  ? null
                  : col;

              case Columns.SIZE_DESCRIPTION:
                return !cropFields.value.some(
                  (f) => f === CropField.SIZE_DESCRIPTION,
                )
                  ? null
                  : col;

              case Columns.SUPPLIER_DOCUMENT_NR:
                return !cropFields.value.some(
                  (f) => f === CropField.SUPPLIER_DOCUMENT_NR,
                )
                  ? null
                  : col;

              case Columns.CONTAINER_NR:
                return !cropFields.value.some(
                  (f) => f === CropField.CONTAINER_NR,
                )
                  ? null
                  : col;

              case Columns.SUBSTRATE_MIXTURE:
                return !cropFields.value.some(
                  (f) => f === CropField.SUBSTRATE_MIXTURE,
                )
                  ? null
                  : col;

              case Columns.FERTILIZATION:
                return !cropFields.value.some(
                  (f) => f === CropField.FERTILIZATION,
                )
                  ? null
                  : col;

              case Columns.BARCODE:
                return !cropFields.value.some((f) => f === CropField.BARCODE)
                  ? null
                  : col;

              case Columns.SPACING_DATE:
                return !cropFields.value.some(
                  (f) => f === CropField.SPACING_DATE,
                )
                  ? null
                  : col;

              case Columns.DESCRIPTION:
                return !cropFields.value.some(
                  (f) => f === CropField.DESCRIPTION,
                )
                  ? null
                  : col;

              case Columns.OBJECTIVES:
                return !cropFields.value.some((f) => f === CropField.OBJECTIVES)
                  ? null
                  : col;

              case Columns.BULB_SIZE:
                return !cropFields.value.some((f) => f === CropField.BULB_SIZE)
                  ? null
                  : col;

              case Columns.PERSON_PLANTED:
                return !cropFields.value.some((f) => f === CropField.PERSON)
                  ? null
                  : col;

              case Columns.PLANTS_PER_CONTAINER:
                return !cropFields.value.some(
                  (f) => f === CropField.PLANTS_PER_CONTAINER,
                )
                  ? null
                  : col;

              case Columns.NUMBER_OF_CONTAINERS:
                return !cropFields.value.some(
                  (f) => f === CropField.NUMBER_OF_CONTAINERS,
                )
                  ? null
                  : col;

              default:
                return col;
            }
          })
          .filter(Boolean) as [Columns, boolean][],
      ) as { [key in Columns]: boolean };
    },
    set(value) {
      tableColumns.value = value;
    },
  });

  const allowedSelectCropOptions = computed({
    get() {
      return {
        secondRow: selectCropOption.value.secondRow.filter(
          (col) => allowedTableColumns.value[col] !== undefined,
        ),
        thirdRow: selectCropOption.value.thirdRow.filter(
          (col) => allowedTableColumns.value[col] !== undefined,
        ),
        sort: {
          column:
            allowedTableColumns.value[selectCropOption.value.sort.column] !==
            undefined
              ? selectCropOption.value.sort.column
              : Columns.GENUS,
          direction: selectCropOption.value.sort.direction,
        },
      };
    },
    set(value) {
      selectCropOption.value = value;
    },
  });

  const photoOwnerId = computed(() => {
    return (
      currentCompany.value?.originalUserId || currentCompany.value?.id || ""
    );
  });

  return {
    allowedTableColumns,
    allowedSelectCropOptions,
    currentCompany: currentCompany as Ref<CompanySchema | null>,
    userCompany,
    isLoading,
    setViewCompanyId: companyStore.setViewCompanyId,
    cropFields,
    locationMap,
    photoOwnerId,
    isNl,
  };
}
