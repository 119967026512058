import mixpanel from 'mixpanel-browser';
import {
    AnalyticsActions,
    AnalyticsObjects,
  } from '@/data/analytics-constants';
  import { AnalyticsEvents } from '@/data/analytics-events';
  import { hasElements, isNullOrUndefined } from '@/utils/type-functions';
import * as Sentry from "@sentry/vue";
import { useCompany } from "@/composables/use-company";
import { watch } from 'vue';
import { useUser } from './use-user';

export function useAnalytics()  {
    const isProduction = process.env.NODE_ENV === 'production';
    const { userInDb, authUser} = useUser();
    
    watch([userInDb, authUser],
      ([userInDb, authUser]) => {
      if (userInDb && userInDb.id && authUser) {
        mixpanel.identify(userInDb.id);
        mixpanel.people.set({
          $name: userInDb.name,
          $email: authUser.email,
        });
      }
    }, { immediate: true });

    /**
     * Initialize mixpanel based in the current environment
     */
    function initializeMixpanel() {
      if (isProduction) {
        mixpanel.init('4459b334cd6cab68994f29dbc6101ac9', { ignore_dnt: true });
      } else {
        mixpanel.init('03a5943679a6596d1b669176cac7deb8', { ignore_dnt: true });
      }
    }

    /**
     * Track an event in mixpanel.
     * 
     * @param event The name of the event that is tracked
     * @param props All properties related to this event (see  {@link @/data/analytics-events} 
     *              for the required properties per event).
     */
    function track(event: string, props: Record<string, any> = {}) {
      const properties = props;
      if (userInDb.value?.id) {
        properties.distinct_id = userInDb.value?.id;
        mixpanel.track(event, properties);
      } 
    }

    /**
     * Track an event in mixpanel after performing check on the input.
     * 
     * @param object The object of the event that is tracked
     * @param action The action of the event that is tracked
     * @param properties All properties related to this event (see  {@link @/data/analytics-events} 
     *                    for the required properties per event).
     * @returns null
     */
    function trackEvent(
        object: AnalyticsObjects,
        action: AnalyticsActions,
        properties: Record<string, any> = {}
      ) {
        const { currentCompany } = useCompany();
        const eventName = `${object}_${action}`;
        let neededProperties;
        if (eventName in AnalyticsEvents){
          neededProperties = AnalyticsEvents[eventName as keyof typeof AnalyticsEvents];
        } 

        if (isNullOrUndefined(neededProperties)) {
          if (isProduction) {
            Sentry.captureMessage(`Non-existent analytics event sent: ${eventName}`);
          }
          return;
        } 

        neededProperties = neededProperties as Record<string, any>;

        const incomingPropertyNames = Object.keys(properties);
        const neededPropertyNames = Object.keys(neededProperties!);

        if (hasElements(neededPropertyNames)) {
          const isMissingNeededProperty = neededPropertyNames.some((name) => {
            const containsProperty = incomingPropertyNames.includes(name);
    
            if (!containsProperty) {
              // IMPROVEMENT: Add Sentry breadcrumb
              return true;
            }
    
            const matchesPropertyType =
              (typeof properties[name]).toString() === neededProperties[name];
            if (!matchesPropertyType) {
              // IMPROVEMENT: Add Sentry breadcrumb
              return true;
            }
    
            // All good!
            return false;
          });
    
          if (isMissingNeededProperty) {
            Sentry.captureMessage(`Missing property for event sent: ${eventName}`);
            return;
          }
        }

        const hasUnexpectedProperty = incomingPropertyNames.some((key) => {
          return !neededPropertyNames.includes(key);
        });
    
        if (hasUnexpectedProperty) {
          Sentry.captureMessage(`Unexpected property for event sent: ${eventName}`);
          return;
        }

        if (currentCompany?.value) {
          properties['company_name'] = currentCompany.value?.name;
        }

        // IMPROVEMENT: Add Sentry breadcrumb
        track(`${eventName}`, properties);
      }

  return { initializeMixpanel, trackEvent };
}
