import { z } from "zod";
import type { DocumentData, DocumentReference } from "firebase/firestore";
import type { CompanySchema } from "./company.schema";

type NotificationSettingsFields =
  | "dailySummary"
  | "endOfCultivationAlerts"
  | "sensorAlerts"
  | "taskAlerts";

export const allowedLocationSchema = z.object({
  businessLocation: z.object({
    id: z.string(),
    name: z.string(),
    level1Locations: z.array(
      z.object({
        id: z.string(),
        name: z.string(),
        level2Locations: z.array(
          z.object({
            id: z.string(),
            name: z.string(),
          }),
        ),
      }),
    ),
  }),
});

type AllowedLocation = z.infer<typeof allowedLocationSchema>;

export interface UserSensorSettings {
  receiveAlerts: Array<string | number>;
}

interface UserSchemaBase {
  id: string;
  active: boolean;
  name: string;
  email?: string | null;
  slug: string;
  type: "user" | "grower" | "admin" | "advisor" | "third-party";
  companyId: string;
  company?: CompanySchema;
  profile: {
    taskAlertPreference: string;
    notificationSettings: Record<NotificationSettingsFields, boolean>;
    phone?: string;
    preferredLanguage?: { code: string; name: string; text: string } | null; // TODO: can be string too but that will be migrated away
    sensorSettings?: UserSensorSettings;
  };
  config: {
    manageCompanies: string[];
    managedCompanies: Array<{
      allowedLocations: AllowedLocation[];
      companyId: string;
      isOwnCompany: boolean;
      roleId: string | null;
    }>;
    allowedLocations?: AllowedLocation[]; // read when isInOwnCompany
    companyOwner?: boolean;
  };
  roleId?: string | null; // read when isInOwnCompany
}

interface UserSchemaCreate {
  active: boolean;
  name: string;
  slug: string;
  type: "user" | "grower" | "admin" | "advisor" | "third-party";
  companyId: string;
  company: DocumentReference<DocumentData, DocumentData>;
  profile: {
    taskAlertPreference: string;
    notificationSettings: Record<NotificationSettingsFields, boolean>;
    phone?: string;
    preferredLanguage?: { code: string; name: string; text: string } | null; // TODO: can be string too but that will be migrated away
    sensorSettings?: UserSensorSettings;
  };
  config: {
    manageCompanies: string[];
    managedCompanies: Array<{
      allowedLocations: AllowedLocation[];
      companyId: string;
      isOwnCompany: boolean;
      roleId: string | null;
    }>;
    allowedLocations?: AllowedLocation[]; // read when isInOwnCompany
    companyOwner?: boolean;
  };
  roleId: string | null; // read when isInOwnCompany
  agreement: { version: string };
}

type UserSchemaRead = Readonly<UserSchemaBase>;

export type {
  UserSchemaBase as UserSchema,
  UserSchemaRead,
  UserSchemaCreate,
  AllowedLocation,
};
