import { defineStore } from "pinia";
import { ref } from "vue";

export enum ToastType {
  ERROR = "error",
  WARNING = "warning",
  SUCCESS = "success",
}

export type Toast = {
  id: number;
  type: ToastType;
  title: string;
  message: string;
  extra?: any;
  time: Date;
  show: boolean;
};

export const useToastsStore = defineStore("toasts", () => {
  const data = ref<Toast[]>([]);
  const latestIndex = ref<number>(1);

  const timeout = 5000;
  const maxLength = 3;

  const toastHideDelayMap = ref<{
    [key: number]: ReturnType<typeof setTimeout>;
  }>({});

  const addWarning = (title: string, message: string) => {
    const id = latestIndex.value++;
    data.value.push({
      id,
      type: ToastType.WARNING,
      title,
      message,
      time: new Date(),
      show: true,
    });

    const lastTwoVisibleIds = [...data.value]
      .filter((t) => t.show)
      .slice(-maxLength)
      .map((t) => t.id);

    for (const toast of data.value) {
      if (lastTwoVisibleIds.includes(toast.id)) continue;
      hideMessage(toast.id);
    }

    startDelayedHide(id);
  };

  const addError = (error: any, title: string, message: string) => {
    const id = latestIndex.value++;
    data.value.push({
      id,
      type: ToastType.ERROR,
      title,
      message,
      extra: { name: error.name, message: error.message, stack: error.stack },

      time: new Date(),
      show: true,
    });

    const lastTwoVisibleIds = [...data.value]
      .filter((t) => t.show)
      .slice(-maxLength)
      .map((t) => t.id);

    for (const toast of data.value) {
      if (lastTwoVisibleIds.includes(toast.id)) continue;
      hideMessage(toast.id);
    }

    startDelayedHide(id);
  };

  const addSuccess = (title: string, message: string) => {
    const id = latestIndex.value++;
    data.value.push({
      id,
      type: ToastType.SUCCESS,
      title,
      message,
      time: new Date(),
      show: true,
    });

    const lastTwoVisibleIds = [...data.value]
      .filter((t) => t.show)
      .slice(-maxLength)
      .map((t) => t.id);

    for (const toast of data.value) {
      if (lastTwoVisibleIds.includes(toast.id)) continue;
      hideMessage(toast.id);
    }

    startDelayedHide(id);
  };

  const hideMessage = (id: number) => {
    const index = data.value.findIndex((t) => t.id === id);
    if (index > -1) {
      data.value[index].show = false;
    }
  };

  const stopDelayedHide = (id: number) => {
    const timeout = toastHideDelayMap.value[id];
    if (timeout) {
      clearTimeout(timeout);
    }
  };

  const startDelayedHide = (id: number) => {
    toastHideDelayMap.value[id] = setTimeout(() => {
      hideMessage(id);
    }, timeout);
  };

  return {
    data,
    addError,
    addWarning,
    addSuccess,
    hideMessage,
    stopDelayedHide,
    startDelayedHide,
  };
});
