export default {
  index: {
    vidFname: "log-and-solve-comp-en.mp4",
    topNav: {
      item1: "Avantages",
      item2: "Contactez",
      item3: "Tableau de bord",
      item4: "S'identifier",
    },
    hero: {
      title: "Mieux grandir avec les données",
      intro:
        "<p>Nous aidons les producteurs à collecter et à stocker des données pertinentes sur les cultures afin de mieux les comprendre. Ces données vous aident dans vos décisions quotidiennes pour prévenir les problèmes de culture.</p>",
      email: "Envoyez-nous un courriel pour participer",
    },
    chats: [
      {
        title: "Comparer les photos",
        content:
          "Comment votre culture se comporte-t-elle par rapport à celle de l'année dernière ?",
      },
      {
        title: "Évaluation des résultats par des experts",
        content:
          "Retour d'information immédiat et individuel de la part des conseillers techniques",
      },
      {
        title: "Réduire l'irrigation",
        content: "Le substrat est trop mouillé",
      },
      {
        title: "Augmenter la conductivité",
        content: "Niveau de nutrition trop faible !",
      },
    ],
    cards: [
      {
        title: "Minimiser les risques en culture",
        content:
          "La combinaison des données sur la culture peut expliquer les problèmes en culture. Cela vous permet de tirer un meilleur parti de votre culture et d'améliorer les cultures.",
      },
      {
        title: "Communication directe avec les conseillers",
        content:
          "Les données numériques de culture peuvent être partagées avec des techniciens. Vous pouvez déterminer qui a accès à vos données de culture et vous pouvez rapidement procéder à des ajustements si nécessaire.",
      },
      {
        title:
          "Valorisez votre savoir faire technique et votre valeur commerciale",
        content:
          "Que vous souhaitiez vendre votre entreprise ou transmettre vos connaissances. L'enregistrement des données de culture est un atout et valorise votre entreprise et votre savoir faire.",
      },
      {
        title: "Une certification rapide et plus facile",
        content:
          "Enregistrez l'utilisation des engrais, des pesticides et autres intrants. Log&Solve vous aide à collecter des données et permet une plus grande transparence dans la communication avec vos clients.",
      },
    ],
    companyPitch: {
      content:
        "En collectant des données pertinentes, nous vous aidons à mieux comprendre vos processus de culture. L'enregistrement continu de notes, de photos, d'analyses d'eau et de substrat et d'autres données pertinentes vous aide à dresser la carte de votre situation. Il nous est ainsi plus facile de vous conseiller et de trouver rapidement des solutions à vos problèmes de culture. Nous nous efforçons de prévenir les problèmes en cultures. Nous combinons un logiciel intelligent avec les connaissances de conseillerstechniques expérimentés.",
      smallText: "Rendu possible par Klasmann-Deilmann",
    },
    contact: {
      title: "Contactez",
      intro:
        "Vous êtes intéressé par les possibilités offertes par Log & Solve pour votre production ? Demandez à votre représentant Klasmann-Deilmann France",
      buttonText: "Envoyez-nous un courriel pour obtenir des informations",
      persons: [
        {
          name: "Co-Anne van Waaij",
          email: "log.solve@klasmann-deilmann.com",
          phoneNumber: "tel: +31.6.13.53.50.54",
        },
      ],
    },
    faq: {
      titleOne:
        "À propos de la propriété des données, du RGPD et de la sécurité",
      itemsOne: [
        {
          title:
            "Aurai-je le contrôle de mon tableau de bord et de mes données ?",
          content:
            "Oui, vous serez toujours le propriétaire de vos données et vous pourrez les retirer à tout moment. Notre service est conforme au Règlement Général sur la Protection des Données  (RGPD) de l'Union européenne.",
        },
        {
          title: "Qu'est-ce que le RGPD ?",
          content:
            "Le Règlement général sur la protection des données (RGPD) est une loi de l'Union européenne qui a été mise en œuvre le 25 mai 2018 et qui exige des organisations qu'elles protègent les données personnelles et qu'elles fassent respecter le droit à la vie privée de toutes les personnes sur le territoire de l'UE. Le règlement comprend sept principes de protection des données qui doivent être mis en œuvre et huit lois sur la protection des données qui doivent être facilitées. Il autorise également les autorités chargées de la protection des données à appliquer la RGPD en prévoyant des sanctions et des amendes.",
        },
        {
          title: "Qui est le propriétaire de mes données ?",
          content:
            "En tant qu'utilisateur du tableau de bord Log & Solve, vous serez toujours le propriétaire de vos données. Vous pouvez être certain que vos données seront traitées de manière absolument confidentielle et ne seront pas partagées avec un tiers sans votre consentement explicite. Log & Solve est un service et une propriété de Klasmann-Deilmann.",
        },
        {
          title: "À quelles fins Klasmann-Deilmann utilisera vos données?",
          content:
            "a)\tPour fournir le service Log & Solve \n b)\tPour vous aider à obtenir des conseils personnalisés, l'équipe de Log & Solve peut accéder à vos données et créer une évaluation statistique \n c) Pour envoyer des alertes automatisées et rapides sur l'état de vos cultures \n d)\tEffectuer des analyses statistiques anonymes pour améliorer continuellement nos services \n e) Adapter et améliorer nos mesures de sécurisation des données.",
        },
        {
          title:
            "Mes données historiques seront-elles sauvegardées dans mon tableau de bord ?",
          content:
            "Oui, vos données historiques restent toujours en sécurité dans votre tableau de bord. Vous pourrez créer dans votre tableau de bord des feuilles de culture séparées pour chaque culture et chaque lot, qui seront classés pour un accès futur, par exemple pour comparer différents lots de différentes années.",
        },
        {
          title:
            "Mes données sur les cultures sont-elles stockées en toute sécurité ?",
          content:
            "Log & Solve répond à des normes de sécurité élevées qui sont généralement acceptées. Log & Solve est hébergé sur Amazon AWS, l'une des solutions d'hébergement en cloud les plus respectées et les plus sûres du marché. Nos serveurs sont surveillés pour détecter toute activité suspecte et les dernières mises à jour de sécurité sont régulièrement appliquées.",
        },
      ],
      titleTwo: "À propos des coûts",
      itemsTwo: [
        {
          title:
            "Puis-je essayer le tableau de bord Log & Solve avec un compte de test gratuit ?",
          content:
            "Oui, vous pouvez utiliser le tableau de bord gratuitement pendant une période d'essai de deux mois.",
        },
        {
          title:
            "Combien coûte l'utilisation régulière du tableau de bord Log & Solve ?",
          content:
            "Le tableau de bord Log & Solve est disponible dans le cadre de divers abonnements mensuels offrant différentes fonctionnalités. L'abonnement de base permet d'utiliser le tableau de bord Log & Solve avec les fonctionnalités du journal de bord pour vos différentes cultures et différents lots.",
        },
        {
          title:
            "En plus de l'abonnement de base, Log&Solve propose différents modules complémentaires, qui peuvent être adaptés à vos besoins et à votre système de culture :",
          content:
            "Service d'échantillonnage de substrats, Systèmes de sondes (par exemple, humidité du substrat, température du substrat, température de l'air, rayonnement, etc.), et Service de conseil technique",
        },
        {
          title: "Veuillez nous contacter pour une proposition sur mesure.",
          content: "",
        },
      ],
      titleThree: "À propos des fonctionnalités de Log & Solve",
      itemsThree: [
        {
          title: "Puis-je enregistrer des données par lot de culture ?",
          content:
            "Oui, vous pouvez enregistrer des données telles que des notes, des images et des résultats d'analyse par série et par culture. L'équipe Log & Solve peut vous aider à créer des pages de données pour les nouvelles séries mises en production et les nouvelles cultures dans votre tableau de bord si nécessaire. Les données des cultures précédentes seront toujours disponibles.",
        },
        {
          title:
            "Comment puis-je ajouter des analyses de laboratoire (substrat et eau) à mon tableau de bord ?",
          content:
            "Toutes les analyses seront automatiquement téléchargées dans votre tableau de bord par l'équipe d'assistance Log & Solve. Les laboratoires avec lesquels nous travaillons pour Log & Solve fournissent les données dans un format approprié pour le téléchargement automatique.",
        },
        {
          title:
            "Puis-je utiliser Log & Solve sur mes appareils mobiles (smartphone, tablette, etc.) ?",
          content:
            "Oui, vous pouvez utiliser Log & Solve sur vos appareils mobiles. Le tableau de bord et toutes ses fonctionnalités sont entièrement accessibles avec les smartphones et les tablettes. Il est possible d'ajouter des photos au tableau de bord à partir de votre galerie de photos ou même de prendre des photos avec l'appareil photo de votre appareil mobile directement dans votre tableau de bord. Il est également possible de télécharger des notes avec vos appareils mobiles.",
        },
        {
          title:
            "Comment Klasmann-Deilmann peut-il me soutenir par le biais de Log & Solve ?",
          content:
            "Klasmann-Deilmann peut soutenir votre stratégie de culture en vous fournissant une expertise numérique sur les analyses de substrat et d'eau en combinaison avec d'autres données disponibles telles que des photos, des enregistrements météorologiques, des données issues de sondes et des notes pour évaluer la situation actuelle des cultures. Log & Solve est un service supplémentaire de Klasmann-Deilmann, il ne remplace ni n'affecte le service que vous recevez actuellement de Klasmann-Deilmann.",
        },
      ],
    },
    copyrightText: "Log & Solve est un service numérique de Klasmann-Deilmann",
  },
  docs: { title: "Les documents" },
  dashboard: {
    title: "Données de culture",
    components: {
      mainNavigation: [
        { text: "Données de culture" },
        { text: "Général" },
        { text: "Tâches" },
        { text: "Profil du producteur" },
        { text: "Capteurs" },
        { text: "Klasmann-Deilmann webshop" },
        { text: "La culture en bref" },
        { text: "FAQ" },
        { text: "Menu" },
        { text: "Ajouter la culture" },
        { text: "Profil de la société" },
        { text: "Déconnexion" },
        { text: "Note" },
        { text: "L'administrateur" },
        { text: "Changer d'entreprise" },
        { text: "Protocols" },
        { text: "Daily summary" },
      ],
      cropNavigation: {
        showMoretext: "Autre cultures",
        items: [
          { id: "log", text: "Journal", slug: "", icon: "clipboard-list" },
          { id: "labAnalyses", text: "Analyses", slug: "lab", icon: "flask" },
          { id: "photos", text: "Photos", slug: "photos", icon: "image" },
          {
            id: "profile",
            text: "Profil de la culture",
            slug: "edit",
            icon: "edit",
          },
          {
            id: "qrCode",
            text: "Télécharger le QR-code",
            slug: "pdf",
            icon: "file-pdf",
          },
          {
            id: "protocol",
            text: "Protocol",
            slug: "protocol",
            icon: "scroll",
          },
        ],
      },
      labDataChart: {
        lineDescription: "Optimum",
        selectMetrics: "Sélectionner les paremètres",
        pickMetrics: "Choisir les paramètres",
        pickWeather: "Choisir les paramètres",
        ecNutrientContentConverted:
          "Les éléments nutritifs sont convertis en Ec de référence.",
        units: {
          length: "Longueur",
          diameter: "Diamètre",
          ec: "Ec",
          ph: "pH",
          weight: "Weight",
          yield: "Yield",
          numberOfBuds: "Number of buds",
          substrate: "Substrate",
          drain: "Drain",
          gift: "Gift",
          ditch: "Ditch",
        },
      },
      composition: { title: "Composition" },
      ratio: { title: "Ratio" },
      ec: { title: "Valeur EC" },
      ph: { title: "Valeur pH" },
      analysis: {
        title: "Résultats des analyses",
        labDataTable: {
          labId: "Lab id",
          date: "Date",
          export: "Exportation",
          edit: "Éditer",
          delete: "Supprimer",
          addNote: "Ajouter une note",
          deleteNote: "Supprimer la note",
          cancel: "Annuler",
          addNoteHeader: "Ajouter une note à l'échantillon de",
          sampleDescription: "Description de l'échantillon",
          table: {
            titleLeft: "Macroéléments en mmol/l",
            titleCenter: "Microéléments en mmol/l",
            titleRight: "",
            titleFirstColumn: "Date du prélèvement",
            titleUnit: "Unit",
          },
          tableLufa: {
            titleLeft: "Macroéléments en mmol/l",
            titleCenter: "Microéléments en mmol/l",
            titleRight: "",
            titleFirstColumn: "Date du prélèvement",
            titleUnit: "Unit",
          },
          legend: {
            title: "Légende",
            description:
              "L'évaluation comparative est effectuée sur la base du 'Bemestings Advies Basis' (système néerlandais de conseil en matière de fertilisation)",
            ranks: [
              { title: "trop haut", subTitle: "Écart supérieur à 2" },
              { title: "haut", subTitle: "Écart de 1 ou plus" },
              { title: "bas", subTitle: "Écart de -1 ou inférieur" },
              { title: "trop bas", subTitle: "Écart inférieur à -2" },
            ],
          },
          settings: { title: "Paramètres", displayUnit: "Afficher les unités" },
        },
      },
      photos: {
        title: "Photos de la culture",
        buttons: {
          remove: { value: "Supprimer", singular: "photo", plural: "photos" },
          edit: { value: "Modification" },
          view: { value: "Annuler" },
        },
        options: {
          all: { label: "Toutes les photos" },
          overview: { label: "Vue d'ensemble" },
          single: { label: "Une seule plante" },
          detail: { label: "Détail" },
          roots: { label: "Racines" },
        },
      },
      uploadPhoto: {
        bigTitle: "Photos de la culture",
        subTitle: "Photo details:",
        caption: "Légende",
        firstOption: "Choisissez une photo",
        secondOption: "Type de photo",
        success: "Téléchargé avec succès!",
        options: {
          overview: { value: "Vue d'ensemble" },
          single: { value: "Une seule plante" },
          detail: { value: "Détail" },
          roots: { value: "Racines" },
        },
        thirdOption: "Date",
        button: "Sauvegarder",
      },
      cropProfile: {
        bigTitle: "Profil de culture",
        firstCategory: {
          title: "Profil de la culture",
          cultivation: {
            text: "Espèce référente",
            value: "Choisissez une culture (comparable)",
            shrubsTrees: "(arbuste / arbre)",
          },
          familyName: { text: "Espèce", placeholder: "Par exemple, benjamina" },
          name: { text: "Variété", placeholder: "Par exemple, danielle" },
          pottingDate: "Date de rempotage",
          spacingDate: "Date de distançage",
          deliveryDate: "Fin de culture (prévue)",
          potSize: "Taille de pot (cm)",
          location: {
            text: "Emplacement de la culture",
            placeholder: "Par exemple, 'serre 2' ou 'tunnel 1'",
          },
          rideNumber: "Identification du lot de substrat",
          soilAdditive: "Autres additifs dans le substrat",
          certification: {
            text: "Certifications",
            options: {
              mps: { text: "MPS" },
              globalGap: { text: "Global GAP" },
              skal: { text: "SKAL" },
            },
          },
          other: "Autres remarques",
          button: "Sauvegarder",
        },
        secondCategory: {
          title: "Conditions de culture",
          fertilization: {
            text: "Méthode de fertilisation",
            placeholder:
              "Par exemple, engrais à libération contrôlée ou ferti-irrigation",
          },
          waterSource: {
            text: "Eau d'arrosage",
            placeholder: "Par exemple, 'eau de forage' ou 'eau de pluie'",
          },
          irrigation: {
            text: "Méthode d'arrosage",
            placeholder: "Par exemple, 'sub-irrigation' ou 'goutteurs'",
          },
          resource: "Protection des cultures",
          construction: {
            text: "Type de culture",
            options: {
              containerField: {
                text: "Surface au sol du conteneur (extérieur)",
              },
              greenhouse: { text: "Serre" },
              polytunnel: { text: "Tunnel plastique" },
              outdoorShadowNet: {
                text: "Culture en plein air avec filet d'ombrage",
              },
              other: { text: "Autre" },
            },
          },
          extraRemarks: {
            text: "Notes complémentaires sur les conditions de culture",
            placeholder: "Par exemple, 'culture biologique'",
          },
          button: "Sauvegarder",
        },
        thirdCategory: {
          title: "Objectifs et défis pour cette culture",
          comment: "",
          button: "Sauvegarder",
        },
      },
      notes: {
        bigTitle: "Notes de culture",
        new: {
          title: "Nouvelle note",
          hiddenTitle: "Catégorie de note",
          classButton: "+ Ajouter",
          saveButton: "Sauver",
        },
        saved: {
          title: "Notes enregistrées",
          firstAttributes: "Date",
          secondAttributes: "Catégorie",
          buttonAttributes: "Supprimer",
          hiddenAttributes: "Note",
        },
        categories: {
          waterAndFertilization: { label: "Eau et fertilisation" },
          cropControl: { label: "Contrôle des cultures" },
          cultivationActivity: { label: "Activité de culture" },
          observation: { label: "Observation" },
          misc: { label: "Divers" },
        },
      },
      selectLanguage: { firstSelect: "Choisir la langue" },
    },
  },
  dataExplorer: {
    photoExplorer: {
      noPhotos: "Il n'y a pas de photos.",
      photoSizeOptions: {
        smallSquare: "Petit (carré)",
        medium: "Moyen",
        large: "Grand",
      },
      photoSizeOptionsPlaceholder: "Taille de la photo",
    },
    tabs: {
      data: "Données",
      photo: "explorateur de photos",
    },
  },
  userManagement: {
    title: "Paramètres utilisateurs",
    user: {
      title: "Utilisateur",
      name: "Nom",
      permissions: "Permissions",
      role: "Poste",
      active: "Actif",
      actions: "Actions",
    },
    thirdParty: {
      title: "Invitation utilisateur et requêtes",
      accept: "Accepté",
      reject: "Rejeté",
      awaitingAction: "Action attendue",
      revokeAccess: "Accès supprimé",
      requestAccess: "Demande d'accès pour un utilisateur invité",
      revoked: "Accès supprimé pour un utilisateur invité",
      submit: "Valider",
      requestSent: "Invitation utilisateur envoyée",
      alreadyInvited: "Une invitation a déjà été envoyée à cette adresse",
      backToOverview: "Retour à la vue globale des cultures",
    },
    save: "Sauvegarder",
    userUpdated: "Mise à jour utilisateur effectuée avec succès !",
    userTypes: [
      { id: "user", name: "Utilisateur" },
      { id: "admin", name: "Admin" },
    ],
    advisors: "Utilisateur standard",
    emails: {
      accepted: {
        subject: "Votre invitation a été acceptée !",
        message: "Votre invitation a été acceptée !",
      },
      rejected: {
        subject: "Votre invitation a été refusée !",
        message: "Votre invitation a été refusée !",
      },
    },
  },
  admin: {
    title: "Administrateur",
    companyName: "Nom",
    contactPerson: "Contact person",
    addCompany: "Ajouter une entreprise",
    addHelpText: "Le nom complet de la nouvelle société",
    defaultLanguage: "Langue par défaut",
    addLanguageHelpText: "Définir la langue par défaut de l'entreprise",
    companyLabels: "Company properties",
    companyLabelsHelpText: "Set properties",
    cta: "Ajouter une entreprise",
    advisorConfig: {
      title: "Configuration des utilisateurs standards",
      addAdvisor: "Ajouter un utilisateur standard",
      updateAdvisor: "Mettre à jour un utilisateur",
      deleteAdvisor: "Supprimer un utilisateur",
      cancel: "Annuler",
      editAction: "Editer",
      thSlug: "Nom",
      thCompanies: "Entreprise",
      thAction: "Action",
      fieldSlug: "Etiquette",
      fieldCompanyIDs: "EntrepriseIDs",
      thirdPartyConfig: "Configuration de l'utilisateur",
    },
    roleConfig: {
      title: "Role Config",
      addRole: "Add role",
      editRole: "Edit role",
      rolesTable: {
        title: "Roles",
        columns: ["Role", "Actions"],
      },
      roleName: "Role name",
      permissionsTable: {
        columns: ["Action", "View", "Create", "Edit", "Delete"],
      },
      allowedLocations: {
        title: "Allowed locations",
        label: "Select allowed locations",
      },
      forAllCompanies: "Display for all companies",
      forThirdParty: "Intended for third parties",
      forThirdPartyDefault: "Assign by default to third parties",
      save: "Save",
      cancel: "Cancel",
    },
    userRoleConfig: {
      title: "User Role Config",
      usersTable: {
        title: "Users",
        columns: ["Name", "Role", "Actions"],
      },
      save: "Save",
    },
    sensorConfig: {
      title: "Sensor Config",
      selectCompany: "Sélectionner l'entreprise",
      save: "Sauvegarder",
    },
    weatherDataConfig: {
      title: "Données climatiques",
      addWeather: "Ajouter des données climatiques",
      selectCompany: "Sélectionner l'entreprise",
      selectLocation: "Sélectionner l'emplacement",
      uploadWeatherData: "Télécharger des données climatiques",
      save: "Sauvegarder",
    },
    locationsConfig: {
      title: "Emplacements",
      updateLocations: "Mettre à jour les emplacements",
      selectLocation: "Sélectionner un emplacement",
      addLocation: "Ajouter un emplacement",
      noLocations: "Pas d'emplacement",
      cancel: "Annuler",
      editAction: "Editer",
      fieldSlug: "Etiquette",
      thSlug: "Etiquette",
      thLocations: "Emplacements",
      thAction: "Action",
      thName: "Nom",
      thLatitude: "Latitude",
      thLongitude: "Longitude",
    },
    qrCodeSubstrate: {
      title: "QR code du substrat",
      uploadFormTitle: "Télécharger un document concernant le substrat",
      upload: "Télécharger",
      success: "{nr} données sur le substrat créées",
      failure: "Une erreur est survenue à la création des données du substrat",
    },
    analyticsConfig: {
      title: "Suivi analytique",
      btnCompanyAnalytics: "Télécharger le suivi analytique de l'entreprise",
      btnTrackingAnalytics: "Télécharger le suivi analytique",
    },
  },
  companyProfile: {
    title: "Profil de la société",
    companyId: "ID de l'entreprise",
    labCustNumber: "Lab customer number",
    invite: "Inviter des utilisateurs",
    inviteType: "Invitation type",
    inviteTypes: [
      { id: "user", name: "Utilisateur" },
      { id: "admin", name: "Administrateur" },
    ],
    inviteEmailType: "Invitation email type",
    inviteEmailTypes: [
      { id: "newCustomer", name: "New customer" },
      { id: "substrateSampleService", name: "Substrate sample service" },
      { id: "cropServiceLabAnalyses", name: "Crop service, lab analyses" },
    ],
    addThirdPartyUsers: "Add third party users",
    inviteCta: "Envoyer une invitation",
    inviteSuccess: "Invitation envoyée!",
    userManagement: "Gestion des utilisateurs",
    inviteHelp:
      "Accordez l'accès à votre tableau de bord en ajoutant des membres ici. Saisissez une adresse e-mail pour inviter un nouvel utilisateur.",
    InvitationLink: "Lien d'invitation",
    noPreferredLanguage: "Pas de langue préférée",
    sidebar: {
      profile: "Profile",
      sensorConfig: "Configuration des capteurs",
      locationConfig: "Configuration des emplacements",
      exports: "Exports",
      tasks: "Tâches",
      noteChecklists: "Liste des notes",
      tankMixes: "Mélanges en cuves pour la protection des cultures",
      cropsImport: "Importer des cultures",
      locationsQr: "QR code des emplacements",
      userManagement: "Gestion des utilisateurs",
      protocols: "Protocols",
    },
  },
  sensorConfig: { providers: { blockbax: "Blockbax", tentacles: "Tentacles" } },
  general: {
    sidebar: {
      documents: "Documents",
      climate: "Climat",
      fertilization: "Fertilisation",
      notes: "Notes",
      labAnalyses: "Lab analyses",
      advices: "Advices",
    },
  },
  languages: {
    nl: "Néerlandais",
    en: "Anglais",
    de: "Allemand",
    fr: "Français",
  },
  profile: {
    title: "Profil du producteur",
    name: "Nom",
    phone: "Téléphone",
    password: "Mot de passe",
    passwordsDontMatch: "Les mots de passe ne correspondent pas!",
    confirmPassword: "Confirmez le mot de passe",
    changePassword: "Changer le mot de passe",
    preferredLanguage: "Langues préférée",
    youngPlantSuppliers: "Fournisseur de jeunes plants",
    sensorProvider: "Sensor provider",
    bulbGrower: "Bulb grower",
    taskSensorNotificationPreference:
      "Préférences de notifications pour les Tâches / Capteurs",
    contact: {
      title: "Contact",
      companyName: "Nom de la société",
      clientNumber: "Numéro de client",
      adress: "Adresse",
      contactPerson: "Interlocuteur",
      phoneNumber: "Numéro de téléphone",
      email: "Adresse mail",
    },
    location: {
      title: "Lieu",
      locationAmount: {
        title: "Nombre de sites",
        placeholder: "Par exemple, '3 pépinières de plantes en pot'",
      },
      surface: {
        title: "Superficie de culture",
        placeholder: "Par exemple, '5 ha'",
      },
      cropAmount: {
        title: "Nombre d'espèces végétales",
        placeholder: "Nombre total d'espèces en production dans l'entreprise",
      },
      notes: {
        title: "Autres remarques",
        placeholder: "Que doivent savoir les conseillers ?",
      },
      addLocation: "Ajouter un emplacement",
      locationsList: "Liste des emplacements",
      addLocationLevel1: "Ajouter un emplacement de niveau 1",
      editLocationLevel1: "Editer un emplacement de niveau 1",
      addLocationLevel2: "Ajouter un emplacement de niveau 2",
      editLocationLevel2: "Editer un emplacement de niveau 1",
      addSurfaceArea: "Ajouter une surface de culture",
      editSurfaceArea: "Editer une surface de culture",
    },
    growingConditions: {
      title: "Conditions de culture",
      fertilizationMethod: {
        title: "Méthode de fertilisation",
        placeholder:
          "Par exemple, engrais à libération contrôlée ou ferti-irrigation",
      },
      waterSource: {
        title: "Eau d'arrosage",
        placeholder: "Par exemple, 'eau de forage' ou 'eau de pluie'",
      },
      waterSample: {
        title: "Méthode d'arrosage",
        placeholder: "Par exemple, 'sub-irrigation' ou 'goutteurs'",
      },
      environment: {
        title: "Type de culture",
        setupMethods: {
          containerField: { text: "Surface au sol du conteneur (extérieur)" },
          greenhouse: { text: "Serre" },
          openSoil: { text: "Culture extérieur" },
          polytunnel: { text: "Tunnel plastique" },
          other: { text: "Autre" },
        },
        type: { title: "A l'extérieur ou à l'intérieur" },
        notes: {
          title: "Notes complémentaires sur les conditions de culture",
          placeholder: "Que doivent savoir les conseillers ?",
        },
        challenges: {
          title: "Défis/points d'attention",
          placeholder:
            "Par exemple, la 'lutte biologique' ou la 'réduction de la consommation d'eau'",
        },
        certification: {
          title: "Certifications",
          placeholder: "Par exemple, 'MPS A+'",
        },
        buttonText: "Sauvegarder",
      },
    },
    cropFields: {
      title: "Optional crop fields",
    },
    pdfFields: {
      title: "Optional pdf fields",
    },
    cultivationActivities: { title: "Sélectionner vos activités" },
    measurementTypes: {
      title: "Sélectionner le type de mesures à afficher pour les notes",
    },
    rating: { title: "Select rating items in note form" },
    noteLabels: { title: "Select custom note labels in note form" },
    photoLabels: { title: "Select custom photo labels in note form" },
    wateringUnits: { title: "Select default watering unit in note form" },
    cropLabels: {
      fields: "Sélectionner la culture",
      sizes: {
        select: "Sélectionner la taille de la culture",
        large: "Haute/Large",
        small: "Basse",
      },
    },
    settings: {
      title: "Paramètres",
      weatherStats: {
        title: "Masquer les données climatiques",
        placeholder: "",
        options: {
          tavgAvg: "tmoyAvg",
          tminAvg: "tminAvg",
          tmaxAvg: "tmaxAvg",
          humAvg: "humAvg",
          irrSum: "irrSum",
          leafSum: "leafSum",
          rainSum: "rainSum",
        },
      },
      notificationSettings: {
        title: "Paramètres de notifications",
        sensorAlerts: "Alertes des capteurs",
        taskAlerts: "Rappels de tâches",
        dailySummaryEmails: "E-mail de suivi quotidien",
        endOfCultivationAlertsEmails: "Fin des alertes mails sur les cultures",
      },
    },
    savedSuccessfully: "Profil mis à jour!",
    unsubscribedSuccessfully: "Unsubscribed from daily alerts.",
    wateringItems: {
      title: "Champs inclus dans le type de note Arrosage + EC + Drainage",
    },
  },
  sensors: {
    title: "Capteurs",
    config: "Configuration des capteurs",
    loading: "Chargement des capteurs",
    selectSensors: "Sélectionner des capteurss",
    selectAll: "Tout sélectionner",
    backToAll: "Retour à la liste",
    pickYourSensors: "Choisissez votre capteur",
    sensorId: "id",
    externalId: "id externe",
    allSensors: "Tous les capteurs",
    noSensors: "Pas de capteur enregistré",
    noConnectedCrops: "Pas de culture connectée",
    loadingMeasurements: "Loading measurements",
    noMeasurements: "No measurements",
    editSensor: {
      title: "Editer les paramètre du capteur",
      connectedCrops: {
        title: "Culture",
        add: "ajouter",
        edit: "éditer",
        remove: "supprimer",
        addEdit: "Ajouter/éditer une culture connectée à un capteur",
        crop: "Culture",
        startEndDate: "Select start / end date",
        noStartDate: "Pas de date de départ",
        noEndDate: "Pas de date de fin",
      },
      dataYAxisBounds: {
        label: "Limites des données de l'axe y",
        options: {
          default: "Limites par défaut",
          boundByValues: "Limites mesurées",
        },
      },
      highSoilMoisture: "Humidité du sol élevée",
      highTemperature: "Température élevée",
      lowSoilMoisture: "Humidité du sol faible",
      lowTemperature: "Température basse",
      receiveAlerts: "Recevoir des alertes",
      button: "Sauvegarder",
    },
    timeRange: {
      title: "Plage de temps",
      placeholder: "Sélectionner une plage",
      options: {
        lastDay: "Hier",
        lastWeek: "Semaine dernière",
        lastMonth: "Mois dernier",
        lastYear: "Last year",
      },
    },
    settings: "Paramètres",
    noSensorMessage:
      "Il est possible de connecter des sondes à votre tableau de bord personnel Log & Solve. Deux types de sondes peuvent être connectées actuellement. Elles sont placées directement dans le pot pour mesurer divers paramètres. Ces deux types de sondes sont décrites ci-après ;",
    sensorOffer1:
      "Mesurez le taux d'humidité et la température dans le pot 4 fois par heure.",
    sensorOffer2:
      "Mesurez la teneur en humidité et la température dans le pot - et l'humidité de l'air, l'intensité de la lumière et la température de l'air juste au-dessus du pot 12 fois par heure.",
    sensorOfferContact:
      "Le choix du type de sonde dépend de vos besoins, du type de culture et du système de culture. Pour plus d'informations, vous pouvez contacter Co-Anne van Waaij via co-anne.vanwaaij@klasmann-deilmann.com ou au +31 6 13 53 50 54.",
    sensorOfferDisclaimer:
      "Remarque : à ce stade, les données de votre ordinateur climatique ne peuvent pas encore être connectées à Log & Solve.",
  },
  locations: {
    title: "Emplacements",
    config: "Configuration des emplacements",
    businessLocations: "Lieu de l'entreprise",
    locations: "Emplacements",
    location: "Emplacement",
    slevels: [
      "Lieu de l'entreprise",
      "Emplacement niveau 1",
      "Emplacement niveau 2",
    ],
    mlevels: [
      "BLieux de l'entreprise",
      "Emplacements niveau 1",
      "Emplacements niveau 2",
    ],
    divideSurface: "Diviser la surface de manière égale",
    surfaceArea: "Surface",
    addNew: "Ajouter un sous-emplacement",
    edit: "Editer",
    remove: "Supprimer",
    saveAdd: "Sauvegarder + Créer nouveau",
    save: "Sauvegarder",
    cancel: "Annuler",
    onlySameBusinessLocs:
      "You can't change location for crops with different business locations.",
    errorSavingLocations:
      "Une erreur est survenue lors de la mise à jour du lieu de l'entreprise !",
  },
  cropsQr: {
    title: "QR code de la culture",
    urlType: {
      label: "URL",
      options: [
        { id: "noteForm", name: "Note", docLabel: "note-photo" },
        { id: "cropLog", name: "Journal de la culture", docLabel: "Journal" },
        { id: "cropProfile", name: "Profil de la culture", docLabel: "Profil" },
      ],
    },
    week: "Semaine",
    objectcode: "Objet",
    ends: "Fin",
    start: "Début",
    end: "Fin",
    wk: "sem",
    cropLabel: "Culture",
  },
  locationsQr: {
    title: "QR code de l'emplacement",
    cropsAtLocations: "Note par culture et par emplacement",
    notitie: "Note",
    foto: "photo",
  },
  exports: {
    title: "Exports",
    cropControl: {
      fileType: "Type de fichier",
      export: "Export",
      typeOptions: {
        cropProtection: "Protection des cultures",
        fertilization: "Fertilisation",
        cultivation: "Cultivation",
      },
      button: "Export protection des cultures",
    },
  },
  exporters: {
    exportPdf: "To PDF",
    exportXlsx: "To Excel",
    exportCsv: "To CSV",
  },
  assesment: { title: "Évaluation {nom}" },
  weather: {
    title: "Données météorologiques de référence",
    tavgAvg: "Temperature",
    tminAvg: "Temperature min",
    tmaxAvg: "Temperature max",
    humAvg: "Humidité",
    irrSum: "Rayonnement",
    rainSum: "Pluie",
    leafSum: "Feuille",
  },
  signUp: {
    title: "S'inscrire",
    errors: {
      emailInUse: "Adresse e-mail déjà utilisée.",
      passwordWeak: "Le mot de passe doit contenir au moins 6 caractères",
    },
  },
  signIn: {
    title: "S'identifier",
    generalErrors: {
      tooManyRequests: "Trop de tentatives de connexion en peu de temps.",
      networkRequestFailed: "Pas de connexion internet.",
      default: "Une erreur inconnue s'est produite: {0}",
    },
    form: {
      emailAddress: {
        text: "Adresse électronique",
        errors: {
          invalidEmailAddress: "Adresse électronique invalide.",
          userEmailNotFound:
            "Aucun compte trouvé avec cette adresse électronique.",
          userDisabled: "Utilisateur actuellement désactivé.",
        },
      },
      password: { text: "Mot de passe", error: "Mot de passe incorrect." },
      button: "S'identifier",
      linkText: "Vous avez oublié votre mot de passe ?",
      noAccountText: "Si vous n'avez pas encore de compte, créez-en un ici",
    },
  },
  signedInRedirect: { text: "Connexion..." },
  signOut: { title: "Déconnexion réussie", linkText: "Se reconnecter ?" },
  disclaimer: {
    titleOne: "Responsabilité",
    textOne:
      "Nous déclinons toute responsabilité quant à la mise à jour, l'exactitude et l'exhaustivité des évaluations et recommandations de fertilisation fournies. Les demandes de responsabilité à notre encontre concernant les dommages matériels ou les dommages consécutifs causés par le suivi ou le non suivi des recommandations de fertilisation fournies ou par le suivi de recommandations incorrectes et incomplètes sont en principe exclues, sauf s'il existe des preuves manifestes d'un comportement intentionnel ou d'une négligence grave. Cette exclusion de responsabilité ne s'applique pas aux dommages corporels ou à la responsabilité pour des actes illicites. Nous nous réservons expressément le droit de modifier, de compléter ou de supprimer tout ou partie de Log & Solve sans préavis ou d'en arrêter l'utilisation temporairement ou définitivement.",
    titleTwo: "Interprétation des données de culture",
    textTwo:
      "L'évaluation automatique des résultats d'analyse dans Log & Solve est spécifique à chaque culture. L'évaluation est basée sur le système néerlandais de recommandation de fertilisation 'Bemesting Advies Basis', complété par l'expérience et les résultats de recherche de l'université de Wageningen*.  À des fins de conseil, les cultures sont classées en fonction de leurs besoins en nutriments, de leur sensibilité au sel et de leur tolérance au pH. Ce système de recommandation pour la fertilisation est un guide détaillé qui permet d'évaluer les résultats d'analyse des cultures de manière spécifique et de fournir les conseils les plus précis possibles en matière de fertilisation. L'interprétation et la préparation d'un avis de fertilisation doivent tenir compte de l'état de la culture et de la fertilisation effectuée avant l'échantillonnage. Il est également possible, d'années en années, de développer vos propres valeurs de référence spécifiques à la culture au sein de Log & Solve. Par conséquent, les valeurs cibles et limites  doivent toujours être considérées comme des lignes directrices générales. \n \n * Lors de l'évaluation de l'analyse, les résultats sont convertis en une valeur de conductivité standard, qui est établie par culture. Cela permet une conversion des résultats d'analyse proportionnelle à la conductivité et une comparaison dans le temps.",
  },
  overview: {
    title: "Liste des producteurs",
    searchGrower: "Chercher un producteur",
  },
  team: {
    intro:
      "Log & Solve est un outil numérique développé et géré par l'équipe Innovation de Klasmann-Deilmann. Rencontrez l'équipe de Log & Solve :",
    members: [
      {
        name: "Sjors Beijer",
        portrait: "sjors.jpg",
        position: "Responsable de l'innovation Klasmann-Deilmann",
        description:
          "Sjors a rejoint Klasmann-Deilmann en juin 2016 en tant que responsable de l'innovation. Il a été l'un des initiateurs de Log & Solve. 'La tendance à une utilisation plus intelligente des ressources naturelles et à une culture plus durable avec moins d'intrants chimiques exige des adaptations dans la stratégie de culture. La surveillance des cultures est une partie essentielle de cette adaptation. Elle permet également aux producteurs de mieux préserver les connaissances spécifiques en matière de culture, d'optimiser leur stratégie de production et de conserver les connaissances au sein de l'entreprise. À mon avis, les services numériques peuvent apporter une grande valeur ajoutée aux producteurs de plantes en pot car ils permettent de résoudre les problèmes de manière proactive et de réduire les pertes en culture. Log & Solve a été conçu pour - mais surtout en collaboration avec les producteurs'.",
      },
      {
        name: "Co-Anne van Waaij",
        portrait: "co-anne.jpg",
        position: "Développeuse Log & Solve",
        description:
          "Co-Anne est l'un des talents enthousiastes et innovants de Klasmann-Deilmann. Co-Anne a rejoint la société en 2018 et est activement impliquée en tant qu'interlocutrice pour Log & Solve. Co-Anne travaille avec les producteurs pour trouver la meilleure adéquation entre Log & Solve et leurs quotidien dans la gestion des cultures. 'J'aime travailler avec les producteurs pour faire correspondre leurs besoins pratiques avec l'outil Log & Solve, afin qu'il apporte une valeur ajoutée à leur stratégie culturale. Dans le processus de développement, nous avons préféré travailler en collaboration avec les producteurs plutôt que de créer un outil puis le lancer sur le marché. Je crois en cette méthode de travail, appelée co-création'.",
      },
      {
        name: "Gerard Wapenaar",
        portrait: "gerard.jpg",
        position: "Conseiller technique Log & Solve",
        description:
          "Gérard a travaillé pendant de nombreuses années dans le secteur des substrats et de l'horticulture. Chez Klasmann-Deilmann, en tant que chef de produit, Gerard considère Log & Solve comme un outil puissant pour les producteurs, qui permet de porter la gestion quotidienne des cultures à un niveau supérieur. 'Agir à temps - sur la base des données stockées, par exemple des années précédentes ou dans des conditions similaires - pourrait éventuellement améliorer les performances de la culture. Je proppose des évaluations des analyses de substrat, de solutions nutritives et d'échantillons d'eau entrées dans Log & Solve et ainsi je peux vous accompagner dans la gestion de la fertirrigation'.",
      },
      {
        name: "Hermann Konnemann",
        portrait: "hermann.jpg",
        position: "Conseiller technique Log & Solve",
        description:
          "Hermann travaille pour Klasmann-Deilmann en tant que conseiller technique depuis 2007, après avoir été impliqué en tant qu'ingénieur de recherche dans une station de recherche pour la science des sols et la nutrition des plantes pendant plusieurs années. Sur la base de ses activités de conseil auprès des agriculteurs d'Europe et d'Extrême-Orient, il voit les avantages de Log & Solve dans le fait que le conseil sur les cultures peut être effectué plus rapidement et de manière plus fiable. 'En tant que consultant, je peux maintenant utiliser Log & Solve pour comparer directement les résultats d'analyse avec des images du stade de la culture et consulter des notes supplémentaires et des données de capteurs sur la gestion des cultures. Cela permettra d'améliorer la qualité de la consultation'.",
      },
      {
        name: "Balie Nasution",
        portrait: "balie.jpg",
        position: "Propriétaire et administrateur du produit Log & Solve",
        description:
          "Balie a rejoint Klasmann-Deilmann en tant que stagiaire international à l'été 2019. Grâce à sa formation en sciences du sol et en sciences végétales, ainsi qu'à ses intérêts et compétences en matière de développement web et de gestion des données, il s'intègre parfaitement dans l'équipe de Log & Solve. 'À l'avenir, le travail sera de plus en plus axé sur les données. Il contribuera à accroître la transparence des chaînes d'approvisionnement et à prendre des décisions sur la base de données plutôt que sur la 'main verte'. Les services numériques comme Log & Solve offrent des outils permettant d'intégrer les données du passé et du présent, de sorte qu'au final, les producteurs puissent améliorer leur efficacité de production'.",
      },
      {
        name: "Sören Houwing",
        portrait: "soren.jpg",
        position: "Coordinateur des marchés internationaux Log & Solve",
        description:
          "Depuis l'automne 2016, Sören renforce l'équipe de coordination des ventes techniques chez Klasmann-Deilmann. Grâce à un apprentissage de jardinier et à des études ultérieures en horticulture à l'Université des sciences appliquées d'Osnabrück, Sören a lui-même acquis une grande expérience pratique dans le domaine de l'horticulture. Son affinité pour les processus assistés par les technologies de l'information l'a inspiré pour le service numérique Log & Solve : 'Pendant mon apprentissage et mon travail en production, j'ai dû enregistrer beaucoup de données sous forme analogique et j'aurais été heureux d'avoir un outil numérique comme Log & Solve. Je suis donc très heureux de soutenir le développement de Log & Solve pour rendre les processus de travail plus faciles, plus rapides et plus efficaces pour les producteurs'.",
      },
    ],
  },
  cropsList: [
    { text: "Notifications" },
    { text: "Ajouter" },
    { text: "Ajouté à" },
    { text: "Tous" },
  ],
  cropsListPage: {},
  tableComponent: {
    icon: "Icone",
    showColumns: "Sélectionnez les colonnes",
    noColumns: "Sélectionnez les colonnes à afficher",
    alerts: "Mises à jour",
    progress: "Le progrès",
    reset: "Réinitialiser",
    label: "Étiquette",
    date: "Date",
    weekNr: "Numéro de semaine",
    potDate: "Date du rempotage",
    potSize: "Taille du pot",
    tray: "Tray",
    trayCellsAmount: "Number of cells per tray",
    traysAmount: "Number of trays",
    plugType: "Plug type",
    growcoon: "Growcoon type",
    openField: "Open field",
    startingNumberOfPots: "Start number",
    currentNumberOfPots: "Current number",
    genus: "Genre",
    genusName: "Genus",
    speciesName: "Species",
    cultivarName: "Cultivar",
    labDataId: "Identifiant labo",
    barcode: "Barcode",
    batchNumber: "Numéro de lot",
    youngPlantSuppliers: "Fournisseurs de jeunes plants",
    bulbSize: "Bulb size",
    personPlanted: "Person who planted",
    isTrialCrop: "Afficher uniquement les essais",
    species: "Espèces",
    cultivar: "Cultivar",
    location: "Lieu",
    businessLocation: "Emplacement de l'entreprise",
    locationLevel1: "Emplacement niveau 1",
    locationLevel2: "Emplacement niveau 2",
    variety: "Variété",
    measure: "Mesures",
    sort: "Trier",
    show_location: "Montrer l'emplacement",
    status: "Montrer les inactifs",
    search_placeholder: "Rechercher des cultures ...",
    select: { all: "Tous", month: "Month", week: "Week" },
    next: "Suivant",
    prev: "Précédent",
    noData: "Pas de données :(",
    locationToggle: "Afficher l'emplacement",
    labDataIdToggle: "Afficher l'identifiant labo",
    alert: {
      "new-note": "NOTE",
      "new-photo": "PHOTO",
      "lab-data": "DONNEES LABO",
      "lab-data-comment": "LAB DATA COMMENT",
      "lab-data-pending": "PENDING LAB DATA",
      "new-task-due": "TASK DUE",
      protocol: "WATCH OUT FOR",
      "new-advice": "ADVICE",
      "new-sensor-trigger": "SENSOR TRIGGER",
      "new-note-mention": "NOTE MENTION",
    },
    actions: {
      generateQrCodes: "Generer les QR codes",
      duplicateCrops: "Dupliquer",
    },
    advanced: {
      label: "Advanced",
      title: "Advanced crop filters",
      add: "Add",
      update: "Update",
      execute: "Execute",
      clear: "Clear",
    },
    view: "View",
    cropLabel: "Crop label",
  },
  notificationLis: { new: "Nouvelle" },
  addCrop: {
    phase: "Phase de culture",
    duplicateCrop: "Dupliquer la culture",
    selectCrop: "Sélectionner la culture",
    classification: "Classification",
    referenceGenus: "Genre de référence",
    noteDate: "Date",
    duplicateForm: "Dupliquer de",
    cropType: "Nom de la culture",
    genericName: "Espèce référente",
    choose: "Choisir",
    specificEpithet: "Espèce",
    cultivar: "Variété",
    chooseYourName: "Choisissez votre nom…",
    or: "Ou",
    details: "Détails",
    potSize: "Taille du pot",
    startingNumberOfPots: "Start number",
    currentNumberOfPots: "Current number",
    numberOfCellsPerTray: "Number of cells per tray",
    numberOfTrays: "Number of trays",
    plugType: "Plug type",
    growcoonType: "Growcoon type",
    drippers: "Drippers",
    startingAmount: "Starting amount",
    currentAmount: "Current amount",
    batchNumber: "Numéro de lot",
    bulbSize: { title: "Bulb size", placeholder: "Bulb size" },
    personPlanted: {
      title: "Person who planted",
      placeholder: "Person who planted",
    },
    number: "Nombre",
    cultivationTime: "Temps de culture",
    dateOfPotting: "Date du rempotage",
    endDate: "Date de fin de culture",
    expectedCultivationTime: "Temps de culture prévu (en semaines)",
    numberOfWeeks: "Nombre de semaines",
    trialCrop: "Essai de culture",
    submit: "Soumettre",
    delete: "Delete",
    confirmDelete: "Are you sure you want to delete this crop?",
    search: "Chercher",
    next: "Suivant",
    back: "Retour",
    cropAddedSuccessfully: "Nouvelle culture ajoutée!",
    cropEditedSuccessfully: "Crop edited successfully!",
    cropDeletedSuccessfully: "Crop deleted successfully!",
    edit: "Éditer culture",
    weekInYear: "Numéro de semaine",
    description: "La description",
    descPlaceholder: "Description des cultures..",
    labDataId: "Identifiant labo",
    barcode: "Code-barre",
    labIdNotUnique: "Cet identifiant de laboratoire existe déjà",
    statusActive: "Actif",
    statusInactive: "Inactif",
    recipe: "Recette",
    productionDate: "Date de livraison",
    orderNo: "Numéro de commande",
    targetPh: "pH demandé",
    targetEc: "EC demandée",
    substrateMixture: "Mélange",
    recipeDescription: "Description",
    addSubstrateInfo: "Ajouter des infos sur le substrat",
    loadingSubstrateOrder: "Charger les infos sur la commande substrat...",
    noSubstrateOrder:
      "Il n'y a pas d'information de commande pour l'id ou le contact actuel",
    protocol: "Protocol",
    fertilization: "Fertilization",
    containerOptions: [
      { id: "pot", label: "Pot" },
      { id: "tray", label: "Tray" },
      { id: "open", label: "Open field" },
    ],
    plugTypeOptions: [
      { id: "loose", name: "Loose filled trays" },
      { id: "growcoon", name: "Growcoon" },
      { id: "paper", name: "Paper Pot" },
      { id: "glued", name: "Glued plugs" },
      { id: "rockwool", name: "Rockwool" },
      { id: "oasis", name: "Oasis" },
    ],
    trayName: "Tray name",
    cropLabelName: "Label name",
    cropLabel: "Crop label",
    cropLabels: ["Cultivation trial", "Main crop"],
    objectives: "Objectives and challenges for this crop",
    spacingDate: "Arrival date of young plant material",
    container: "Container",
    businessLocation: "Business location",
    labId: "Lab ID",
    openField: "Open field",
    tray: "Tray",
  },
  inputComponent: {
    loading: "Récupération des données..",
    search_placeholder: "Tapez quelque chose s'il vous plaît...",
  },
  noteChecklists: {
    title: "Liste",
    createNew: "Nouvelle liste d'actions",
    createEdit: "Créer ou modifier une liste d'actions",
    name: "Nom de la liste d'actions",
    selectBranch: "Sélectionner une section",
    displayIn: "Display in",
    customLabel: "Custom label",
    save: "Sauvegarder la liste d'actions",
    noChecklists: "Rien à voir ici",
    selectChecklist: "Sélectionner une liste d'actions",
  },
  tankMixes: {
    title: "Mélange en cuve pour la protection des cultures",
    createNew: "Créer",
    createEdit: "Créer ou modifier un mélange en cuve",
    name: "Nom de la cuve de mélange",
    type: "Type de mélange en cuve",
    save: "Sauvegarder le mélange",
    newMix: "Nouveau mélange",
    predefinedMix: "Predefined mix",
    emptyProduct: "Produit vide",
    noTankMixes: "Rien à voir ici",
    selectTankMix: "Sélectionner une cuve de mélange",
    selectPredefinedMix: "Choisir un mélange pré-défini",
  },
  documents: {
    platform: "Documents de la plate-forme",
    company: "Docuemnts de l'entreprise",
    crop: "Documents de la culture",
    categories: [
      { id: "third-party", label: "Invités" },
      { id: "log-and-solve", label: "Log & Solve" },
      { id: "documentation", label: "Documentation" },
    ],
    showAll: "Afficher tout",
    add: "Ajouter",
    edit: "Editer",
    document: "document",
    title: "Titre",
    category: "Categorie",
    type: "Type",
    shareWith: "Partager avec",
    allCompanies: "Toutes les entreprises",
    allIn: "Tous",
    file: "Fichier",
    link: "Lien",
    submit: "Soumettre",
    noEntries: "Rien à voir ici",
    crops: "cultures",
    description: "Description",
  },
  advices: {
    title: "Advices",
    advice: "advice",
    description: "Description",
    viewAttachment: "View attachment",
  },
  generalNotes: {
    title: "Notes",
    note: "note",
    selectLocation: {
      label: "Sélectionner un emplacement",
      placeholder: "Sélectionner un emplacement",
    },
    description: "Description",
    viewAttachment: "View attachment",
    includeInCrops: "Include in crops",
  },
  climateData: {
    title: "Données climatiques",
    paramTypes: [
      { id: "set", name: "Définir" },
      { id: "achieved", name: "Complété" },
    ],
    parameters: {
      temperature: "Temperature",
      light: "Lumière",
      airh: "Humidité de l'air",
      co2: "CO2",
      radiation: "Radiation lumineuse",
      "ventilation-a": "Ventilation A",
      "ventilation-b": "Ventilation B",
      shading: "Ombrage",
      substrate_temperature: "Substrate temperature",
    },
    timeframes: {
      "24h": "24h",
      day: "jour",
      night: "nuit",
      agendaWeekNr: "Numéro de semaine",
      "24h-avg": "24h-moy",
      week: "semaine",
    },
    units: {
      c: "℃",
      f: "f",
      "j/cm2-som": "j/cm2-som",
      "mol/m2": "mol/m2",
      "%": "%",
      av: "av",
      vd: "vd",
      ppm: "ppm",
      "wind m/s": "vent m/s",
      radiation: "radiation lumineuse",
      airh: "airh",
      "w/m2": "w/m2",
      "umol/m2/s": "umol/m2/s",
      daylength: "longueurjour",
      h: "h",
      "kg/ha": "kg/ha",
    },
    wizard: {
      title: "Enregistrer une nouvelle entrée",
      manage: "Gérer les entrées",
      selectLocations: "Sélectionner un emplacement",
    },
    settings: {
      title: "Paramètres",
      manage: "Gérer les paramètres",
      settingsUpdated: "Les paramètres ont été mis-à-jour",
    },
    week: "Semaine",
    save: "Sauvegarder",
    getStarted:
      "Créer de nouveaux paramètres de gestion climatique pour démarrer",
    createSettings: "Créer",
  },
  fertilizationBins: {
    title: "Fertilisation",
    wizard: {
      title: "Enregistrer une nouvelle entrée",
      manage: "Gérer les entrées",
      pickBin: "Choisir un bac",
    },
    settings: {
      title: "Paramètres",
      manage: "Gérer les paramètres",
      createNew: "Créer une nouvelle entrée",
      createEdit: "Créer ou modifier un bac de fertilisation",
      selectLocations: "Sélectionner un emplacement",
      noBins: "Pas de bac de fertilisation",
      settingsUpdated: "Les paramètres ont été mis-à-jour",
    },
    name: "Nom du bac de fertilisation",
    type: "Type de bac de fertilisation",
    concentration: "Concentration",
    bin: "Bac",
  },
  pendingLabAnalysis: {
    title: "Pending lab analyses",
    submit: "Submit",
    noEntries: "Nothing to see here",
    completed: "{nr} lab analysis entries have been applied",
  },
  cropsImport: {
    title: "Importer une culture",
    menu: { wizard: "Assistance", settings: "Paramètres" },
    inputFormats: {
      voorbeeld: "Wintree TSD",
      wouters: "Mprise agriware",
      voorraadpaartij: "GroenVision",
    },
    getStarted:
      "Créer de nouveaux paramètres d'import des cultures pour démarrer",
    createSettings: "Créer",
    selectFile: "Sélectionner un fichier d'import Excel",
    importFile: "Fichier d'import",
    reset: "Réinitialiser",
    progress: {
      created: "Créer",
      updated: "Mettre à jour",
      skipped: "Suivant",
      failed: "Echec",
    },
    execute: "Exécuter",
    completed: "L'import de la culture est complété",
    validationError: "Veuillez vérifier les entrées et réessayer",
    settings: {
      familyCodes: "Code famille",
      familyCode: "Code famille",
      locationCodes: "Code emplacement",
      locationCode: "Code emplacement",
      potsizeCodes: "Code contenant",
      fieldsMaps: "Cartes des champs",
      dedicatedLocCols: "Dedicated location columns",
      save: "Sauvegarder",
      settingsUpdated: "Paramètres d'import des cultures mis à jour",
    },
  },
  tableHeaders: {
    barcode: "Code-barre",
    genus: "Genres",
    species: "Espèces",
    cultivar: "Cultivar",
    ownName: "Nom propre",
    container: "Container",
    potSize: "Taille de pot",
    tray: "Tray",
    plugType: "Plug type",
    growcoon: "Growcoon",
    cellsPerTray: "Number of cells per tray",
    trays: "Number of trays",
    startingAmount: "Starting amount",
    currentAmount: "Current amount",
    batchNo: "N° de lot",
    youngPlantSupplier: "Fournisseur de jeunes plants",
    locationCode: "Code emplacement",
    businessLocation: "Emplacement de l'entreprise",
    level1Location: "Emplacement de niveau 1",
    level2Location: "Emplacement de niveau 2",
    weekNumber: "Numéro de semaine",
    pottingStart: "Début du rempotage",
    pottingEnd: "Fin du rempotage",
    expectedCultivationTime: "Expected cultivation time (in weeks)",
    fertilization: "Fertilisation",
    substrateMixture: "Support de culture",
    description: "Description",
    sizeDescription: "Size description",
    supplierDocumentNr: "Supplier document nr.",
    containerNr: "Container number",
    protocol: "Protocol",
    label: "Label",
    labelName: "Label name",
    phase: "Phase",
    objectives: "Objectives",
    spacingDate: "Arrival date of young plant material",
    bulbSize: "Bulb size",
    person: "Person",
    drippers: "Drippers",
  },
  cropActions: {
    duplicateCrops: {
      title: "Dupliquer la culture",
      progress: {
        created: "Créer",
        updated: "Mettre à jour",
        skipped: "Suivant",
        failed: "Echec",
      },
      execute: "Executer",
      proceed: "Continuer",
      completed: "{count} de {total} culture ont été dupliquées avec succès",
      validationError: "Veuillez vérifier les entrées et réessayer",
    },
  },
  cropForm: {
    selected: "Sélectionner {nr} cultures",
    selectLocation: "Sélectionner un emplacement",
    selectYourLocation: "Sélectionner votre emplacement",
    cropsSelected: "Total de {nr} cultures sélectionnées",
    noActiveCrops: "Actuellement aucune culture active à cet emplacement",
    selectCrops: "Sélectionner des cultures",
    crops: "Cultures",
    locations: "Emplacements",
    selectedLocations: "Sélectionner des emplacements",
    search: "Chercher",
    selectAll: "Sélectionner tout",
    showMore: "Voir plus",
    stepNo: "Etape {cs} sur {ms}",
    labData: "Données laboratoire",
    climateData: "Données climatiques",
    fertilizationBins: "FBacs de fertilisation",
    advice: "Advice",
    "general-note": "General note",
    checklist: "Checklist",
    fertilizationEc: "EC d'arrosage",
    watering: "Arrosage",
    ecWateringDrainage: "Arrosage + EC + Drainage",
    drainage: "Drainage",
    measurementEC: "EC-mesurée",
    measurementpH: "pH mesuré",
    EC: "EC",
    "fertilization-Water": "Irrigation",
    amountSpreadable: "Quantité",
    concentrationSpreadable: "Concentration",
    "fertilization-fertilization": "Fertilisation foliaire",
    amountAgent: "Quantité",
    choose: "Choisir",
    cause: "La raison de l'application",
    or: "Ou",
    andOr: "Et/Ou",
    selectedCrop: "Culture sélectionnée",
    change: "Changement",
    completed: "Terminé",
    qrCode: "Scannez le code QR",
    start: "Début",
    save: "Sauvegarder",
    saveAddNew: "Sauvegarder + ajouter nouveau",
    ok: "Ok",
    cancel: "Annuler",
    pick: "Choisir",
    measurement: "La mesure",
    substrate: "Substrat",
    drain: "Drainage",
    gift: "Absorbé",
    ditch: "Lessivé",
    observation: "Observation",
    waterAndFertilization: "Eau et fertilisation",
    logTask: "Enregistrement de:",
    cultivationActivity: "Activité de culture",
    units: "Units",
    addPhoto: {
      label: "Ajouter une photo",
      overzicht: "Aperçu",
      enkelePlant: "Plante unique",
      detail: "Détail",
      wortels: "Racines",
    },
    photoType: "Type de photo",
    category: "Catégorie",
    whatMeasurement: "Quelle mesure souhaitez-vous enregistrer?",
    addPH: "Ajouter une mesure de pH",
    addLength: "Ajouter une longueur en cm",
    addDiameter: "Ajouter un diamètre en cm",
    numberOfBuds: "Nombre de boutons/fleurs",
    weight: "Poids",
    yield: "Yield",
    addBuds: "Ajouter un nombre de boutons/fleurs",
    addWeight: "Ajouter un poids",
    addYield: "Add yield",
    diameter: "Diamètre",
    whatObservation: "Quelle observation souhaitez-vous enregistrer?",
    defectPhenomenon: {
      label: "Observation de défauts",
      options: {
        bladvlekkenGeel: "Tâches foliaires - jaune",
        bladvlekkenBruin: "Tâches foliaires - marron",
        bladrandGeel: "Bordure des feuilles - jaune",
        BladrandBruin: "Bordure des feuilles - marron",
        krullendBlad: "Feuilles frisées",
        bladval: "Chute de feuilles",
        knopval: "Chute de bourgeons",
      },
    },
    leafObservation: "Leaf observation",
    defectPhenomenonIn: "Observation de défauts sur:",
    youngLeaves: "Jeunes feuilles",
    oldLeaves: "Vieilles feuilles",
    leafBurn: "Brûlure des feuilles",
    flowering: "Floraison",
    fungus: {
      label: "Champignon et maladie bactérienne",
      options: {
        altenaria: "Altenaria",
        bladvlekkenziekte: "Tâches foliaires",
        bloemrot: "Pourriture des fleurs",
        botrytis: "Botrytis",
        didymella: "Didymella",
        fusarium: "Fusarium",
        japanseRoest: "Rouille japonaise",
        echteMeeldauw: "Mildiou poudreux",
        valseMeeldauw: "Mildiou",
        phytophthora: "Phytophthora",
        pythium: "Pythium (pourriture des racines)",
        roetdauw: "Rosée fuligineuse",
        sclerotinia: "Sclerotinia",
        stengelbasisrot: "Pourriture de la base de la tige",
        verticillium: "Verticillium",
        zwarteVlekkenziekte: "Maladie des points noirs",
        nofungus: "No fungus and bacterial disease",
      },
    },
    plague: {
      label: "Ravageur",
      options: {
        aaltjes: "Nématodes",
        bladluizen: "Pucerons",
        cicaden: "Cigales",
        kevers: "Coléoptères",
        mineervliegen: "Mineuses des feuilles",
        muggenVliegen: "Moustiques et mouches",
        rupsen: "Chenilles, papillons de nuit et papillons",
        mijten: "(Araignée) acariens",
        trips: "Thrips",
        wantsen: "Insectes",
        mole: "Taupes",
        otherInsects: "Insectes autres",
        mice: "Souris",
        symphyla: "Symphyles mille-pattes",
        weeds: "Mauvaises herbes",
        slugs: "Escargots",
        springtail: "Collemboles",
        whiteFly: "Mouche blanche",
        wolluizen: "Cochenilles",
        dopluizen: "Dopluizen",
        schildluizen: "Schildluizen",
        noplagues: "No pests detected",
      },
    },
    color: "Couleur",
    failure: "Échec",
    rating: {
      label: "Rating",
      options: {
        germRate: "Germ rate",
        leafColor: "Leaf color",
        leafGloss: "Leaf gloss",
        shootFormation: "Shoot formation",
        flowerSize: "Flower size",
        flowerFirmness: "Flower firmness",
        floridity: "Floridity",
        rooting: "Rooting",
        uniformity: "Uniformity",
        moisture: "Moisture",
        branching: "Branching",
        plantFirmness: "Plant firmness",
        plantStructure: "Plant structure",
        compactness: "Compactness",
        cutlings: "Cutlings",
        growth: "Croissance",
      },
    },
    intensity: {
      label: "Intensity",
      levels: [
        {
          value: 1,
          label: "Light",
        },
        {
          value: 2,
          label: "Some",
        },
        {
          value: 3,
          label: "Medium",
        },
        {
          value: 4,
          label: "A lot",
        },
        {
          value: 5,
          label: "Heavy",
        },
      ],
    },
    LeafTips: "Bords des feuilles",
    selectDefect: "Sélectionnez un observation de défauts",
    selectFungi: "Sélectionnez un champignon/maladies bactériennes",
    selectPlague: "Sélectionnez un ravageur",
    addEC: "Quelle EC avez-vous donnée lors de l'arrosage?",
    addECMeasurement: "Ajouter une mesure d'Ec",
    addWaterMeasurement: "Combien de minutes avez-vous arrosé?",
    whichFertilizationCategory: "Quel type de fertilisation ?",
    cropControl: "Contrôle des cultures",
    whichCropControl: "Quel contrôle des cultures?",
    misc: "Divers",
    miscellaneous: "Divers",
    numberOfMeters: "Nombre de m²:",
    concentration: "Concentration ml / 100 litre:",
    photoUpload: "Téléchargement de photos",
    pruning: "Taille",
    budding: "Pincement",
    widening: "Élargissement",
    sorting: "Trier",
    moveCultivationBatch: "Déplacer un lot",
    changePotsize: "Rempoter",
    rotate: "Rotation",
    cutting: "Boutures",
    cuttingLeaf: "CutBouture de feuille",
    layFoil: "Lay foil",
    removeFoil: "Remove foil",
    harvest: "Récolte",
    lower: "Raccourcir",
    settle: "Régler",
    removeLeafs: "Effeuiller",
    bendIn: "Plier",
    placeSticks: "Tuteurer",
    siftingThrough: "Passer au crible",
    pollinate: "Polliniser",
    binding: "Lier",
    weeding: "Désherber",
    deflower: "Efleurer",
    removeTendrils: "Suppressimer des vrilles",
    placeCatchCards: "Placer des pièges englués",
    tendrils: "Vrilles",
    raiseTheGauze: "Enlever les bâches",
    cropMaintenance: "Entretien des cultures",
    removeBuds: "Enlever les bourgeons",
    potting: "Rempotage",
    deliverFinishedPlants: "Livrer",
    pottingUnit: "plugs/cuttings",
    deliverFinishedPlantsUnit: "plants",
    floridityUnit: "average amount of flowers",
    sow: "Truie",
    makeCuttings: "Faire des boutures",
    cuttingUnder: "Cutting under",
    cuttingRound: "Cutting round",
    technicalService: "Service technique",
    length: "Longueur",
    chemicalProtection: {
      label: "Protection chimique des cultures",
      options: {
        batavia: "Batavia",
        boxer: "Boxer",
        butisanS: "Butisan S",
        bonzi: "Bonzi",
        configuresc: "Configure Sc",
        collis: "Collis",
        cecece: "CeCeCe",
        conserve: "Conserve",
        AZ500: "AZ 500",
        abamectineHf: "Abamectine HF",
        centurionplus: "Centurion Plus",
        carex10sc: "Carex 10 SC",
        decis: "Decis",
        dithane: "Dithane NeoTec",
        dimanin: "Dimanin",
        floramite: "Floramite",
        flint: "Flint",
        gnatrolSc: "Gnatrol SC",
        karateZeon: "Karate zeon",
        limocide: "Limocide",
        lunaPrivilige: "Luna Privilege",
        mirageElan: "Mirage Elan",
        miragePlus: "Mirage plus",
        movento: "Movento",
        nissorun: "Nissorun",
        ortiva: "Ortiva",
        ortivaTop: "Ortiva Top",
        paraat: "Paraat",
        previcurEnergy: "Previcur Energy",
        proplant: "Proplant",
        ranman: "Ranman",
        rovral: "Rovral",
        serenade: "Serenade",
        scelta: "Scelta",
        sivantoPrime: "Silvanto Prime",
        siltac: "Siltac",
        signum: "Signum",
        steward: "Spuitzwavel Microsulfo",
        sumicidinSuper: "Sumicidin Super",
        switch: "Switch",
        teldor: "Teldor",
        teppeki: "Teppeki",
        tracer: "Tracer",
        xentarie: "Xentarie",
        vertimec: "Vertimec",
        azatin: "Azatin",
        biosweet: "Biosweet",
        bitterzout: "Bitterzout",
        contans: "Contans",
        dipel: "Dipel",
        dolokal: "Dolokal",
        kumulusS: "Kumulus S",
        kumar: "Kumar",
        microsulfo: "Spuitzwavel Micro Sulfo",
        nectarMagnwsiumDuo: "Nectar magnesium duo",
        neemazal: "NeemAzal ",
        suitzwavel: "Zwavel",
        trianium: "Trianum",
        aCSzwavel800: "ACS- zwavel 800",
        admiral: "Admiral",
        alibiflora: "Alibi flora",
        altacor: "Altacor",
        applaud: "Applaud",
        asperello: "Asperello",
        attracker: "Attracker",
        bifasto: "Bifasto",
        biosoap: "Biosoap",
        botanigardWP: "Botanigard WP",
        captosan500sc: "Captosan 500 sc",
        closer: "Closer",
        dazideenhance: "Dazide enhance",
        ecomist: "Eco mist",
        ethrelA: "Ethrel A",
        fado: "Fado",
        falgro: "Falgro",
        flipper: "Flipper",
        frupica: "Frupica",
        fungaflash: "Fungaflash",
        gazelle: "Gazelle",
        geoxe: "Geoxe",
        hasten: "Hasten",
        hfOnkruidVrij: "HF Onkruid vrij",
        hiCure: "Hi Cure",
        hiwett: "Hi wett",
        inssimo: "Inssimo",
        karma: "Karma",
        mainspring: "Mainspring",
        MCPA500: "MCPA 500",
        nocturn: "Nocturn",
        nomolt: "Nomolt",
        oberon: "Oberon",
        oroganic: "Oroganic",
        pedimentrookontwikkelaar: "Pediment rookontwikkelaar",
        pirimor: "Pirimor",
        pironpushbox: "Piron pushbox",
        prolong: "Prolong",
        ranmanTop: "Ranman Top",
        rhizopon: "Rhizopon",
        rocket: "Rocket",
        rodilinHavermix: "Rodilin Havermix",
        rodilonsoftblock: "Rodilon soft block",
        ridomilGold: "Ridomil Gold",
        rizolex: "Rizolex",
        runner: "Runner",
        saponin: "Saponin",
        score250EC: "Score250EC",
        silwetgold: "Silwet gold",
        sporgon: "Sporgon",
        sluxx: "Sluxx",
        sonata: "Sonata",
        suikerlokstof: "Suikerlokstof",
        tzwavel: "T- zwavel",
        taegro: "Taegro",
        takumi: "Takumi",
        tomCatRTU: "Tom Cat RTU",
        topaz: "Topaz",
        topsinmutra: "Topsin M utra",
        turexPowder: "Turex Poeder",
        vacciplant: "Vacciplant",
        valiosoSt: "Violoso-ST",
        vivando: "Vivando",
        abir: "Abir",
        mycotal: "Mycotal",
        motto: "Motto",
        meltatox: "Meltatox",
        winner: "Winner",
        fusiladeMax: "Fusilade Max",
        wingP: "Wing P",
        agralGold: "Agral Gold",
        goltixWG: "Goltix WG",
        roundupDynamic: "Roundup Dynamic",
        roundupEvolution: "Roundup Evolution",
        helioterpen: "Helioterpen",
        thripsLok: "ThripsLok",
        concreteHF: "Concrete HF",

        corzalSE: "Corzal SE",
        calciumShuttle: "Calcium Shuttle",
        basfoliar36Extra: "Basfoliar 36 Extra",
        calciumChoride: "Calcium Choride",

        ridemilGold: "Ridemil Gold",

        pergado: "Pergado",

        abemectineHF: "Abemectine HF",
        captosan500Sc: "Captosan 500 sc",

        fulvic: "Fulvic",
      },
    },
    addChemicalProtection: "Sélectionner un produit",
    addOrganicProtection: "Sélectionner un produit",
    organicProtection: {
      label: "Protection des cultures biologiques",
      options: {
        adaliaBipunctata: "Adalia bipunctata",
        amblydromalusLimonicus: "Amblydromalus limonicus",
        amblyseiusSwirskii: "Amblyseius swirskii",
        aphelinusAbdomalis: "Aphelinus abdomalis",
        aphidiusColemani: "Aphidius colemani",
        aphidiusErvi: "Aphidius ervi",
        aphidoletesAphidimyza: "Aphidoletes aphidimyza",
        azatin: "Azatin",
        biosweet: "Biosweet",
        bitterzout: "BitterSalt",
        chrysoperlaCarnea: "Chrysoperla carnea",
        contans: "Contans",
        dacnusaSibirica: "Dacnusa sibirica",
        diglyphusIsaea: "Diglyphus isaea",
        dipel: "Dipel",
        dolokal: "Dolokal",
        encarsiaFormosa: "Encarsia formosa",
        eretmocerusEremicus: "Eretmocerus eremicus",
        feltiellaAcarisuga: "Feltiella acarisuga",
        hypoaspisMiles: "Hypoaspis miles",
        kumulusS: "Kumulus S",
        macrochelesRobustulus: "Macrocheles robustulus",
        macrolophusPygameus: "Macrolophus pygameus",
        microsulfo: "Microsulfo",
        nectarMagnwsiumDuo: "Nectar magnesium duo",
        neemazal: "NeemAzal",
        neoseiulusCalifornicus: "Neoseiulus californicus",
        neoseiulusCucumeris: "Neoseiulus cucumeris",
        oriusLaevigatus: "Orius laevigatus",
        phytoseiulusPersimilis: "Phytoseiulus persimilis",
        suitzwavel: "Sulfur",
        steinernemaCarpocapsae: "Steinernema carpocapsae",
        steinernemaFeltiae: "Steinernema feltiae",
        transeiusMontdorensis: "Transeius montdorensis",
        trianium: "Trianum",
        trichogrammaArchaeae: "Trichogramma archaeae",
        anagyrusVladimiri: "Anagyrus vladimiri",
        aphidiusMatricariae: "Aphidius matricariae",
        pekelkreeftcystenArtemiaspp: "Pekelkreeftcysten artemia spp",
        athetaCoriaria: "Atheta coriaria",
        bombusTerrestris: "Bombus terrestris (Bourdons)",
        cryptolaemusMontrouzieri: "Cryptolaemus montrouzieri",
        delphastusCatalinae: "Delphastus catalinae",
        enermix: "Enermix",
        ephestiaKuehniella: "Ephestia kuehniella",
        carpoglyphusLactis: "Carpoglyphus lactis",
        AZ500: "AZ 500",
        hollandFytozide: "holland Fytozide",
        Teppeki: "Teppeki",
        springbok: "Springbok",
      },
    },
    PPP: "Plant protection product",
    biocide: "Biocide",
    adjuvant: "Adjuvant",
    addgrowthRegulation: "Sélectionner un produit",
    addMeasurement: "Ajouter une mesure",
    remmen: {
      label: "Régulation de la croissance",
      options: {
        alar: "Alar",
        bonzi: "Bonzi",
        calciumchloride: "Calciumchloride",
        chryzopon: "Chryzopon",
        controlGrowdesign: "Growdesign",
        cycocel: "Cycocel",
        dazide: "Dazide",
        elastoG5: "Elasto G5",
        koper: "Kupfer",
        magnesiumchloride: "Magnesium chlorid",
        pirouette: "Pirouette",
        regalis: "Regalis",
        rhizoponAa: "Rhizopon AA",
        uplccc750: "Upl ccc 750",
        questuran: "Questuran",
      },
    },
    addRemmen: "Sélectionner un produit",
    floweringTreatment: {
      label: "Traitement de floraison",
      options: {
        auxine: "Auxine",
        bonzi: "Bonzi",
        carousel: "Carousel",
        configureSc: "Configure SC",
        ethylene: "Ethylen",
        florgib: "Florgib",
        gibbereline: "Gibbereline",
        valiosoSt: "Valioso ST",
      },
    },
    addFloweringTreatment: "Sélectionner un produit",
    addNote: "Ajouter une note",
    filters: { title: "Filtres", gotoLevel2: "Niveau 2" },
    edit: "Editer",
    reset: "Réinitialiser",
    uploadPhoto: "Photo",
    whatCrop: "À quelle culture appartient la note?",
    whatToDo: "Qu'est-ce que tu aimerais faire?",
    whatTask: "Quelle tâche avez-vous accomplie?",
    whichRegulator: "Quel régulateur de croissance avez-vous utilisé?",
    whatObserve: "Remarque additionnelle:",
    howMiliter: "Combien de millilitre?",
    notAdded: "Bravo! Souhaitez-vous ajouter autre chose?",
    yes: "Oui",
    no: "Non",
    both: "Les deux",
    amDone: "Non, j'ai fini!",
    yourNote: "Votre note...",
    yourOwn: "Votre commentaire...",
    skip: "Suivant",
    skipQuestion: "Passer la question",
    tankage: {
      label: "Taille du réservoir",
      concentration: "Nombre de m²:",
      tankage: "Réservoir en litres:",
    },
    productAmount: {
      label: "Quantité de produit",
      concentrationTotal: "Concentration de produit",
      concentrationTotalKg: "Concentration produit en kg / Ha",
      tankageTotal: "Quantité totale de produit dans le réservoir:",
      tankageTotalKg: "Quantité totale de produit (kg)",
      amountAgent: "Quantité absolue de produit",
      numberOf: "Nombre d'unités appliquées",
      pieces: "Pièces par unité",
      totalApplied: "Nombre total appliqué",
      totalAppliedFertilizer: "Fertilisation totale appliquée",
      kgPer: "Kg par unité",
    },
    product: "Produit",
    tankageMix: "Produit {idx} sur {total}",
    back: "Précédent",
    next: "Suivant",
    done: "Validé",
    fertilization: {
      label: "Fertilisation",
      spreadible: {
        label: "Engrais épandable",
        title: "Quel engrais épandable?",
        options: {
          dcmMix1: "DCM mix 1",
          dcmMix2: "DCM mix 2",
          dcmMix3: "DCM mix 3",
          dcmMix4: "DCM mix 4",
          dcmMix5: "DCM mix 5",
          dcmMix6: "DCM mix 6",
          vivikali: "Vivikali",
          dolokal: "Dolokal",
          lokdoosMetGif: "Lokdoos met gif",
          lokdoosZonderGif: "Lokdoos zonder gif",
          kalkalmmonsalpeter: "Nitrate de chaux",
          magnesamonsalpeter: "Nitrate de magnésium",
          patentkali: "Potasse",
          protixflytilizer: "Protix Flytilizer",
          innogreenecogroei: "Innogreen ECO groei",
          tripelsuperfosfaat: "Tripelsuperfosfaat",
        },
      },
      foliar: {
        label: "Engrais foliaires",
        title: "Quel engrais foliaire?",
        options: {
          cuprum: "Cuprum",
          calciumchloride: "Calciumchloride",
          salifort: "Salifort",
          siliforce: "Siliforce",
          soluplantSF31661414CaO: "Soluplant SF3 16-6-14+14CaO",
          olfAg: "OLF Ag",
          espotop: "Bitterzout Epso Top",
          bitterzout: "Bitterzout",
          fertigroMSN: "Fertigro MSN",
          fertigroCN: "Fertigro CN",
          zwavelzuur37: "Zwavelzuur 37%",
          superFK: "Super FK",
          powerP: "Power P",
          universolgroen: "Universol Groen 23-6-10",
          kalksalpeter: "Kalksalpeter",
          kalksalpeterCalcinite: "Kalksalpeter Calcinite",
          kalksalpeterTropicote: "Kalksalpeter Tropicote",
          calsal: "Calsal Substrafeed Yara",
          yaraNitromag: "YaraBela Nitromag 27+4 MgO",
          spuitzwavel: "spuitzwavel",
          yaraSulfan: "YaraBela Sulfan 24+18SO3",
          ureumprills: "Ureum Prills",
          HuwaSan: "Huwa-San TR-50",
          vitalance: "Vitalance",
        },
      },
    },
    causesCropcontrol: {
      options: {
        aaltjes: "Nématodes",
        altenaria: "Altenaria",
        bacterienov: "Bactéries",
        bladvlekkenziekte: "Tâches foliaires",
        bloemrot: "Pourriture des fleurs",
        botrytis: "Botrytis",
        bladluizen: "Pucerons",
        bloeibehandeling: "Traitement de floraison",
        cicaden: "Cicadelles",
        echteMeeldauw: "Mildiou duvet",
        erwinia: "Erwinia",
        valseMeeldauw: "Mildiou",
        kevers: "Coléoptères",
        mineervliegen: "Mineuses des feuilles",
        muggenVliegen: "Moustiques et mouches",
        rupsen: "Chenilles, papillons de nuit et papillons",
        mijten: "Acariens",
        trips: "Thrips",
        wantsen: "Insectes",
        wolluizen: "Cochenilles",
        didymella: "Didymella",
        fusarium: "Fusarium",
        japanseRoest: "Rouille japonaise",
        phytophthora: "Phytophthora",
        pythium: "Pythium",
        roetdauw: "Fumagine",
        sclerotinia: "Sclerotinia",
        stengelbasisrot: "Pourriture de la base de la tige",
        verticillium: "Verticillium",
        zwarteVlekkenziekte: "Maladie des points noirs",
        otherBacteria: "Autres bactéries",
        rooting: "Enracinement",
        leafSpot: "Tâche sur les feuilles",
        floweringTreatment: "Traitement de floraison",
        centerRot: "Pourriture du cœur",
        adhesive: "Adhésif",
        otherInsects: "Insectes autres",
        tuberRot: "Pourriture des tubercules",
        attractant: "Attractif",
        aphids: "Pucerons",
        mole: "Taupes",
        mice: "Souris",
        shoreFly: "Mouche du rivage",
        weeds: "Mauvaises herbes",
        disinfection: "Désinfection",
        misk: "Autres",
        plantResistance: "Résilience des plantes",
        rats: "Rats",
        remmen: "Régulation de la croissance",
        rhizoctonia: "Rhizoctonia",
        caterpillars: "Chenilles",
        otherFungus: "Autre champignon",
        slugs: "Escargots",
        spiders: "Tétranyques",
        springtail: "Collemboles",
        sugarRot: "Pourriture des tiges",
        whiteFly: "Mouche blanche",
        rootRot: "Pourriture des racines",
        symphyla: "Symphyla",
        tensides: "Tensio-actif",
        beworteling: "rooting",
        dopluizen: "Dopluizen",
        schildluizen: "Schildluizen",
        branchMortality: "Branch mortality",
        beetles: "Beetles",
      },
    },
    pleaseSelectCrops: "Please select crops before proceeding.",
    backgroundSaveInProgress:
      "Des données sont toujours en cours d'enregistrement en arrière-plan...",
    pleaseWaitbackgroundSave: "Attendez jusqu'à la fin de l'enregistrement.",
    document: "Document",
  },
  resetPassword: {
    title: "Réinitialisation du mot de passe",
    message:
      "Saisissez l'adresse e-mail de votre compte utilisateur et nous vous enverrons un lien de réinitialisation de mot de passe.",
    placeholder: "Entrez votre adresse email",
    send: "Envoyer un e-mail de réinitialisation du mot de passe",
    successMessage: "Un lien a été envoyé!",
    emailNotUsed: "Cet email n'est pas valide!",
    updateSuccessMessage: "Mot de passe mis à jour !",
  },
  footer: { disclaimer: "Avertissement", contact: "Contact", faq: "FAQ" },
  timeControl: {
    selectRange: "Sélectionnez la plage horaire",
    last: "Dernière",
    deltas: {
      hour: "heure",
      day: "jour",
      week: "semaine",
      month: "mois",
      year: "year",
    },
  },
  notesFeed: {
    log: "Journal",
    dailySummary: "Daily summary",
    fertilizationEc: "EC d'arrosage",
    EC: "EC",
    "fertilization-Water": "Irrigation",
    amountSpreadable: "Quantité",
    concentrationSpreadable: "Concentration",
    "fertilization-fertilization": "Fertilisation",
    spreadible: "Fertilisation en arrosage",
    foliar: "Fertilisation foliaire",
    amountAgent: "Quantité",
    labData: "Données de laboratoire",
    ec: "EC",
    ph: "pH",
    color: "Couleur",
    remmen: "Régulation de la croissance",
    floweringTreatment: "Traitement de floraison",
    length: "Longueur",
    chemicalProtection: "Protection chimique des cultures",
    organicProtection: "Protection Biologique des cultures",
    diameter: "Diamètre",
    numberOfBuds: "Nombre de boutons/fleurs",
    weight: "Poids",
    yield: "Yield",
    surface: "Surface",
    size: "Taille",
    concentration: "Concentration",
    amount: "Quantité",
    compare: "Comparer",
    edit: "Éditer",
    download: "Download",
    cancelEdit: "Annuler la modification",
    toggleView: "Vue graphique",
    successfullyDeleted: "Les notes ont été supprimées avec succès",
    remove: "supprimer",
    note: "note",
    notes: "notes",
    started: "La culture démarre le",
    leafObservation: "Leaf observation",
    defectPhenomenon: "Bordure des feuilles - jaune",
    leafBurn: "Brûlure des feuilles",
    flowering: "Floraison",
    intensity: "Intensity",
    counting: "Counting",
    fungus: "Champignon",
    plague: "Ravageur",
    failure: "Pertes",
    failureRate: "Taux de perte",
    rating: "Rating",
    ratingValue: "Rating",
    LeafTips: "Bords des feuilles",
    miscellaneous: "Divers",
    misc: "Divers",
    pieces: "Pièces par unité",
    numberOf: "Nombre d'unités",
    totalApplied: "Total appliqué",
    kgPer: "Kg par unité",
    kgPerUnit: "Kg par unité",
    totalIrrigation: "Total irrigation",
    floridity: "Floridity",
    units: {
      pieces: "unité",
      "unité / m2": "unité / m2",
      "ml / 100 litres": "ml / 100 litres",
      "gr / 100 litres": "gr / 100 litres",
      kg: "kg",
      gr: "gr",
      units: "units",
      "pieces / m2": "pieces / m2",
      "ml / 100 liters": "ml / 100 liters",
      "gram / 100 liters": "gram / 100 liters",
    },
    formBranches: [
      { id: "all", label: "Afficher tout" },
      { id: "favorites", label: "Favoris" },
      { id: "cultivationActivity", label: "Activité de culture" },
      { id: "measurement", label: "Mesure" },
      { id: "observation", label: "Observation" },
      { id: "waterAndFertilization", label: "Eau et fertilisation" },
      { id: "cropControl", label: "Contrôle des cultures" },
      { id: "labData", label: "Lab data" },
      { id: "addPhoto", label: "Photos" },
      { id: "misc", label: "Misc" },
      { id: "hasPhoto", label: "Picture mode" },
    ],
    addPhoto: "Photo",
    addFavorite: "Ajouter aux Favoris",
    removeFavorite: "Favoris",
    labResults: "Résultats de laboratoire",
    substrate: "Substrat",
    drain: "Drainage",
    gift: "Absorbé",
    ditch: "Lessivé",
    changed: "Changed",
    from: "from",
    to: "to",
    added: "Added",
    removed: "Removed",
    containerTypes: {
      container: "container",
      tray: "tray",
      potSize: "potsize",
      plugType: "plug type",
      growcoon: "growcoon",
    },
    potSizeChange: "Rempotage de",
    potSizeChangeTo: "à",
    locationChange: "Déplacement de",
    locationChangeTo: "à",
    water: "Duration",
    tankage: "Tankage",
    numberOfUnits: "Units applied",
    print: {
      companyName: "Company name",
      label: "Label",
      genus: "Genus",
      species: "Species",
      cultivar: "Cultivar",
      startWeek: "Start week",
      potSize: "Pot size",
      labId: "L&S ID",
      startingAmount: "Starting amount",
      currentAmount: "Current amount",
      businessLocation: "Business location",
      level1Locations: "Level 1 locations",
      level2Locations: "Level 2 locations",
      cropOverview: "Crop overview",
      referenceNumber: "Reference number",
      photoReference: "Photo reference",
      photosOverview: "Photos overview",
      log: "Log",
      summary: "Summary",
      at: "at",
      by: "by",
      batchNumber: "Batch number",
      youngPlantSuppliers: "Young plant suppliers",
      sizeDescription: "Size description",
      supplierDocumentNr: "Supplier document nr.",
      containerNr: "Container number",
      substrateMixture: "Substrate mixture",
      fertilization: "Fertilization",
      barcode: "Barcode",
      weeknumber: "Week number",
      potDate: "Pot date",
      endDate: "End date",
      spacingDate: "Arrival date of young plant material",
      description: "Description",
      objectives: "Objectives and challenges for this crop",
      container: "Container",
      openField: "Open field",
      tray: "Tray",
      plugType: "Plug type",
    },
    startWeek: "Start week",
    week: "Week",
    weather: "Weather",
    confirmDelete: "Are you sure you want to delete this note?",
    confirmDeleteMultiple:
      "Are you sure you want to delete this note across all crops?",
    confirmEditMultiple:
      "Are you sure you want to edit this note across all crops?",
    confirmEdit: "Are you sure you want to edit this note?",
    delete: "Delete",
    cancel: "Cancel",
    update: "Update",
    show: "Show",
    moreNotesInBatch: "more notes in batch",
    collapse: "Collapse",
  },
  cropCard: {
    startDate: "SEMAINE DE DÉBUT",
    expectedDate: "SEMAINE PRÉVUE",
    endDate: "DATE DE FIN",
    location: "LIEU",
    potSize: "TAILLE DU POT",
    tray: "TRAY",
    growcoon: "GROWCOON",
    plugType: "PLUG TYPE",
    openField: "OPEN FIELD",
    labId: "Identifiant labo",
    changePhoto: "CHANGE PHOTO",
    editBatch: "Modifier les informations",
    week: "La semaine",
  },
  compareLogs: {
    compareLogs: "Comparer les cultures",
    selectCrop: {
      1: "Sélectionner la première culture :",
      2: "Sélectionner la seconde culture :",
      3: "Sélectionner la troisième culture :",
    },
    add: "Ajouter",
    useLandscape: "Afficher en paysage",
    noData: "Pas de donnée",
    selectCrops: "Sélectionner la culture..",
    reminderModal: {
      title: "Ajouter un nouveau rappel",
      datePickerPlaceholder: "Sélectionner la date",
      textareadPlaceholder: "Description du rappel...",
      button: "Sauvegarder",
      reminderAddedSuccessfully: "Nouveau rappel ajouté !",
      reminderAddedNotSuccessfully: "Pas de nouveau rappel ajouté !",
    },
  },
  task: {
    title: {
      create: "Créer une opération",
      edit: "Editer",
      list: "Liste des tâches",
      mytasks: "Tâches",
    },
    type: {
      label: "Type",
      name: {
        pruning: "Tailler",
        pinching: "Pincement",
        spacing: "Distancer",
        move: "Déplacer",
        sorting: "Trier",
        changePotsize: "Rempoter",
        rotate: "Rotation",
        cuttingLeaf: "CutBouture de feuille",
        layFoil: "Lay foil",
        removeFoil: "Remove foil",
        harvest: "Récolte",
        lower: "Raccourcir",
        settle: "Régler",
        removeLeafs: "Effeuiller",
        bendIn: "Plier",
        placeSticks: "Tuteurer",
        siftingThrough: "Sifting through",
        pollinate: "Pollinate",
        binding: "Binding",
        weeding: "Weeding",
        deflower: "Deflower",
        removeTendrils: "Remove tendrils",
        placeCatchCards: "Place catch cards",
        tendrils: "Tendrils",
        raiseTheGauze: "Raise the gauze",
        cropMaintenance: "Crop maintenance",
        removeBuds: "Remove buds",
        potting: "Rempotage",
        deliverFinishedPlants: "Livrer",
        sow: "Truie",
        makeCuttings: "Faire des boutures",
        technicalService: "Technical service",
        PPP: "Plant protection product",
        biocide: "Biocide",
        adjuvant: "Adjuvant",
        liquidCropControl: "Traiter des cultures en arrosage",
        spreadingCropControl: "Traiter des cultures en pulvérisation",
        growthRegulation: "Appliquer un régulateur de croissance",
        floweringTreatment: "Traiter la floraison",
        controlRound: "Contrôler visuellement les cultures",
        spreadableFertilizers: "Appliquer un fertilisation soluble",
        foliarFertilizers: "Appliquer un fertilisation foliaire",
        scouting: "Effectuer un suivi sanitaire",
        misc: "Autre",
        checklist: "Checklist",
      },
      defaultDescription: {
        pruning: "Tailler le lot",
        pinching: "Pincer le lot",
        spacing: "Distancer les plantes",
        move: "Déplacer le lot",
        sorting: "Fête de tri",
        changePotsize: "Rempoter",
        rotate: "Rotation",
        cuttingLeaf: "CutBouture de feuille",
        layFoil: "Lay foil",
        removeFoil: "Remove foil",
        harvest: "Récolter la production",
        lower: "Raccourcir",
        settle: "Régler",
        removeLeafs: "Effeuiller",
        bendIn: "Plier",
        placeSticks: "Tuteurer",
        siftingThrough: "Sifting through",
        pollinate: "Pollinate",
        binding: "Binding",
        weeding: "Weeding",
        deflower: "Deflower",
        removeTendrils: "Remove tendrils",
        placeCatchCards: "Place catch cards",
        tendrils: "Tendrils",
        raiseTheGauze: "Raise the gauze",
        cropMaintenance: "Crop maintenance",
        removeBuds: "Remove buds",
        potting: "Rempotage",
        deliverFinishedPlants: "Livrer",
        sow: "Truie",
        makeCuttings: "Faire des boutures",
        technicalService: "Technical service",
        PPP: "Plant protection product",
        biocide: "Biocide",
        adjuvant: "Adjuvant",
        liquidCropControl:
          "Pulvériser un produit de protection des cultures en arrosage",
        spreadingCropControl:
          "Réaliser un traitement de protection des cultures en pulvérisation",
        growthRegulation: "Effectuer un régulateur de croissance",
        floweringTreatment: "Effectuer un traitement de floraison",
        controlRound: "Effectuer un contrôle visuel des cultures",
        spreadableFertilizers: "Apporter une fertilisation soluble",
        foliarFertilizers: "Apporter une fertilisation foliaire",
        scouting:
          "Evaluer la santé des plantes (ravageurs, symptômes de carence, pathogènes fongiques et bactérioses)",
        misc: "Autre",
        checklist: "Checklist",
      },
    },
    checklist: { label: "Checklist" },
    selectCrop: "Sélectionner une culture",
    openField: "Open field",
    selectLocation: {
      label: "Sélectionner un emplacement",
      placeholder: "Sélectionner un emplacement",
    },
    description: { label: "Description", placeholder: "Description" },
    user: { label: "Utilisateur", noUser: "No user" },
    date: "Date",
    completed: "Completé",
    repeats: {
      label: "Nombre de répétition",
      help: "Le nombre de fois que cette tâche se répète",
    },
    repeatWeekOffset: {
      label: "Répéter toutes les semaines",
      help: "Le nombre de semaines entre les répétitions. Choisissez 1 pour les tâches répétitives hebdomadaires, 2 pour les deux semaines, etc.",
    },
    deadline: "Date limite",
    startDate: "Date de début",
    isRecurringTask: "Opération récurrente",
    button: {
      create: "Créer",
      update: "Mettre à jour",
      delete: "Delete",
      addTask: "Ajouter une tâche",
      allTasks: "All Tasks",
    },
    filter: {
      dueToday: "Pour aujourd'hui",
      thisWeek: "Cette semaine",
      showAll: "Afficher tout",
    },
    more: "plus",
    delete: "Delete task",
    edit: "Edit task",
    confirmDelete: "Are you sure you want to delete this task?",
    column: {
      name: "Cultivation name",
      businessLocation: "Business location",
      level1Location: "Level 1 location",
      level2Location: "Level 2 location",
      batchNumber: "Batch number",
      potDate: "Pot date",
      potSize: "Potsize",
      startingAmount: "Starting amount",
      currentAmount: "Current amount",
      surface: "Surface",
    },
    addNoteToSelected: "Add note to selected",
    markSelectedAsDone: "Mark selected as done",
    performBy: "Perform by",
    crops: "crops",
    crop: "crop",
    assignedTo: "Assigned to:",
    createdBy: "Created by:",
    you: "you",
    task: "Task",
    protocol: "Protocol",
    createdByType: {
      user: "User",
      protocol: "Protocol",
      all: "All",
    },
    taskDetails: "Task details",
    details: "Details",
    totalOf: "Total of selected crops",
    finished: "Finished",
    showCompletedOnly: "Show completed only",
  },
  alerts: { markAllAsRead: "Mark all as read", noAlerts: "All caught up!" },
  pdfmake: {
    title: "Générer le PDF",
    regeneratePdf: "Re-générer le PDF",
    downloadPdf: "Télécharger",
  },
  vuelidate: {
    unknownLabel: "Ce champs",
    errors: {
      required: "{label} est un champs obligatoire.",
      minLength: "{label} doit comporter {min} ou plus d'élements.",
      maxLength: "{label} doit comporter au maximum {max} éléments.",
      minValue: "{label} doit être supérieur à {min}.",
      maxValue: "{label} doit être inférieur à {max}.",
      email: "{label} doit être un e-mail valide.",
      phoneNumber: "{label} doit être un numéro de téléphone valide.",
      sameAsPassword: "{label} doit être identique au mot de passe.",
      unknownError: "{label} comporte une erreur.",
    },
  },
  companyLabels: {
    consumptionCrops: {
      title: "Consumption Crops",
      options: {
        largeFruit: "Large Fruit",
        smallFruit: "Small Fruit",
        leafVegetables: "Leaf vegetables",
        legumes: "Legumes",
        fruitVegetables: "Fruit vegetables",
        cabbageCrops: "Cabbage crops",
        rootAndTuberVegetables: "Root and Tuber Vegetables",
        stalkVegetables: "Stalk vegetables",
        herbs: "Herbs",
        edibleMushrooms: "Edible mushrooms",
      },
    },
    ornamentals: {
      title: "Ornamentals",
      options: {
        flowerBulbsTubers: "Flower bulbs and flower tubers",
        bulbFlowersTubers: "Bulb flowers and tubers",
        pottedPlants: "Potted plants",
        cutFlowers: "Cut flowers",
        draftShrubs: "Draft shrubs",
        cutGreen: "Cut green",
        beddingPlants: "Bedding plants",
        treeNursery: "Tree nursery",
        growingPerennials: "Growing perennials",
        flowerSeedCultivation: "Flower seed cultivation",
        swampWaterPlants: "Swamp and water plants",
        breedingBasicSeedProduction: "Breeding and basic seed production",
        temporarilyUncultivatedTerrain: "Temporary uncultivated terrain",
        permanentlyUncultivatedTerrain: "Permanently uncultivated terrain",
      },
    },
    cultivationMethod: {
      title: "Cultivation method",
      options: {
        biological: "Biological",
        regular: "Regular",
        openField: "Open field",
        substrateBased: "Substrate based",
        hydroponic: "Hydroponic",
        verticalFarming: "Vertical Farming",
      },
    },
    countries: {
      title: "Countries",
      options: {
        nl: "The Netherlands",
        be: "Belgium",
        de: "Germany",
        uk: "United Kingdom",
        fr: "France",
        na: "North America",
        sa: "South America",
        as: "Asia",
        es: "Spain",
        pl: "Poland",
        ch: "Switzerland",
        dk: "Denmark",
        pt: "Portugal",
      },
    },
  },
  common: { of: "de" },
  updateEmail: {
    intro: "Your clients dashboards have been updated",
    dear: "Dear",
    new: "New",
    addedTo: "added to",
    subject: "Your daily summary",
    notes: "notes",
    photos: "photos",
    labSamples: "lab data",
    labSampleComments: "lab data comments",
    greetings: "Best regards",
    unsub: "Unsubscribe from daily update",
  },
  protocol: {
    title: {
      myProtocols: "Protocols",
      applyProtocol: "Apply protocol",
      create: "Create a protocol",
      potSizes: "Pot Sizes:",
      protocolName: "Protocol:",
      kpis: "Kpi's:",
      week: "Week",
      stage: "Stage:",
      startDate: "Start date:",
      any: "Any",
      unnamed: "Unnamed",
      protocol: "Protocol",
      tank: "Tank",
      action: "Action",
      alert: "Alert",
      protocolsOverview: "Protocols overview",
      trays: "Trays:",
      openField: "Open field:",
    },
    alerts: {
      added: "New protocol added!",
      updated: "Protocol updated successfully!",
      deleted: "Protocol deleted successfully!",
      error: "Something went wrong!",
    },
    button: {
      add: "Add protocol",
      duplicateFrom: "Duplicate from",
      next: "Next",
      back: "Back",
      addStage: "Add stage",
      addFirstStage: "Add your first stage",
      removeStage: "Remove stage",
      addKpi: "Add KPI",
      addFirstKpi: "Add your first KPI",
      removeKpi: "Remove KPI",
      addAction: "Add action",
      addFirstAction: "Add your first action",
      removeAction: "Remove action",
      addAlert: "Add alert",
      addFirstAlert: "Add your first alert",
      removeAlert: "Remove alert",
      cancel: "Cancel",
      save: "Save",
      edit: "Edit",
      clone: "Clone",
      update: "Update",
      delete: "Delete",
      select: "Select",
      addCondition: "Add condition",
      addFirstCondition: "Add your first condition",
      addFertilizationSchema: "Add fertilization schema",
      addFirstFertilizationSchema: "Add your first fertilization schema",
      addClimateSchema: "Add climate schema",
      addFirstClimateSchema: "Add your first climate schema",
      addWeatherData: "Add weather data",
      addTank: "Add tank",
      addStartDates: "Add start dates",
      goToTask: "Go to task",
    },
    add: {
      stage: {
        type: "Type",
        stages: "Stages",
        kpis: "KPI's",
        actions: "Actions",
        alerts: "Alerts",
        conditions: "Cultivation conditions",
        generalConditions: "General cultivation conditions",
        fertilizationSchematics: "Fertilization schematics",
        climateSchema: "Climate schema",
      },
      label: {
        genus: "Genus",
        species: "Species",
        cultivar: "Cultivar",
        potSize: "Pot size",
        tray: "Tray",
        openField: "Open field",
        startDate: "Start date between",
        stageName: { stage: "Stage", name: "name" },
        value: "Value",
        week: "Week",
        from: "From",
        until: "Until",
        fromWeek: "From week",
        untilWeek: "Until week",
        kpi: "KPI",
        selectKpi: "Select KPI",
        number: "Number",
        units: "Units",
        actionType: "Action type",
        selectType: "Select type",
        description: "Description",
        observationType: "Observation type",
        protocolsName: "Protocol's name",
        name: "Name",
        min: "Min",
        max: "Max",
        conditionType: "Condition type",
        fertilizationSchema: "Fertilization schema",
        climateSchema: "Climate schema",
        recurringAction: "Recurring",
        repeatWeekOffset: "Repeat week offset",
        repeats: "Repeats count",
        endStartDate: "End of start date",
        enabled: "Enabled",
      },
    },
    kpi: [
      {
        id: "length",
        name: "Length",
        units: [
          { id: "cm", name: "cm" },
          { id: "inch", name: "inch" },
          { id: "m", name: "m" },
          { id: "mm", name: "mm" },
          { id: "ft", name: "ft" },
        ],
      },
      {
        id: "width",
        name: "Width",
        units: [
          { id: "cm", name: "cm" },
          { id: "inch", name: "inch" },
          { id: "m", name: "m" },
          { id: "mm", name: "mm" },
          { id: "ft", name: "ft" },
        ],
      },
      {
        id: "numberOfFlowers",
        name: "Number of flowers",
        units: [{ id: "pieces", name: "pieces" }],
      },
    ],
    overview: {
      dateActive: "Date active",
      cropNameDescription: "Crop name / description",
      locations: "Locations",
      batch: "Batch number",
      potweek: "Potweek",
      potSize: "Potsize",
      plantNumber: "Plant number",
      dates: "Dates",
    },
    condition: [
      { id: "potting_planting", name: "Potting/Planting" },
      { id: "substrate", name: "Substrate" },
      { id: "starting_material", name: "Starting material" },
      { id: "fertilization", name: "Fertilization" },
      { id: "rooting", name: "Rooting" },
      { id: "irrigation_water_ec", name: "Irrigation water EC" },
    ],
    annotation: {
      crop: "Crop annotation",
      stage: "Stages annotation",
      kpi: "KPI's annotation",
      action: "Actions annotation",
      alert: "Alerts annotation",
      conditionSchema: "General conditions annotation",
      fertSchema: "Fertilization schemes annotation",
      climateSchema: "Climate schemes annotation",
      notApplied: "Protocol is not applied.",
      noAvailableProtocols: "No protocols available for this crop.",
      considerCreating: ["Consider", "creating a new protocol"],
      applyProtocol: "Apply a protocol",
    },
  },
  agreement: {
    weValueYourPrivacy: "We value your privacy",
    dataSafety:
      "Your data is safe with us. All data is stored on AVG compliant servers. We do not sell your data to external parties. We do not store data that we do not need. But we would like to keep improving our software services. Want to know more? Read the privacy statement or send us an email.",
    read: "Read terms and conditions",
  },
};
