import { instanceToPlain } from "class-transformer";
import { computed, type ComputedRef } from "vue";
import type { UserRole } from "./user-role.model";
import { type AllowedLocation, type UserSchema } from "@/schemas/user.schema";
import type { DeepPartial } from "@/utils/utils";
import type { CompanySchema } from "@/schemas/company.schema";

type CompanyUserProps = {
  [P in keyof UserSchema]: UserSchema[P];
};

export class CompanyUser {
  readonly id: string;
  private readonly data: UserSchema;
  private readonly currentCompany: CompanySchema | null;
  private readonly userRoles: UserRole[];

  constructor(
    data: UserSchema,
    config: { currentCompany: CompanySchema | null; userRoles: UserRole[] },
  ) {
    this.data = data;
    this.id = data.id;
    this.currentCompany = config.currentCompany;
    this.userRoles = config.userRoles;

    return new Proxy(this, {
      get(target, property: string | symbol) {
        if (typeof property === "string" && property in target.data) {
          return target.data[property as keyof typeof target.data];
        }
        return (target as any)[property];
      },
      set(target, property: string | symbol, value: any) {
        if (typeof property === "string" && property in target.data) {
          (target.data as any)[property] = value;
          return true;
        }
        (target as any)[property] = value;
        return true;
      },
    });
  }

  static create(
    data: UserSchema,
    config: { currentCompany: CompanySchema | null; userRoles: UserRole[] },
  ) {
    // return plainToInstance(CompanyUser, data) as CompanyUserType; // won't work for X reason
    return new CompanyUser(data, config) as CompanyUserType;
  }

  toDTO(): UserSchema {
    const plain = instanceToPlain<CompanyUser>(this);
    return {
      id: this.id,
      ...plain.data,
    } as UserSchema;
  }

  private get _context(): "own" | "third-party" | "advisor" {
    const isOwnCompany =
      ["user", "grower", "admin"].includes(this.data.type) ||
      (["third-party", "advisor"].includes(this.data.type) &&
        this.data.companyId === this.currentCompany?.id);
    const isThirdParty =
      this.data.type === "third-party" &&
      this.data.companyId !== this.currentCompany?.id;
    const isAdvisor =
      this.data.type === "advisor" &&
      this.data.companyId !== this.currentCompany?.id;

    if (isOwnCompany) return "own";
    if (isThirdParty) return "third-party";
    if (isAdvisor) return "advisor";
    return "own"; // should never happen
  }

  get contextCmp(): {
    role: ComputedRef<UserRole | null>;
    allowedLocations: AllowedLocation[];
    type: UserSchema["type"] | "admin/third-party";
  } {
    let roleId: string | null = null;
    let allowedLocations: any[] = [];

    if (this._context === "own") {
      // in this case user.roleId is used directly
      roleId = this.data.roleId || null;
      allowedLocations = this.data.config.allowedLocations || [];
    } else if (this._context === "third-party") {
      // in this case the relevant roleId is actually
      // user.config.managedCompanies.{companyId}.roleId
      const managedCompany = this.data.config.managedCompanies.find(
        (x) => x.companyId === this.currentCompany?.id,
      );

      roleId = managedCompany?.roleId || null;
      allowedLocations = managedCompany?.allowedLocations || [];
    }

    const role = computed(
      () => this.userRoles.find((x) => x.id === roleId) || null,
    );

    const userType = this.data.type;
    let type: UserSchema["type"] | "admin/third-party" = userType;
    if (userType === "third-party") {
      type = this._context !== "own" ? userType : "admin/third-party";
    }

    return {
      role,
      allowedLocations,
      type,
    };
  }

  setContextCmp({
    roleId,
    allowedLocations,
  }: ({ roleId: string | null } | { allowedLocations: any[] }) & {
    roleId?: string | null;
    allowedLocations?: any[];
  }) {
    if (this._context === "own") {
      // when companyId is the user's own company then set roleId directly
      if (roleId !== undefined) this.data.roleId = roleId;
      if (allowedLocations !== undefined)
        this.data.config.allowedLocations = allowedLocations;
    } else if (this._context === "third-party") {
      // otherwise update the respective managedCompanies entry
      const managedCompanies = this.data.config.managedCompanies;
      const managedCompany = managedCompanies.find(
        (x) => x.companyId === this.currentCompany?.id,
      );

      if (managedCompany) {
        if (roleId !== undefined) managedCompany.roleId = roleId;
        if (allowedLocations !== undefined)
          managedCompany.allowedLocations = allowedLocations;
        this.data.config.managedCompanies = managedCompanies;
      }
    }
  }

  toUpdateDTO() {
    const updateObj: DeepPartial<CompanyUserType> = {};
    updateObj.active = this.data.active;
    updateObj.type = this.data.type;

    if (this._context === "own") {
      // in this case user.roleId is used directly
      updateObj.roleId = this.data.roleId || null;
      updateObj.config = {
        allowedLocations: this.data.config.allowedLocations || [],
      };
    } else if (this._context === "third-party") {
      // in this case the relevant roleId is actually
      // user.config.managedCompanies.{companyId}.roleId
      updateObj.config = {
        managedCompanies: this.data.config.managedCompanies,
      };
    }
    return updateObj;
  }
}

export type CompanyUserType = CompanyUser &
  CompanyUserProps & {
    contextCmp: {
      role: UserRole | null;
      allowedLocations: AllowedLocation[];
      type: UserSchema["type"] | "admin/third-party";
    };
  };
